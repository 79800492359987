const login = {
  TITLE: "ログイン",
  SUB_TITLE: "2段階認証",
  SUB_TIP: "2段階認証コードを入力してください",
  REMEMBER_ME: "ログインしたままにする",
  LOGIN_BTN: "ログイン",
  TO_SIGNUP: "新規ユーザー登録はこちら",
  TO_PASSWORD_REST: "パスワードを忘れた方はこちら",
  OTP_LABEL: "6桁の確認コード",
  SEND_MESSAGE_BTN: "送信",
  TO_FIRST_STEP: "別のアカウントでログイン",
  OTP_ERROR_TIP: "確認コードは6文字です",
  // password set
  PASSWORD_SET_TITLE: "仮パスワードを変更",
  SET_BUTTON_LABEL: "変更する",
};

export default login;
