import { getCookie } from "@/utils";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  SafieViewerAPIApi as Api,
  Configuration,
  Success,
  UserCreateResponse,
} from "../backend-api";

class safieViewer {
  private api: Api;

  constructor(
    config?: Configuration,
    basePath?: string,
    instance?: AxiosInstance,
  ) {
    this.api = new Api(config, basePath, instance);
  }
  // Safieユーザのパスワードリセット
  async resetPasswordPost(
    mailAddress: string,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Success>> {
    await this.apiCertificateGet();
    const xXSRFToken = getCookie("_xsrf");
    return this.api.apiUserRequestResetPasswordPost(
      xXSRFToken ?? "",
      mailAddress,
      "safie",
      options,
    );
  }
  // Safieユーザのサインアップ
  async createUserPost(
    mailAddress: string,
    password: string,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<UserCreateResponse>> {
    await this.apiCertificateGet();
    const xXSRFToken = getCookie("_xsrf");
    return this.api.apiUserCreatePost(
      xXSRFToken ?? "",
      mailAddress,
      password,
      "safie",
      options,
    );
  }
  // Safieユーザのメールアドレス変更
  async apiUserChangeMailAddressPost(
    mailAddress: string,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Success>> {
    await this.apiCertificateGet();
    const xXSRFToken = getCookie("_xsrf");
    return this.api.apiUserChangeMailAddressPost(
      xXSRFToken ?? "",
      mailAddress,
      "safie",
      options,
    );
  }
  // Safieユーザのパスワード変更
  async apiUserChangePasswordPost(
    currentPassword: string,
    newPassword: string,
    confirmPassword: string,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Success>> {
    await this.apiCertificateGet();
    const xXSRFToken = getCookie("_xsrf");
    return this.api.apiUserChangePasswordPost(
      xXSRFToken ?? "",
      currentPassword,
      newPassword,
      confirmPassword,
      options,
    );
  }
  // Safieユーザの二段階認証設定解除
  async apiUserMfaDeletePost(
    password?: string,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Success>> {
    await this.apiCertificateGet();
    const xXSRFToken = getCookie("_xsrf");
    return this.api.apiUserMfaDeletePost(xXSRFToken ?? "", password, options);
  }
  // XSRF Cookie 取得
  apiCertificateGet(): Promise<AxiosResponse<Success>> {
    return this.api.apiCertificateGet();
  }
}

export { safieViewer };
