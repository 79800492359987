/* tslint:disable */
/* eslint-disable */
/**
 * Safie API specification for Safie Developers
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.14.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface Application
 */
export interface Application {
  /**
   * アプリケーションID
   * @type {number}
   * @memberof Application
   */
  application_id?: number;
  /**
   * アプリケーション名
   * @type {string}
   * @memberof Application
   */
  application_name?: string;
  /**
   * アプリケーションコード
   * @type {string}
   * @memberof Application
   */
  application_code?: string;
  /**
   * アプリケーションに適用するプラン。`application_auth_type=oauth2`の場合は、`production`を指定しなければならない。 `trial`: 試験用アプリケーション、権限のある任意のデバイスに対して少量のAPIコールが可能。 `production`: 本番アプリケーション、権限のあるSafie APIオプションプランが付与されたデバイスに対してプランで定められた量のAPIコールが可能。
   * @type {string}
   * @memberof Application
   */
  application_plan?: ApplicationApplicationPlanEnum;
  /**
   * アプリケーションが利用する認証種別 `apikey`: APIキーを利用するアプリケーション `oauth2`: OAuth2認可プロセスを通して取得したアクセストークンを利用するアプリケーション
   * @type {string}
   * @memberof Application
   */
  application_auth_type?: ApplicationApplicationAuthTypeEnum;
  /**
   * アプリケーション公開状況。`application_auth_type=oauth2`の場合は、`true`を指定しなければならない。 `true`: 公開中 (自developerに属さないユーザーがアプリケーションコードを入力することでアプリケーション利用申請することができる。ただし、`application_auth_type=oauth2`のアプリケーションに対する利用申請はできない。) `false`: 非公開
   * @type {boolean}
   * @memberof Application
   */
  published?: boolean;
  /**
   * このアプリケーションでAPIアクセスする際に利用されるデバイスオプションの種別 `dedicated`（専用オプション）、`general`（汎用オプション）の二種類の値のみ
   * @type {string}
   * @memberof Application
   */
  device_option_type?: ApplicationDeviceOptionTypeEnum;
}

export const ApplicationApplicationPlanEnum = {
  Trial: "trial",
  Production: "production",
} as const;

export type ApplicationApplicationPlanEnum =
  (typeof ApplicationApplicationPlanEnum)[keyof typeof ApplicationApplicationPlanEnum];
export const ApplicationApplicationAuthTypeEnum = {
  Apikey: "apikey",
  Oauth2: "oauth2",
} as const;

export type ApplicationApplicationAuthTypeEnum =
  (typeof ApplicationApplicationAuthTypeEnum)[keyof typeof ApplicationApplicationAuthTypeEnum];
export const ApplicationDeviceOptionTypeEnum = {
  Dedicated: "dedicated",
  General: "general",
} as const;

export type ApplicationDeviceOptionTypeEnum =
  (typeof ApplicationDeviceOptionTypeEnum)[keyof typeof ApplicationDeviceOptionTypeEnum];
