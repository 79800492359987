const global = {
  FORM_SUBMIT_ERROR: "入力エラーがあります",
  MAIL_LABEL: "メールアドレス",
  PASSWORD_LABEL: "パスワード",
  PASSWORD_ERROR_TIP:
    "パスワードは8文字以上32文字以下の半角英数字および記号で、数字および文字を最低1字含めてください",
  MUST_TIP: "は必須入力項目です",
  TO_LOGIN: "ログインへ戻る",
  INPUT_TIP: "入力してください",
  EMPTY_TIP: "{n}を入力してください",
  EMPTY_SELECT_TIP: "{n}を選択してください",
  ERROR_TIP: "不正な{n}です",
  NOT_SAME_TIP: "{n}が一致しません",
  IS_AGREE_TIP: "チェックを入れてください",
  IS_PUBLISHED: "公開中",
  IS_NOT_PUBLISHED: "非公開",
  GENERAL_OPTION_TYPE: "汎用",
  DEDICATED_OPTION_TYPE: "専用",
  SHOW_ALL: "全件表示",
  COPY_SUCCESS: "コピーしました",
  DELETE_BTN_TEXT: "削除",
  GO_BACK_BTN_TEXT: "戻る",
  SAVE_BTN_TEXT: "保存",
  CANCEL_BTN_TEXT: "キャンセル",
  SURE_BTN_TEXT: "はい",
  SUBMIT_BTN_TEXT: "発行",
  SUBMIT_SELECT_BTN_TEXT: "選択",
  NARROW_BTN_LABEL: "絞り込み",
  CLEAR_BTN_LABEL: "クリア",
  BATCH_EDIT_BTN_LABEL: "一括設定",
  EDIT_BTN_LABEL: "設定",
  CONFIRM_TITLE: "確認",
  PLUS_BTN: "追加",
  BACK_TOP_TEXT: "Page Top",
  MODIFY_BTN: "変更",
  UNBIND_BTN: "解除",
  APPLY_BTN: "申請",
  NEXT_BTN: "次へ",
  SETTING_BTN: "設定",
  DELIVERY_OVER_TITLE: "送信完了",
  DELIVERY_OVER_TEXT:
    "登録確認Eメールを送信しました。受信したEメールを開き、アクティベーションを行ってください",
  DELIVERY_OVER_TIP: "アクティベーションされるまでログインすることはできません",
  TO_LOGIN_VIEW: "ログイン画面に戻る",
  CLOSE_BTN: "閉じる",
  ERROR_MODAL_TITLE: "エラー",
  HOME_FAQ_STATUS_SUFFIX: "について",
  COMPANY_NAME_SUFFIX: "様",
  ERROR_PAGE_404:
    "お探しのページは見つかりません。<br/>一時的にアクセスできない状況にあるか、移動もしくは削除された可能性があります。",
  ERROR_PAGE_BUTTON: "トップの画面に戻る",
  ERROR_PAGE_403: "指定されたURLへのアクセス権限がありません。",
  ERROR_PAGE_500:
    "サーバーエラーが発生しました。<br/>サーバーの問題でお探しのページを表示できません。再度時間をおいてアクセスしてください。",
};

export default global;
