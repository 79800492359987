/* tslint:disable */
/* eslint-disable */
/**
 * Safie API specification for Safie Developers
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.14.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 所属企業におけるロール情報
 * @export
 * @interface CorporationCorporationRole
 */
export interface CorporationCorporationRole {
  /**
   * ロール名
   * @type {string}
   * @memberof CorporationCorporationRole
   */
  name?: string;
  /**
   * ロールの種別
   * @type {string}
   * @memberof CorporationCorporationRole
   */
  category?: CorporationCorporationRoleCategoryEnum;
}

export const CorporationCorporationRoleCategoryEnum = {
  Admin: "admin",
  Default: "default",
  None: "none",
} as const;

export type CorporationCorporationRoleCategoryEnum =
  (typeof CorporationCorporationRoleCategoryEnum)[keyof typeof CorporationCorporationRoleCategoryEnum];
