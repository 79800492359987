import { getCookie } from "@/utils";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Layout from "@/common/layout/index.vue";
import store from "@/store";
import staticRoutes from "./staticRoute";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: {
      name: "home",
    },
    component: Layout,
    children: [
      // home
      {
        path: "/",
        name: "home",
        component: () => import("../views/HomeView.vue"),
        meta: {
          layoutType: "noAside",
        },
      },
      // static page
      ...staticRoutes,
      // application
      {
        path: "/applications",
        name: "applications",
        component: () =>
          import("../views/manager/application/ApplicationListView.vue"),
        meta: {
          auth: true,
          layoutType: "all",
          breadcrumb: [
            {
              name: "管理ツール",
            },
            {
              name: "アプリケーション一覧",
              to: "/applications",
            },
          ],
          category: "applications",
        },
      },
      {
        path: "/applications/apikey-list",
        name: "applicationApiList",
        component: () =>
          import("../views/manager/application/api/ApiListView.vue"),
        meta: {
          auth: true,
          layoutType: "all",
          breadcrumb: [
            {
              name: "管理ツール",
            },
            {
              name: "アプリケーション一覧",
              to: "/applications",
            },
            {
              name: "APIキー認証アプリケーション一覧",
              to: "/applications/apikey-list",
            },
          ],
          category: "api",
        },
      },
      {
        path: "/applications/apikey-detail/:id",
        name: "applicationApiEdit",
        component: () =>
          import("../views/manager/application/api/edit/index.vue"),
        meta: {
          auth: true,
          breadcrumb: [
            {
              name: "管理ツール",
            },
            {
              name: "アプリケーション一覧",
              to: "/applications",
            },
            {
              name: "APIキー認証",
              to: "/applications/apikey-list",
            },
            {
              name: "APIキー認証アプリケーション設定",
            },
          ],
          layoutType: "all",
          category: "api",
          name: "設定",
        },
      },
      {
        path: "/applications/apikey-create",
        name: "applicationApiAdd",
        component: () =>
          import("../views/manager/application/api/ApiAddView.vue"),
        meta: {
          auth: true,
          layoutType: "all",
          breadcrumb: [
            {
              name: "管理ツール",
            },
            {
              name: "アプリケーション一覧",
              to: "/applications",
            },
            {
              name: "APIキー認証",
              to: "/applications/apikey-list",
            },
            {
              name: "APIキー認証アプリケーション新規作成",
              to: "/applications/apikey-create",
            },
          ],
          category: "api",
          name: "新規作成",
        },
      },
      {
        path: "/applications/oauth-list",
        name: "applicationOAuthList",
        component: () =>
          import("../views/manager/application/oAuth/OAuthListView.vue"),
        meta: {
          auth: true,
          breadcrumb: [
            {
              name: "管理ツール",
            },
            {
              name: "アプリケーション一覧",
              to: "/applications",
            },
            {
              name: "OAuth2.0認証アプリケーション一覧",
            },
          ],
          layoutType: "all",
          category: "oauth",
        },
      },
      {
        path: "/applications/oauth-detail/:id",
        name: "applicationOAuthEdit",
        component: () =>
          import("../views/manager/application/oAuth/edit/index.vue"),
        meta: {
          auth: true,
          layoutType: "all",
          category: "oauth",
          breadcrumb: [
            {
              name: "管理ツール",
            },
            {
              name: "アプリケーション一覧",
              to: "/applications",
            },
            {
              name: "OAuth2.0認証",
              to: "/applications/oauth-list",
            },
            {
              name: "OAuth2.0認証アプリケーション設定",
            },
          ],
          name: "設定",
        },
      },
      {
        path: "/applications/oauth-create",
        name: "application",
        component: () =>
          import("../views/manager/application/oAuth/OAuthAddView.vue"),
        meta: {
          auth: true,
          layoutType: "all",
          category: "oauth",
          breadcrumb: [
            {
              name: "管理ツール",
            },
            {
              name: "アプリケーション一覧",
              to: "/applications",
            },
            {
              name: "OAuth2.0認証",
              to: "/applications/oauth-list",
            },
            {
              name: "OAuth2.0認証アプリケーション新規作成",
            },
          ],
          name: "新規作成",
        },
      },
      {
        path: "/applications/apikey-accessright/:id",
        name: "accessManage",
        component: () => import("../views/manager/access/index.vue"),
        meta: {
          auth: true,
          breadcrumb: [
            {
              name: "管理ツール",
            },
            {
              name: "アプリケーション一覧",
              to: "/applications",
            },
            {
              name: "APIキー認証",
              to: "/applications/apikey-list",
            },
            {
              name: "連携デバイス管理",
            },
          ],
          layoutType: "all",
          category: "api",
          name: "連携デバイス管理",
        },
      },
      // user setting
      {
        path: "login",
        name: "login",
        component: () => import("../views/LoginView.vue"),
        meta: {
          layoutType: "noAsideNoFooter",
        },
      },
      {
        path: "signup",
        name: "signup",
        component: () => import("../views/SignupView.vue"),
        meta: {
          layoutType: "noAsideNoFooter",
        },
      },
      {
        path: "password-reset",
        name: "passwordReset",
        component: () => import("../views/PasswordResetView.vue"),
        meta: {
          layoutType: "noAsideNoFooter",
        },
      },
      {
        path: "password-set",
        name: "passwordSet",
        component: () => import("../views/PasswordSet.vue"),
        meta: {
          auth: true,
          layoutType: "noAsideNoFooter",
        },
      },
      {
        path: "user-setting",
        name: "userInfo",
        component: () => import("../views/UserInfoView.vue"),
        meta: {
          auth: true,
          layoutType: "noAsideNoFooter",
        },
      },
      {
        path: "user-setting-audit",
        name: "userInfoAudit",
        component: () => import("../views/UserInfoView.vue"),
        meta: {
          auth: true,
          layoutType: "noAsideNoFooter",
        },
      },
      {
        path: "/user-setting-edit",
        name: "user-setting-edit",
        component: () => import("../views/manager/userinfo/edit/index.vue"),
        meta: {
          auth: true,
          breadcrumb: [
            {
              name: "管理ツール",
            },
            {
              name: "ディベロッパー情報",
            },
          ],
          layoutType: "all",
          category: "user-setting-edit",
        },
      },
      {
        path: "404",
        name: "404",
        component: () => import("../common/components/error/404.vue"),
        meta: {
          layoutType: "noAside",
        },
      },
      {
        path: "500",
        name: "500",
        component: () => import("../common/components/error/500.vue"),
        meta: {
          layoutType: "noAside",
        },
      },
      {
        path: "403",
        name: "403",
        component: () => import("../common/components/error/403.vue"),
        meta: {
          layoutType: "noAside",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: {
      name: "home",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  const initPassword = store.state.initPassword;
  const sessionIDCookieName =
    process.env.VUE_APP_SESSION_ID_COOKIE_NAME ?? "session_id";
  if (initPassword && !getCookie(sessionIDCookieName)) {
    store.commit("setInitPassword", undefined);
  }
  if (store.state.userSettingPath === to.path) {
    store.commit("setUserSettingUnload", undefined);
  } else if (to.meta.auth && !getCookie(sessionIDCookieName)) {
    if (to.path === "/password-set") {
      return { path: "/login" };
    } else {
      return { path: "/login", query: { redirect: to.path } };
    }
  } else if (
    to.meta.auth &&
    getCookie(sessionIDCookieName) &&
    !store.state.developerId &&
    !initPassword &&
    !(to.name === "userInfo" || to.name === "userInfoAudit")
  ) {
    return { path: "/user-setting" };
  } else if (
    to.meta.auth &&
    getCookie(sessionIDCookieName) &&
    !store.state.developerId &&
    initPassword &&
    to.path !== "/password-set"
  ) {
    return { path: "/password-set" };
  } else if (to.name === "userInfo") {
    const userinfo = store.state.userinfo;
    if (
      !(
        getCookie(sessionIDCookieName) &&
        userinfo &&
        !(userinfo.user_developer || userinfo.corporation_developer)
      )
    ) {
      return { path: "/" };
    }
  } else if (to.name === "userInfoAudit" && from.name !== "userInfo") {
    return { path: "/" };
  } else if (to.path === "/password-set" && !store.state.initPassword) {
    return { path: "/" };
  }
});

export default router;
