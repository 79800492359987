const userinfo = {
  TITLE: "ディベロッパー情報入力",
  SUB_TITLE: "入力内容の確認",
  SUB_TIP: "確認内容",
  SUB_TITLE_ACCOUNT: "アカウント情報",
  USERNAME_LABEL: "メールアドレス",
  PASSWORD_LABEL: "パスワード",
  DESCRIPTION_LABEL: "ディベロッパー名",
  DESCRIPTION_PLACEHOLDER: "（例）セーフィー株式会社",
  SUB_TITLE_CHARGE: "担当者情報",
  NAME_LABEL: "氏名",
  NAME1_LABEL: "氏名（姓）",
  NAME2_LABEL: "氏名（名）",
  NAME_KANA_LABEL: "氏名（カナ）",
  NAME_KANA1_LABEL: "氏名（カナ）（姓）",
  NAME_KANA2_LABEL: "氏名（カナ）（名）",
  USER_TYPE_LABEL: "法人 / 個人",
  USER_TYPE_COMPANY: "法人および個人事業主",
  USER_TYPE_PERSONAL: "個人",
  COMPANY_NAME_LABEL: "企業名",
  REPRESENTATIVE_NAME_LABEL: "代表者名",
  DEPARTMENT_LABEL: "部署名",
  POSTAL_CODE_LABEL: "郵便番号",
  SET_ADDRESS_BTN: "住所自動入力",
  ADDRESS_LABEL: "住所",
  PREFECTURE_LABEL: "都道府県",
  CITY_LABEL: "市区町村",
  TOWN_LABEL: "町名",
  STREET_LABEL: "丁目・番地",
  BUILDING_label: "建物名・部屋名",
  PHONE_NUMBER_LABEL: "電話番号",
  MAIL_LABEL: "メールアドレス",
  SIGNUP_ACCOUNT_TYPE_LABEL: "アカウント種別",
  SIGNUP_ACCOUNT_TYPE_GENERAL: "個人権限利用",
  SIGNUP_ACCOUNT_TYPE_ENTERPRISE: "Safie Manager 権限利用",
  IS_AGREE_LABEL_LINK: "Safie Developers利用規約",
  IS_AGREE_LABEL_TEXT: "の内容を確認の上、同意する",
  EDIT_SURE_BTN: "入力内容の確認",
  COMMIT_BTN: "登録",
  EDIT_AGAIN_BTN: "再編集",
  // user info edit
  EDIT_TITLE: "ディベロッパー情報",
  AUDIT_STATUS_LABEL: "アクセスできるデバイス",
  AUDIT_BUTTON_TEXT: "審査申請",
  AUDIT_FINISH_BUTTON_TEXT: "審査中",
  AUDIT_STATUS_NOT_VERIFIED: "自身がオーナーアカウントのデバイスのみ",
  AUDIT_STATUS_VERIFIED: "すべてのデバイス",
  SETTING_BUTTON_TEXT: "設定する",
  CORPORATION_LABEL: "Safie Manager連携",
  CORPORATION_VALUE: "{n}　連携中",
  TWO_STEP_SETTING: "二段階認証",
  MODIFY_TITLE: "{n}の変更",
  NOW_FORM_LABEL: "現在の{n}",
  NEW_FORM_LABEL: "新しい{n}",
  NEW_SURE_FORM_LABEL: "新しい{n}（確認）",
  DESCRIPTION_DIALOG_LABEL: "アカウント表示名",
  AUDIT_APPLY_TITLE: "審査申請",
  AUDIT_APPLY_LABEL:
    "法人の実在確認およびコンプライアンスチェックをさせていただきます。審査通過後、他のユーザーから許諾を得ることで、そのユーザーのデバイスにアクセスできるようになります。",
  CORPORATION_DIALOG_TITLE: "Safie Manager 権限での利用",
  CORPORATION_DIALOG_TEXT:
    "Safie Managerの権限での利用に変更します。変更後はSafie Managerの管理下となります。変更しますか？",
  MFA_DELETE_DIALOG_TITLE: "２段階認証の設定解除",
  MFA_DELETE_DIALOG_TIP:
    "本人の操作を確認するため、パスワードを入力してください。",
  MFA_SETTING_DIALOG_TITLE: "2段階認証の設定",
  MFA_SETTING_DIALOG_TIP1:
    "本人の操作を確認するため、パスワードを入力してください。",
  MFA_SETTING_DIALOG_TIP2: "以下よりGoogle Authenticationを設定してください",
  MFA_SETTING_DIALOG_TIP3:
    "QRコードが読み取れない場合は、以下のシークレットキーを手動で認証アプリに入力してください",
  MFA_STEP_ONE: "パスワード認証",
  MFA_STEP_TWO: "2段階認証",
  SECRET_LABEL: "ワンタイムパスワード",
  ADDRESS_ERROR: "住所（{n}）",
  IS_SETTING: "設定済み",
  UN_SETTING: "未設定",
  EMAIL_EXIST_ERROR: "すでに登録されたメールアドレス",
  EMAIL_ERROR_ERROR: "Manager権限に所属しているメールアドレス",
  PASSWORD_SAME: "現在のパスワードと同じものは設定できません",
  MAIL_ADDRESS_SAME: "現在のメールアドレスと同じものは設定できません",
  PASSWORD_ERROR: "パスワードが正しくありません",
  USED_MAIL_ADDRESS_ERROR:
    "指定したメールアドレスはすでにディベロッパーとして登録されています",
  JUDGING_REASON:
    "他のユーザーのデバイスにアクセスできるようにするためには事前審査が必要です。個人でディベロッパー登録されている方は審査申請をいただいても自動的に不通過となります。ご了承ください",
};
export default userinfo;
