import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import router from "@/router";
import { removeCookie } from "@/utils";
import store from "@/store";

export enum RequestErrorType {
  Unknown = "Unknown",
  BadRequest = "BadRequest",
  Unauthorized = "Unauthorized",
  Forbidden = "Forbidden",
  NotFound = "NotFound",
  InternalServerError = "InternalServerError",
  TimeoutError = "TimeoutError",
  NetworkError = "NetworkError",
  ParamsInvalidError = "ParamsInvalidError",
  ExclusionError = "ExclusionError",
  ServerUnavailable = "ServerUnavailable",
}

export enum HttpStatus {
  OK = 200,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
  ServerUnavailable = 503,
}

const getRequestErrorType = (status: number) => {
  switch (status) {
    case 400:
      return RequestErrorType.BadRequest;
    case 401:
      return RequestErrorType.Unauthorized;
    case 403:
      return RequestErrorType.Forbidden;
    case 404:
      return RequestErrorType.NotFound;
    case 500:
      return RequestErrorType.InternalServerError;
    case 503:
      return RequestErrorType.ServerUnavailable;
    case 504:
      return RequestErrorType.TimeoutError;
    default:
      return RequestErrorType.Unknown;
  }
};

const axiosInstance = axios.create();
axiosInstance.defaults.headers.post["Content-Type"] =
  "application/json;application/x-www-form-urlencoded;charset=utf8;multipart/form-data";
axiosInstance.defaults.withCredentials = true;
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    store.commit("closeLoading");
    if (response.status === HttpStatus.OK) {
      return Promise.resolve(response);
    }
    // 2xx status, except 200
    return Promise.reject({ response });
  },
  (error: AxiosError) => {
    store.commit("closeLoading");
    handleErrorResponse(error);
    return Promise.reject(error);
  },
);

const toLoginPage = () => {
  const path = router.currentRoute.value.path;
  const sessionIDCookieName =
    process.env.VUE_APP_SESSION_ID_COOKIE_NAME ?? "session_id";
  removeCookie(sessionIDCookieName);
  store.commit("setIsLogin", false);
  if (path !== "/login") {
    if (path !== "/") {
      router.push({ path: "/login", query: { redirect: path } });
    } else {
      router.push({ path: "/login" });
    }
  }
};

const handleErrorResponse = (error: AxiosError) => {
  if (error.response) {
    const response = error.response as AxiosResponse;
    const type = getRequestErrorType(response.status);
    switch (type) {
      case RequestErrorType.Unauthorized:
        toLoginPage();
        break;
      case RequestErrorType.BadRequest:
        if (response.data.error === "invalid_token") {
          toLoginPage();
        }
        break;
      case RequestErrorType.Forbidden:
        router.push({ path: "/403" });
        break;
      case RequestErrorType.NotFound:
        router.push({ path: "/404" });
        break;
      case RequestErrorType.InternalServerError:
      case RequestErrorType.ServerUnavailable:
        if (!response.data.code) {
          router.push({ path: "/500" });
        }
        break;
      default:
        break;
    }
  }
};

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    store.commit("openLoading");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axiosInstance;
