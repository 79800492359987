import global from "./global";
import login from "./login";
import signup from "./signup";
import pwdReset from "./passwordReset";
import userinfo from "./userinfo";
import applicationList from "./applicationList";
import application from "./application";
import access from "./accessManager";
import oAuth from "./oAuth";
import layout from "./layout";
import elementPlus from "./elementPlus";
import staticPage from "./staticPage";
const ja = {
  global,
  login,
  signup,
  pwdReset,
  userinfo,
  applicationList,
  application,
  access,
  oAuth,
  layout,
  elementPlus,
  staticPage,
};

export default ja;
