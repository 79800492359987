/* tslint:disable */
/* eslint-disable */
/**
 * Safie API specification for Safie Developers
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.14.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { ApikeyApplicationInstallResponse } from "../models";
// @ts-ignore
import { ApikeyApplicationInstallationDevicesResponse } from "../models";
// @ts-ignore
import { ApikeyApplicationList } from "../models";
// @ts-ignore
import { ApikeyApplicationListResponse } from "../models";
// @ts-ignore
import { ApikeyApplicationResponse } from "../models";
// @ts-ignore
import { ApplicationInstallationPatchRequest } from "../models";
// @ts-ignore
import { ApplicationInstallationRequest } from "../models";
// @ts-ignore
import { MfaResponse } from "../models";
// @ts-ignore
import { Model400 } from "../models";
// @ts-ignore
import { Model400ChangeMailAddress } from "../models";
// @ts-ignore
import { Model400OldFormat } from "../models";
// @ts-ignore
import { Model401 } from "../models";
// @ts-ignore
import { Model403 } from "../models";
// @ts-ignore
import { Model404 } from "../models";
// @ts-ignore
import { Model428 } from "../models";
// @ts-ignore
import { Model500 } from "../models";
// @ts-ignore
import { Model503 } from "../models";
// @ts-ignore
import { Oauth2ApplicationList } from "../models";
// @ts-ignore
import { Success } from "../models";
// @ts-ignore
import { UserCreateResponse } from "../models";
/**
 * SafieViewerAPIApi - axios parameter creator
 * @export
 */
export const SafieViewerAPIApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * XSRF Cookieを取得する。リクエストが成功すると、_xsrfのCookieが設定される。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。
     * @summary XSRF Cookie 取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCertificateGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/certificate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 特定のアプリケーションに対して利用申請しているデバイスの一覧を取得する。 APIを実行したユーザーがapp_integration権限を持っているデバイスのみが返される。  前提条件:   - 認証: useスコープ
     * @summary アプリケーション利用中デバイス一覧取得
     * @param {number} applicationId アプリケーションID
     * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
     * @param {number} [offset] 返却するリストのオフセット, 既定値0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiApplicationInstallationsDevicesGet: async (
      applicationId: number,
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "apiOpenapiApplicationInstallationsDevicesGet",
        "applicationId",
        applicationId,
      );
      const localVarPath =
        `/api/openapi/application_installations/{application_id}/devices`.replace(
          `{${"application_id"}}`,
          encodeURIComponent(String(applicationId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * APIを実行したユーザーがapp_integration権限を持っているデバイス全てについて、 アプリケーション利用申請を行っているアプリケーションの一覧を取得する。  前提条件:   - 認証: useスコープ
     * @summary 利用中アプリケーション一覧取得
     * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
     * @param {number} [offset] 返却するリストのオフセット, 既定値0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiApplicationInstallationsGet: async (
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/openapi/application_installations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したデバイスおよびアプリケーションの利用状況を取得します。  前提条件   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - 指定されたデバイスで、指定されたアプリケーションが利用されていること
     * @summary アプリケーション利用取得
     * @param {string} deviceId デバイスID
     * @param {number} applicationId アプリケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiDevicesApplicationInstallationsApplicationIdGet: async (
      deviceId: string,
      applicationId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists(
        "apiOpenapiDevicesApplicationInstallationsApplicationIdGet",
        "deviceId",
        deviceId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "apiOpenapiDevicesApplicationInstallationsApplicationIdGet",
        "applicationId",
        applicationId,
      );
      const localVarPath =
        `/api/openapi/devices/{device_id}/application_installations/{application_id}`
          .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)))
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定されたアプリケーション利用の設定を変更する。アプリケーション利用が一時停止に変更されると、 そのアプリケーションが持つAPIキーによるデバイス情報へのアクセスが権限エラーとなる。  前提条件:   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - アプリケーションIDで指定されるアプリケーションを利用中であるか利用申請中であること (審査状況は問わない)
     * @summary アプリケーション利用変更
     * @param {string} deviceId デバイスID
     * @param {number} applicationId アプリケーションID
     * @param {ApplicationInstallationPatchRequest} applicationInstallationPatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiDevicesApplicationInstallationsApplicationIdPatch: async (
      deviceId: string,
      applicationId: number,
      applicationInstallationPatchRequest: ApplicationInstallationPatchRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists(
        "apiOpenapiDevicesApplicationInstallationsApplicationIdPatch",
        "deviceId",
        deviceId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "apiOpenapiDevicesApplicationInstallationsApplicationIdPatch",
        "applicationId",
        applicationId,
      );
      // verify required parameter 'applicationInstallationPatchRequest' is not null or undefined
      assertParamExists(
        "apiOpenapiDevicesApplicationInstallationsApplicationIdPatch",
        "applicationInstallationPatchRequest",
        applicationInstallationPatchRequest,
      );
      const localVarPath =
        `/api/openapi/devices/{device_id}/application_installations/{application_id}`
          .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)))
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        applicationInstallationPatchRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したデバイスが利用中のAPIキー認証アプリケーションの一覧を取得します。  前提条件   - 認証: useスコープ
     * @summary アプリケーション利用一覧取得
     * @param {string} deviceId デバイスID
     * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
     * @param {number} [offset] 返却するリストのオフセット, 既定値0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiDevicesApplicationInstallationsGet: async (
      deviceId: string,
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists(
        "apiOpenapiDevicesApplicationInstallationsGet",
        "deviceId",
        deviceId,
      );
      const localVarPath =
        `/api/openapi/devices/{device_id}/application_installations`.replace(
          `{${"device_id"}}`,
          encodeURIComponent(String(deviceId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * アプリケーションコードで指定されたアプリケーションの利用を申請する。  前提条件:   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - アプリケーションコードで識別されるアプリケーションが存在し、以下のいずれかの状態であること     - アプリケーションが、ログインユーザーと関連付けられたディベロッパー（もしくは、ログインユーザが全体管理者である企業に関連付けられたディベロッパー）に属していること     - アプリケーションが公開中であり、アプリケーションが属するディベロッパーが審査済みであること   - アプリケーションのapplication_auth_typeがapikeyであること
     * @summary アプリケーション利用申請
     * @param {string} xXSRFToken
     * @param {string} deviceId デバイスID
     * @param {number} applicationId アプリケーションID
     * @param {ApplicationInstallationRequest} applicationInstallationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiDevicesApplicationInstallationsPost: async (
      xXSRFToken: string,
      deviceId: string,
      applicationId: number,
      applicationInstallationRequest: ApplicationInstallationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists(
        "apiOpenapiDevicesApplicationInstallationsPost",
        "xXSRFToken",
        xXSRFToken,
      );
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists(
        "apiOpenapiDevicesApplicationInstallationsPost",
        "deviceId",
        deviceId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "apiOpenapiDevicesApplicationInstallationsPost",
        "applicationId",
        applicationId,
      );
      // verify required parameter 'applicationInstallationRequest' is not null or undefined
      assertParamExists(
        "apiOpenapiDevicesApplicationInstallationsPost",
        "applicationInstallationRequest",
        applicationInstallationRequest,
      );
      const localVarPath =
        `/api/openapi/devices/{device_id}/application_installations`
          .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)))
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieXSRF required

      // authentication cookieAuth required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        applicationInstallationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * アプリケーションコードで指定されたアプリケーションの情報を取得し、対象デバイスで利用可能かどうか判断する。利用可能でない場合は404のステータスで応答が得られる。  前提条件:   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - アプリケーションコードで識別されるアプリケーションが存在し、以下のいずれかの状態であること     - アプリケーションが、ログインユーザーに関連付けられたディベロッパー（もしくは、ログインユーザが全体管理者である企業に関連付けられたディベロッパー）が作成したものであること     - アプリケーションが公開中であり、アプリケーションが属するディベロッパーが審査済みであること   - アプリケーションのapplication_auth_typeがapikeyであること
     * @summary アプリケーション利用申請プレビュー
     * @param {string} xXSRFToken
     * @param {string} deviceId デバイスID
     * @param {number} applicationId アプリケーションID
     * @param {ApplicationInstallationRequest} applicationInstallationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiDevicesApplicationInstallationsPreviewPost: async (
      xXSRFToken: string,
      deviceId: string,
      applicationId: number,
      applicationInstallationRequest: ApplicationInstallationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists(
        "apiOpenapiDevicesApplicationInstallationsPreviewPost",
        "xXSRFToken",
        xXSRFToken,
      );
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists(
        "apiOpenapiDevicesApplicationInstallationsPreviewPost",
        "deviceId",
        deviceId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "apiOpenapiDevicesApplicationInstallationsPreviewPost",
        "applicationId",
        applicationId,
      );
      // verify required parameter 'applicationInstallationRequest' is not null or undefined
      assertParamExists(
        "apiOpenapiDevicesApplicationInstallationsPreviewPost",
        "applicationInstallationRequest",
        applicationInstallationRequest,
      );
      const localVarPath =
        `/api/openapi/devices/{device_id}/application_installations/preview`
          .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)))
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieXSRF required

      // authentication cookieAuth required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        applicationInstallationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * ログインユーザーがOAuth2.0認証により払い出した有効なアクセストークンを持つアプリケーションの一覧を取得する。 この一覧にv1 APIで払い出したアクセストークンに関する情報は含まれない。  前提条件   - 認証: useスコープ
     * @summary OAuth2アプリケーション一覧取得
     * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
     * @param {number} [offset] 返却するリストのオフセット, 既定値0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiOauth2ApplicationsGet: async (
      limit?: number,
      offset?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/openapi/oauth2_applications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * ログインユーザーが認可した特定のアプリケーションに関連するOAuth2アクセストークンを無効化する。 無効化されたアクセストークンはSafie API呼び出しに使用できなくなり、また関連付けられたリフレッシュトークンはリフレッシュに使用できなくなる。  前提条件   - 認証: useスコープ   - 指定したアプリケーションがログインユーザーが過去に認可したものであること
     * @summary OAuth2アプリケーションの無効化
     * @param {number} applicationId アプリケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiOauth2ApplicationsTokensDelete: async (
      applicationId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "apiOpenapiOauth2ApplicationsTokensDelete",
        "applicationId",
        applicationId,
      );
      const localVarPath =
        `/api/openapi/oauth2_applications/{application_id}/tokens`.replace(
          `{${"application_id"}}`,
          encodeURIComponent(String(applicationId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * メールアドレスの変更を要求します。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
     * @summary Safieユーザのメールアドレス変更
     * @param {string} xXSRFToken
     * @param {string} mailAddress 変更後のメールアドレス
     * @param {string} [provider] &#x60;safie&#x60; の文字列を固定で指定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserChangeMailAddressPost: async (
      xXSRFToken: string,
      mailAddress: string,
      provider?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists(
        "apiUserChangeMailAddressPost",
        "xXSRFToken",
        xXSRFToken,
      );
      // verify required parameter 'mailAddress' is not null or undefined
      assertParamExists(
        "apiUserChangeMailAddressPost",
        "mailAddress",
        mailAddress,
      );
      const localVarPath = `/api/user/change_mail_address`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new URLSearchParams();

      // authentication cookieXSRF required

      // authentication cookieAuth required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      if (mailAddress !== undefined) {
        localVarFormParams.set("mail_address", mailAddress as any);
      }

      if (provider !== undefined) {
        localVarFormParams.set("provider", provider as any);
      }

      localVarHeaderParameter["Content-Type"] =
        "application/x-www-form-urlencoded";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams.toString();

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * パスワードの変更を行います。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
     * @summary Safieユーザのパスワード変更
     * @param {string} xXSRFToken
     * @param {string} currentPassword 変更前のパスワード
     * @param {string} newPassword 新パスワード
     * @param {string} confirmPassword 確認用パスワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserChangePasswordPost: async (
      xXSRFToken: string,
      currentPassword: string,
      newPassword: string,
      confirmPassword: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists("apiUserChangePasswordPost", "xXSRFToken", xXSRFToken);
      // verify required parameter 'currentPassword' is not null or undefined
      assertParamExists(
        "apiUserChangePasswordPost",
        "currentPassword",
        currentPassword,
      );
      // verify required parameter 'newPassword' is not null or undefined
      assertParamExists(
        "apiUserChangePasswordPost",
        "newPassword",
        newPassword,
      );
      // verify required parameter 'confirmPassword' is not null or undefined
      assertParamExists(
        "apiUserChangePasswordPost",
        "confirmPassword",
        confirmPassword,
      );
      const localVarPath = `/api/user/change_password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new URLSearchParams();

      // authentication cookieXSRF required

      // authentication cookieAuth required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      if (currentPassword !== undefined) {
        localVarFormParams.set("current_password", currentPassword as any);
      }

      if (newPassword !== undefined) {
        localVarFormParams.set("new_password", newPassword as any);
      }

      if (confirmPassword !== undefined) {
        localVarFormParams.set("confirm_password", confirmPassword as any);
      }

      localVarHeaderParameter["Content-Type"] =
        "application/x-www-form-urlencoded";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams.toString();

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Safieユーザの新規作成を要求する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。
     * @summary Safieユーザのサインアップ
     * @param {string} xXSRFToken
     * @param {string} mailAddress ユーザのメールアドレス
     * @param {string} password パスワード
     * @param {string} [provider] &#x60;safie&#x60; の文字列を固定で指定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserCreatePost: async (
      xXSRFToken: string,
      mailAddress: string,
      password: string,
      provider?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists("apiUserCreatePost", "xXSRFToken", xXSRFToken);
      // verify required parameter 'mailAddress' is not null or undefined
      assertParamExists("apiUserCreatePost", "mailAddress", mailAddress);
      // verify required parameter 'password' is not null or undefined
      assertParamExists("apiUserCreatePost", "password", password);
      const localVarPath = `/api/user/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new URLSearchParams();

      // authentication cookieXSRF required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      if (mailAddress !== undefined) {
        localVarFormParams.set("mail_address", mailAddress as any);
      }

      if (password !== undefined) {
        localVarFormParams.set("password", password as any);
      }

      if (provider !== undefined) {
        localVarFormParams.set("provider", provider as any);
      }

      localVarHeaderParameter["Content-Type"] =
        "application/x-www-form-urlencoded";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams.toString();

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 二段階認証設定の認証子登録を確認し、二段階認証を有効化する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
     * @summary Safieユーザの二段階認証設定 認証子登録確認
     * @param {string} xXSRFToken
     * @param {string} [authenticatorType] 認証子の種類（&#x60;otp&#x60;のみ設定可能）
     * @param {string} [otp] ワンタイムパスワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserMfaConfirmPost: async (
      xXSRFToken: string,
      authenticatorType?: string,
      otp?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists("apiUserMfaConfirmPost", "xXSRFToken", xXSRFToken);
      const localVarPath = `/api/user/mfa/confirm`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new URLSearchParams();

      // authentication cookieXSRF required

      // authentication cookieAuth required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      if (authenticatorType !== undefined) {
        localVarFormParams.set("authenticator_type", authenticatorType as any);
      }

      if (otp !== undefined) {
        localVarFormParams.set("otp", otp as any);
      }

      localVarHeaderParameter["Content-Type"] =
        "application/x-www-form-urlencoded";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams.toString();

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * ユーザに設定されている二段階認証設定を解除する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
     * @summary Safieユーザの二段階認証設定解除
     * @param {string} xXSRFToken
     * @param {string} [password] パスワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserMfaDeletePost: async (
      xXSRFToken: string,
      password?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists("apiUserMfaDeletePost", "xXSRFToken", xXSRFToken);
      const localVarPath = `/api/user/mfa/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new URLSearchParams();

      // authentication cookieXSRF required

      // authentication cookieAuth required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      if (password !== undefined) {
        localVarFormParams.set("password", password as any);
      }

      localVarHeaderParameter["Content-Type"] =
        "application/x-www-form-urlencoded";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams.toString();

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 二段階認証設定の認証子登録を行い、otpauth URIを発行する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
     * @summary Safieユーザの二段階認証設定 認証子登録
     * @param {string} xXSRFToken
     * @param {string} [authenticatorType] 認証子の種類（&#x60;otp&#x60;のみ設定可能）
     * @param {string} [password] パスワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserMfaPost: async (
      xXSRFToken: string,
      authenticatorType?: string,
      password?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists("apiUserMfaPost", "xXSRFToken", xXSRFToken);
      const localVarPath = `/api/user/mfa`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new URLSearchParams();

      // authentication cookieXSRF required

      // authentication cookieAuth required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      if (authenticatorType !== undefined) {
        localVarFormParams.set("authenticator_type", authenticatorType as any);
      }

      if (password !== undefined) {
        localVarFormParams.set("password", password as any);
      }

      localVarHeaderParameter["Content-Type"] =
        "application/x-www-form-urlencoded";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams.toString();

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * パスワードのリセットを要求します。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。
     * @summary Safieユーザのパスワードリセット
     * @param {string} xXSRFToken
     * @param {string} mailAddress パスワード変更を要求するSafieアカウントのメールアドレス
     * @param {string} [provider] &#x60;safie&#x60; の文字列を固定で指定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserRequestResetPasswordPost: async (
      xXSRFToken: string,
      mailAddress: string,
      provider?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists(
        "apiUserRequestResetPasswordPost",
        "xXSRFToken",
        xXSRFToken,
      );
      // verify required parameter 'mailAddress' is not null or undefined
      assertParamExists(
        "apiUserRequestResetPasswordPost",
        "mailAddress",
        mailAddress,
      );
      const localVarPath = `/user/request_reset_password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new URLSearchParams();

      // authentication cookieXSRF required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      if (mailAddress !== undefined) {
        localVarFormParams.set("mail_address", mailAddress as any);
      }

      if (provider !== undefined) {
        localVarFormParams.set("provider", provider as any);
      }

      localVarHeaderParameter["Content-Type"] =
        "application/x-www-form-urlencoded";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams.toString();

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SafieViewerAPIApi - functional programming interface
 * @export
 */
export const SafieViewerAPIApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SafieViewerAPIApiAxiosParamCreator(configuration);
  return {
    /**
     * XSRF Cookieを取得する。リクエストが成功すると、_xsrfのCookieが設定される。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。
     * @summary XSRF Cookie 取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCertificateGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiCertificateGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 特定のアプリケーションに対して利用申請しているデバイスの一覧を取得する。 APIを実行したユーザーがapp_integration権限を持っているデバイスのみが返される。  前提条件:   - 認証: useスコープ
     * @summary アプリケーション利用中デバイス一覧取得
     * @param {number} applicationId アプリケーションID
     * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
     * @param {number} [offset] 返却するリストのオフセット, 既定値0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiOpenapiApplicationInstallationsDevicesGet(
      applicationId: number,
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApikeyApplicationInstallationDevicesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiOpenapiApplicationInstallationsDevicesGet(
          applicationId,
          limit,
          offset,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * APIを実行したユーザーがapp_integration権限を持っているデバイス全てについて、 アプリケーション利用申請を行っているアプリケーションの一覧を取得する。  前提条件:   - 認証: useスコープ
     * @summary 利用中アプリケーション一覧取得
     * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
     * @param {number} [offset] 返却するリストのオフセット, 既定値0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiOpenapiApplicationInstallationsGet(
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApikeyApplicationListResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiOpenapiApplicationInstallationsGet(
          limit,
          offset,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したデバイスおよびアプリケーションの利用状況を取得します。  前提条件   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - 指定されたデバイスで、指定されたアプリケーションが利用されていること
     * @summary アプリケーション利用取得
     * @param {string} deviceId デバイスID
     * @param {number} applicationId アプリケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiOpenapiDevicesApplicationInstallationsApplicationIdGet(
      deviceId: string,
      applicationId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApikeyApplicationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiOpenapiDevicesApplicationInstallationsApplicationIdGet(
          deviceId,
          applicationId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定されたアプリケーション利用の設定を変更する。アプリケーション利用が一時停止に変更されると、 そのアプリケーションが持つAPIキーによるデバイス情報へのアクセスが権限エラーとなる。  前提条件:   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - アプリケーションIDで指定されるアプリケーションを利用中であるか利用申請中であること (審査状況は問わない)
     * @summary アプリケーション利用変更
     * @param {string} deviceId デバイスID
     * @param {number} applicationId アプリケーションID
     * @param {ApplicationInstallationPatchRequest} applicationInstallationPatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiOpenapiDevicesApplicationInstallationsApplicationIdPatch(
      deviceId: string,
      applicationId: number,
      applicationInstallationPatchRequest: ApplicationInstallationPatchRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApikeyApplicationResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiOpenapiDevicesApplicationInstallationsApplicationIdPatch(
          deviceId,
          applicationId,
          applicationInstallationPatchRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したデバイスが利用中のAPIキー認証アプリケーションの一覧を取得します。  前提条件   - 認証: useスコープ
     * @summary アプリケーション利用一覧取得
     * @param {string} deviceId デバイスID
     * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
     * @param {number} [offset] 返却するリストのオフセット, 既定値0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiOpenapiDevicesApplicationInstallationsGet(
      deviceId: string,
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApikeyApplicationList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiOpenapiDevicesApplicationInstallationsGet(
          deviceId,
          limit,
          offset,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * アプリケーションコードで指定されたアプリケーションの利用を申請する。  前提条件:   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - アプリケーションコードで識別されるアプリケーションが存在し、以下のいずれかの状態であること     - アプリケーションが、ログインユーザーと関連付けられたディベロッパー（もしくは、ログインユーザが全体管理者である企業に関連付けられたディベロッパー）に属していること     - アプリケーションが公開中であり、アプリケーションが属するディベロッパーが審査済みであること   - アプリケーションのapplication_auth_typeがapikeyであること
     * @summary アプリケーション利用申請
     * @param {string} xXSRFToken
     * @param {string} deviceId デバイスID
     * @param {number} applicationId アプリケーションID
     * @param {ApplicationInstallationRequest} applicationInstallationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiOpenapiDevicesApplicationInstallationsPost(
      xXSRFToken: string,
      deviceId: string,
      applicationId: number,
      applicationInstallationRequest: ApplicationInstallationRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApikeyApplicationInstallResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiOpenapiDevicesApplicationInstallationsPost(
          xXSRFToken,
          deviceId,
          applicationId,
          applicationInstallationRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * アプリケーションコードで指定されたアプリケーションの情報を取得し、対象デバイスで利用可能かどうか判断する。利用可能でない場合は404のステータスで応答が得られる。  前提条件:   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - アプリケーションコードで識別されるアプリケーションが存在し、以下のいずれかの状態であること     - アプリケーションが、ログインユーザーに関連付けられたディベロッパー（もしくは、ログインユーザが全体管理者である企業に関連付けられたディベロッパー）が作成したものであること     - アプリケーションが公開中であり、アプリケーションが属するディベロッパーが審査済みであること   - アプリケーションのapplication_auth_typeがapikeyであること
     * @summary アプリケーション利用申請プレビュー
     * @param {string} xXSRFToken
     * @param {string} deviceId デバイスID
     * @param {number} applicationId アプリケーションID
     * @param {ApplicationInstallationRequest} applicationInstallationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiOpenapiDevicesApplicationInstallationsPreviewPost(
      xXSRFToken: string,
      deviceId: string,
      applicationId: number,
      applicationInstallationRequest: ApplicationInstallationRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApikeyApplicationInstallResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiOpenapiDevicesApplicationInstallationsPreviewPost(
          xXSRFToken,
          deviceId,
          applicationId,
          applicationInstallationRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * ログインユーザーがOAuth2.0認証により払い出した有効なアクセストークンを持つアプリケーションの一覧を取得する。 この一覧にv1 APIで払い出したアクセストークンに関する情報は含まれない。  前提条件   - 認証: useスコープ
     * @summary OAuth2アプリケーション一覧取得
     * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
     * @param {number} [offset] 返却するリストのオフセット, 既定値0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiOpenapiOauth2ApplicationsGet(
      limit?: number,
      offset?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Oauth2ApplicationList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiOpenapiOauth2ApplicationsGet(
          limit,
          offset,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * ログインユーザーが認可した特定のアプリケーションに関連するOAuth2アクセストークンを無効化する。 無効化されたアクセストークンはSafie API呼び出しに使用できなくなり、また関連付けられたリフレッシュトークンはリフレッシュに使用できなくなる。  前提条件   - 認証: useスコープ   - 指定したアプリケーションがログインユーザーが過去に認可したものであること
     * @summary OAuth2アプリケーションの無効化
     * @param {number} applicationId アプリケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiOpenapiOauth2ApplicationsTokensDelete(
      applicationId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiOpenapiOauth2ApplicationsTokensDelete(
          applicationId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * メールアドレスの変更を要求します。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
     * @summary Safieユーザのメールアドレス変更
     * @param {string} xXSRFToken
     * @param {string} mailAddress 変更後のメールアドレス
     * @param {string} [provider] &#x60;safie&#x60; の文字列を固定で指定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUserChangeMailAddressPost(
      xXSRFToken: string,
      mailAddress: string,
      provider?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiUserChangeMailAddressPost(
          xXSRFToken,
          mailAddress,
          provider,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * パスワードの変更を行います。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
     * @summary Safieユーザのパスワード変更
     * @param {string} xXSRFToken
     * @param {string} currentPassword 変更前のパスワード
     * @param {string} newPassword 新パスワード
     * @param {string} confirmPassword 確認用パスワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUserChangePasswordPost(
      xXSRFToken: string,
      currentPassword: string,
      newPassword: string,
      confirmPassword: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiUserChangePasswordPost(
          xXSRFToken,
          currentPassword,
          newPassword,
          confirmPassword,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Safieユーザの新規作成を要求する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。
     * @summary Safieユーザのサインアップ
     * @param {string} xXSRFToken
     * @param {string} mailAddress ユーザのメールアドレス
     * @param {string} password パスワード
     * @param {string} [provider] &#x60;safie&#x60; の文字列を固定で指定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUserCreatePost(
      xXSRFToken: string,
      mailAddress: string,
      password: string,
      provider?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserCreateResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiUserCreatePost(
          xXSRFToken,
          mailAddress,
          password,
          provider,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 二段階認証設定の認証子登録を確認し、二段階認証を有効化する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
     * @summary Safieユーザの二段階認証設定 認証子登録確認
     * @param {string} xXSRFToken
     * @param {string} [authenticatorType] 認証子の種類（&#x60;otp&#x60;のみ設定可能）
     * @param {string} [otp] ワンタイムパスワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUserMfaConfirmPost(
      xXSRFToken: string,
      authenticatorType?: string,
      otp?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiUserMfaConfirmPost(
          xXSRFToken,
          authenticatorType,
          otp,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * ユーザに設定されている二段階認証設定を解除する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
     * @summary Safieユーザの二段階認証設定解除
     * @param {string} xXSRFToken
     * @param {string} [password] パスワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUserMfaDeletePost(
      xXSRFToken: string,
      password?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiUserMfaDeletePost(
          xXSRFToken,
          password,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 二段階認証設定の認証子登録を行い、otpauth URIを発行する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
     * @summary Safieユーザの二段階認証設定 認証子登録
     * @param {string} xXSRFToken
     * @param {string} [authenticatorType] 認証子の種類（&#x60;otp&#x60;のみ設定可能）
     * @param {string} [password] パスワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUserMfaPost(
      xXSRFToken: string,
      authenticatorType?: string,
      password?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MfaResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserMfaPost(
        xXSRFToken,
        authenticatorType,
        password,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * パスワードのリセットを要求します。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。
     * @summary Safieユーザのパスワードリセット
     * @param {string} xXSRFToken
     * @param {string} mailAddress パスワード変更を要求するSafieアカウントのメールアドレス
     * @param {string} [provider] &#x60;safie&#x60; の文字列を固定で指定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiUserRequestResetPasswordPost(
      xXSRFToken: string,
      mailAddress: string,
      provider?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiUserRequestResetPasswordPost(
          xXSRFToken,
          mailAddress,
          provider,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SafieViewerAPIApi - factory interface
 * @export
 */
export const SafieViewerAPIApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SafieViewerAPIApiFp(configuration);
  return {
    /**
     * XSRF Cookieを取得する。リクエストが成功すると、_xsrfのCookieが設定される。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。
     * @summary XSRF Cookie 取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCertificateGet(options?: any): AxiosPromise<Success> {
      return localVarFp
        .apiCertificateGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 特定のアプリケーションに対して利用申請しているデバイスの一覧を取得する。 APIを実行したユーザーがapp_integration権限を持っているデバイスのみが返される。  前提条件:   - 認証: useスコープ
     * @summary アプリケーション利用中デバイス一覧取得
     * @param {number} applicationId アプリケーションID
     * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
     * @param {number} [offset] 返却するリストのオフセット, 既定値0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiApplicationInstallationsDevicesGet(
      applicationId: number,
      limit?: number,
      offset?: number,
      options?: any,
    ): AxiosPromise<ApikeyApplicationInstallationDevicesResponse> {
      return localVarFp
        .apiOpenapiApplicationInstallationsDevicesGet(
          applicationId,
          limit,
          offset,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * APIを実行したユーザーがapp_integration権限を持っているデバイス全てについて、 アプリケーション利用申請を行っているアプリケーションの一覧を取得する。  前提条件:   - 認証: useスコープ
     * @summary 利用中アプリケーション一覧取得
     * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
     * @param {number} [offset] 返却するリストのオフセット, 既定値0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiApplicationInstallationsGet(
      limit?: number,
      offset?: number,
      options?: any,
    ): AxiosPromise<ApikeyApplicationListResponse> {
      return localVarFp
        .apiOpenapiApplicationInstallationsGet(limit, offset, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したデバイスおよびアプリケーションの利用状況を取得します。  前提条件   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - 指定されたデバイスで、指定されたアプリケーションが利用されていること
     * @summary アプリケーション利用取得
     * @param {string} deviceId デバイスID
     * @param {number} applicationId アプリケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiDevicesApplicationInstallationsApplicationIdGet(
      deviceId: string,
      applicationId: number,
      options?: any,
    ): AxiosPromise<ApikeyApplicationResponse> {
      return localVarFp
        .apiOpenapiDevicesApplicationInstallationsApplicationIdGet(
          deviceId,
          applicationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定されたアプリケーション利用の設定を変更する。アプリケーション利用が一時停止に変更されると、 そのアプリケーションが持つAPIキーによるデバイス情報へのアクセスが権限エラーとなる。  前提条件:   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - アプリケーションIDで指定されるアプリケーションを利用中であるか利用申請中であること (審査状況は問わない)
     * @summary アプリケーション利用変更
     * @param {string} deviceId デバイスID
     * @param {number} applicationId アプリケーションID
     * @param {ApplicationInstallationPatchRequest} applicationInstallationPatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiDevicesApplicationInstallationsApplicationIdPatch(
      deviceId: string,
      applicationId: number,
      applicationInstallationPatchRequest: ApplicationInstallationPatchRequest,
      options?: any,
    ): AxiosPromise<ApikeyApplicationResponse> {
      return localVarFp
        .apiOpenapiDevicesApplicationInstallationsApplicationIdPatch(
          deviceId,
          applicationId,
          applicationInstallationPatchRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したデバイスが利用中のAPIキー認証アプリケーションの一覧を取得します。  前提条件   - 認証: useスコープ
     * @summary アプリケーション利用一覧取得
     * @param {string} deviceId デバイスID
     * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
     * @param {number} [offset] 返却するリストのオフセット, 既定値0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiDevicesApplicationInstallationsGet(
      deviceId: string,
      limit?: number,
      offset?: number,
      options?: any,
    ): AxiosPromise<ApikeyApplicationList> {
      return localVarFp
        .apiOpenapiDevicesApplicationInstallationsGet(
          deviceId,
          limit,
          offset,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * アプリケーションコードで指定されたアプリケーションの利用を申請する。  前提条件:   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - アプリケーションコードで識別されるアプリケーションが存在し、以下のいずれかの状態であること     - アプリケーションが、ログインユーザーと関連付けられたディベロッパー（もしくは、ログインユーザが全体管理者である企業に関連付けられたディベロッパー）に属していること     - アプリケーションが公開中であり、アプリケーションが属するディベロッパーが審査済みであること   - アプリケーションのapplication_auth_typeがapikeyであること
     * @summary アプリケーション利用申請
     * @param {string} xXSRFToken
     * @param {string} deviceId デバイスID
     * @param {number} applicationId アプリケーションID
     * @param {ApplicationInstallationRequest} applicationInstallationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiDevicesApplicationInstallationsPost(
      xXSRFToken: string,
      deviceId: string,
      applicationId: number,
      applicationInstallationRequest: ApplicationInstallationRequest,
      options?: any,
    ): AxiosPromise<ApikeyApplicationInstallResponse> {
      return localVarFp
        .apiOpenapiDevicesApplicationInstallationsPost(
          xXSRFToken,
          deviceId,
          applicationId,
          applicationInstallationRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * アプリケーションコードで指定されたアプリケーションの情報を取得し、対象デバイスで利用可能かどうか判断する。利用可能でない場合は404のステータスで応答が得られる。  前提条件:   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - アプリケーションコードで識別されるアプリケーションが存在し、以下のいずれかの状態であること     - アプリケーションが、ログインユーザーに関連付けられたディベロッパー（もしくは、ログインユーザが全体管理者である企業に関連付けられたディベロッパー）が作成したものであること     - アプリケーションが公開中であり、アプリケーションが属するディベロッパーが審査済みであること   - アプリケーションのapplication_auth_typeがapikeyであること
     * @summary アプリケーション利用申請プレビュー
     * @param {string} xXSRFToken
     * @param {string} deviceId デバイスID
     * @param {number} applicationId アプリケーションID
     * @param {ApplicationInstallationRequest} applicationInstallationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiDevicesApplicationInstallationsPreviewPost(
      xXSRFToken: string,
      deviceId: string,
      applicationId: number,
      applicationInstallationRequest: ApplicationInstallationRequest,
      options?: any,
    ): AxiosPromise<ApikeyApplicationInstallResponse> {
      return localVarFp
        .apiOpenapiDevicesApplicationInstallationsPreviewPost(
          xXSRFToken,
          deviceId,
          applicationId,
          applicationInstallationRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * ログインユーザーがOAuth2.0認証により払い出した有効なアクセストークンを持つアプリケーションの一覧を取得する。 この一覧にv1 APIで払い出したアクセストークンに関する情報は含まれない。  前提条件   - 認証: useスコープ
     * @summary OAuth2アプリケーション一覧取得
     * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
     * @param {number} [offset] 返却するリストのオフセット, 既定値0
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiOauth2ApplicationsGet(
      limit?: number,
      offset?: number,
      options?: any,
    ): AxiosPromise<Oauth2ApplicationList> {
      return localVarFp
        .apiOpenapiOauth2ApplicationsGet(limit, offset, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ログインユーザーが認可した特定のアプリケーションに関連するOAuth2アクセストークンを無効化する。 無効化されたアクセストークンはSafie API呼び出しに使用できなくなり、また関連付けられたリフレッシュトークンはリフレッシュに使用できなくなる。  前提条件   - 認証: useスコープ   - 指定したアプリケーションがログインユーザーが過去に認可したものであること
     * @summary OAuth2アプリケーションの無効化
     * @param {number} applicationId アプリケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiOpenapiOauth2ApplicationsTokensDelete(
      applicationId: number,
      options?: any,
    ): AxiosPromise<Success> {
      return localVarFp
        .apiOpenapiOauth2ApplicationsTokensDelete(applicationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * メールアドレスの変更を要求します。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
     * @summary Safieユーザのメールアドレス変更
     * @param {string} xXSRFToken
     * @param {string} mailAddress 変更後のメールアドレス
     * @param {string} [provider] &#x60;safie&#x60; の文字列を固定で指定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserChangeMailAddressPost(
      xXSRFToken: string,
      mailAddress: string,
      provider?: string,
      options?: any,
    ): AxiosPromise<Success> {
      return localVarFp
        .apiUserChangeMailAddressPost(
          xXSRFToken,
          mailAddress,
          provider,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * パスワードの変更を行います。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
     * @summary Safieユーザのパスワード変更
     * @param {string} xXSRFToken
     * @param {string} currentPassword 変更前のパスワード
     * @param {string} newPassword 新パスワード
     * @param {string} confirmPassword 確認用パスワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserChangePasswordPost(
      xXSRFToken: string,
      currentPassword: string,
      newPassword: string,
      confirmPassword: string,
      options?: any,
    ): AxiosPromise<Success> {
      return localVarFp
        .apiUserChangePasswordPost(
          xXSRFToken,
          currentPassword,
          newPassword,
          confirmPassword,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Safieユーザの新規作成を要求する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。
     * @summary Safieユーザのサインアップ
     * @param {string} xXSRFToken
     * @param {string} mailAddress ユーザのメールアドレス
     * @param {string} password パスワード
     * @param {string} [provider] &#x60;safie&#x60; の文字列を固定で指定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserCreatePost(
      xXSRFToken: string,
      mailAddress: string,
      password: string,
      provider?: string,
      options?: any,
    ): AxiosPromise<UserCreateResponse> {
      return localVarFp
        .apiUserCreatePost(xXSRFToken, mailAddress, password, provider, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 二段階認証設定の認証子登録を確認し、二段階認証を有効化する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
     * @summary Safieユーザの二段階認証設定 認証子登録確認
     * @param {string} xXSRFToken
     * @param {string} [authenticatorType] 認証子の種類（&#x60;otp&#x60;のみ設定可能）
     * @param {string} [otp] ワンタイムパスワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserMfaConfirmPost(
      xXSRFToken: string,
      authenticatorType?: string,
      otp?: string,
      options?: any,
    ): AxiosPromise<Success> {
      return localVarFp
        .apiUserMfaConfirmPost(xXSRFToken, authenticatorType, otp, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ユーザに設定されている二段階認証設定を解除する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
     * @summary Safieユーザの二段階認証設定解除
     * @param {string} xXSRFToken
     * @param {string} [password] パスワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserMfaDeletePost(
      xXSRFToken: string,
      password?: string,
      options?: any,
    ): AxiosPromise<Success> {
      return localVarFp
        .apiUserMfaDeletePost(xXSRFToken, password, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 二段階認証設定の認証子登録を行い、otpauth URIを発行する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
     * @summary Safieユーザの二段階認証設定 認証子登録
     * @param {string} xXSRFToken
     * @param {string} [authenticatorType] 認証子の種類（&#x60;otp&#x60;のみ設定可能）
     * @param {string} [password] パスワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserMfaPost(
      xXSRFToken: string,
      authenticatorType?: string,
      password?: string,
      options?: any,
    ): AxiosPromise<MfaResponse> {
      return localVarFp
        .apiUserMfaPost(xXSRFToken, authenticatorType, password, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * パスワードのリセットを要求します。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。
     * @summary Safieユーザのパスワードリセット
     * @param {string} xXSRFToken
     * @param {string} mailAddress パスワード変更を要求するSafieアカウントのメールアドレス
     * @param {string} [provider] &#x60;safie&#x60; の文字列を固定で指定
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiUserRequestResetPasswordPost(
      xXSRFToken: string,
      mailAddress: string,
      provider?: string,
      options?: any,
    ): AxiosPromise<Success> {
      return localVarFp
        .apiUserRequestResetPasswordPost(
          xXSRFToken,
          mailAddress,
          provider,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SafieViewerAPIApi - interface
 * @export
 * @interface SafieViewerAPIApi
 */
export interface SafieViewerAPIApiInterface {
  /**
   * XSRF Cookieを取得する。リクエストが成功すると、_xsrfのCookieが設定される。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。
   * @summary XSRF Cookie 取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApiInterface
   */
  apiCertificateGet(options?: AxiosRequestConfig): AxiosPromise<Success>;

  /**
   * 特定のアプリケーションに対して利用申請しているデバイスの一覧を取得する。 APIを実行したユーザーがapp_integration権限を持っているデバイスのみが返される。  前提条件:   - 認証: useスコープ
   * @summary アプリケーション利用中デバイス一覧取得
   * @param {number} applicationId アプリケーションID
   * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
   * @param {number} [offset] 返却するリストのオフセット, 既定値0
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApiInterface
   */
  apiOpenapiApplicationInstallationsDevicesGet(
    applicationId: number,
    limit?: number,
    offset?: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<ApikeyApplicationInstallationDevicesResponse>;

  /**
   * APIを実行したユーザーがapp_integration権限を持っているデバイス全てについて、 アプリケーション利用申請を行っているアプリケーションの一覧を取得する。  前提条件:   - 認証: useスコープ
   * @summary 利用中アプリケーション一覧取得
   * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
   * @param {number} [offset] 返却するリストのオフセット, 既定値0
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApiInterface
   */
  apiOpenapiApplicationInstallationsGet(
    limit?: number,
    offset?: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<ApikeyApplicationListResponse>;

  /**
   * 指定したデバイスおよびアプリケーションの利用状況を取得します。  前提条件   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - 指定されたデバイスで、指定されたアプリケーションが利用されていること
   * @summary アプリケーション利用取得
   * @param {string} deviceId デバイスID
   * @param {number} applicationId アプリケーションID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApiInterface
   */
  apiOpenapiDevicesApplicationInstallationsApplicationIdGet(
    deviceId: string,
    applicationId: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<ApikeyApplicationResponse>;

  /**
   * 指定されたアプリケーション利用の設定を変更する。アプリケーション利用が一時停止に変更されると、 そのアプリケーションが持つAPIキーによるデバイス情報へのアクセスが権限エラーとなる。  前提条件:   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - アプリケーションIDで指定されるアプリケーションを利用中であるか利用申請中であること (審査状況は問わない)
   * @summary アプリケーション利用変更
   * @param {string} deviceId デバイスID
   * @param {number} applicationId アプリケーションID
   * @param {ApplicationInstallationPatchRequest} applicationInstallationPatchRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApiInterface
   */
  apiOpenapiDevicesApplicationInstallationsApplicationIdPatch(
    deviceId: string,
    applicationId: number,
    applicationInstallationPatchRequest: ApplicationInstallationPatchRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<ApikeyApplicationResponse>;

  /**
   * 指定したデバイスが利用中のAPIキー認証アプリケーションの一覧を取得します。  前提条件   - 認証: useスコープ
   * @summary アプリケーション利用一覧取得
   * @param {string} deviceId デバイスID
   * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
   * @param {number} [offset] 返却するリストのオフセット, 既定値0
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApiInterface
   */
  apiOpenapiDevicesApplicationInstallationsGet(
    deviceId: string,
    limit?: number,
    offset?: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<ApikeyApplicationList>;

  /**
   * アプリケーションコードで指定されたアプリケーションの利用を申請する。  前提条件:   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - アプリケーションコードで識別されるアプリケーションが存在し、以下のいずれかの状態であること     - アプリケーションが、ログインユーザーと関連付けられたディベロッパー（もしくは、ログインユーザが全体管理者である企業に関連付けられたディベロッパー）に属していること     - アプリケーションが公開中であり、アプリケーションが属するディベロッパーが審査済みであること   - アプリケーションのapplication_auth_typeがapikeyであること
   * @summary アプリケーション利用申請
   * @param {string} xXSRFToken
   * @param {string} deviceId デバイスID
   * @param {number} applicationId アプリケーションID
   * @param {ApplicationInstallationRequest} applicationInstallationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApiInterface
   */
  apiOpenapiDevicesApplicationInstallationsPost(
    xXSRFToken: string,
    deviceId: string,
    applicationId: number,
    applicationInstallationRequest: ApplicationInstallationRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<ApikeyApplicationInstallResponse>;

  /**
   * アプリケーションコードで指定されたアプリケーションの情報を取得し、対象デバイスで利用可能かどうか判断する。利用可能でない場合は404のステータスで応答が得られる。  前提条件:   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - アプリケーションコードで識別されるアプリケーションが存在し、以下のいずれかの状態であること     - アプリケーションが、ログインユーザーに関連付けられたディベロッパー（もしくは、ログインユーザが全体管理者である企業に関連付けられたディベロッパー）が作成したものであること     - アプリケーションが公開中であり、アプリケーションが属するディベロッパーが審査済みであること   - アプリケーションのapplication_auth_typeがapikeyであること
   * @summary アプリケーション利用申請プレビュー
   * @param {string} xXSRFToken
   * @param {string} deviceId デバイスID
   * @param {number} applicationId アプリケーションID
   * @param {ApplicationInstallationRequest} applicationInstallationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApiInterface
   */
  apiOpenapiDevicesApplicationInstallationsPreviewPost(
    xXSRFToken: string,
    deviceId: string,
    applicationId: number,
    applicationInstallationRequest: ApplicationInstallationRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<ApikeyApplicationInstallResponse>;

  /**
   * ログインユーザーがOAuth2.0認証により払い出した有効なアクセストークンを持つアプリケーションの一覧を取得する。 この一覧にv1 APIで払い出したアクセストークンに関する情報は含まれない。  前提条件   - 認証: useスコープ
   * @summary OAuth2アプリケーション一覧取得
   * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
   * @param {number} [offset] 返却するリストのオフセット, 既定値0
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApiInterface
   */
  apiOpenapiOauth2ApplicationsGet(
    limit?: number,
    offset?: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Oauth2ApplicationList>;

  /**
   * ログインユーザーが認可した特定のアプリケーションに関連するOAuth2アクセストークンを無効化する。 無効化されたアクセストークンはSafie API呼び出しに使用できなくなり、また関連付けられたリフレッシュトークンはリフレッシュに使用できなくなる。  前提条件   - 認証: useスコープ   - 指定したアプリケーションがログインユーザーが過去に認可したものであること
   * @summary OAuth2アプリケーションの無効化
   * @param {number} applicationId アプリケーションID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApiInterface
   */
  apiOpenapiOauth2ApplicationsTokensDelete(
    applicationId: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Success>;

  /**
   * メールアドレスの変更を要求します。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
   * @summary Safieユーザのメールアドレス変更
   * @param {string} xXSRFToken
   * @param {string} mailAddress 変更後のメールアドレス
   * @param {string} [provider] &#x60;safie&#x60; の文字列を固定で指定
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApiInterface
   */
  apiUserChangeMailAddressPost(
    xXSRFToken: string,
    mailAddress: string,
    provider?: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Success>;

  /**
   * パスワードの変更を行います。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
   * @summary Safieユーザのパスワード変更
   * @param {string} xXSRFToken
   * @param {string} currentPassword 変更前のパスワード
   * @param {string} newPassword 新パスワード
   * @param {string} confirmPassword 確認用パスワード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApiInterface
   */
  apiUserChangePasswordPost(
    xXSRFToken: string,
    currentPassword: string,
    newPassword: string,
    confirmPassword: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Success>;

  /**
   * Safieユーザの新規作成を要求する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。
   * @summary Safieユーザのサインアップ
   * @param {string} xXSRFToken
   * @param {string} mailAddress ユーザのメールアドレス
   * @param {string} password パスワード
   * @param {string} [provider] &#x60;safie&#x60; の文字列を固定で指定
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApiInterface
   */
  apiUserCreatePost(
    xXSRFToken: string,
    mailAddress: string,
    password: string,
    provider?: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<UserCreateResponse>;

  /**
   * 二段階認証設定の認証子登録を確認し、二段階認証を有効化する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
   * @summary Safieユーザの二段階認証設定 認証子登録確認
   * @param {string} xXSRFToken
   * @param {string} [authenticatorType] 認証子の種類（&#x60;otp&#x60;のみ設定可能）
   * @param {string} [otp] ワンタイムパスワード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApiInterface
   */
  apiUserMfaConfirmPost(
    xXSRFToken: string,
    authenticatorType?: string,
    otp?: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Success>;

  /**
   * ユーザに設定されている二段階認証設定を解除する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
   * @summary Safieユーザの二段階認証設定解除
   * @param {string} xXSRFToken
   * @param {string} [password] パスワード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApiInterface
   */
  apiUserMfaDeletePost(
    xXSRFToken: string,
    password?: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Success>;

  /**
   * 二段階認証設定の認証子登録を行い、otpauth URIを発行する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
   * @summary Safieユーザの二段階認証設定 認証子登録
   * @param {string} xXSRFToken
   * @param {string} [authenticatorType] 認証子の種類（&#x60;otp&#x60;のみ設定可能）
   * @param {string} [password] パスワード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApiInterface
   */
  apiUserMfaPost(
    xXSRFToken: string,
    authenticatorType?: string,
    password?: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<MfaResponse>;

  /**
   * パスワードのリセットを要求します。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。
   * @summary Safieユーザのパスワードリセット
   * @param {string} xXSRFToken
   * @param {string} mailAddress パスワード変更を要求するSafieアカウントのメールアドレス
   * @param {string} [provider] &#x60;safie&#x60; の文字列を固定で指定
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApiInterface
   */
  apiUserRequestResetPasswordPost(
    xXSRFToken: string,
    mailAddress: string,
    provider?: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Success>;
}

/**
 * SafieViewerAPIApi - object-oriented interface
 * @export
 * @class SafieViewerAPIApi
 * @extends {BaseAPI}
 */
export class SafieViewerAPIApi
  extends BaseAPI
  implements SafieViewerAPIApiInterface
{
  /**
   * XSRF Cookieを取得する。リクエストが成功すると、_xsrfのCookieが設定される。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。
   * @summary XSRF Cookie 取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApi
   */
  public apiCertificateGet(options?: AxiosRequestConfig) {
    return SafieViewerAPIApiFp(this.configuration)
      .apiCertificateGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 特定のアプリケーションに対して利用申請しているデバイスの一覧を取得する。 APIを実行したユーザーがapp_integration権限を持っているデバイスのみが返される。  前提条件:   - 認証: useスコープ
   * @summary アプリケーション利用中デバイス一覧取得
   * @param {number} applicationId アプリケーションID
   * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
   * @param {number} [offset] 返却するリストのオフセット, 既定値0
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApi
   */
  public apiOpenapiApplicationInstallationsDevicesGet(
    applicationId: number,
    limit?: number,
    offset?: number,
    options?: AxiosRequestConfig,
  ) {
    return SafieViewerAPIApiFp(this.configuration)
      .apiOpenapiApplicationInstallationsDevicesGet(
        applicationId,
        limit,
        offset,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * APIを実行したユーザーがapp_integration権限を持っているデバイス全てについて、 アプリケーション利用申請を行っているアプリケーションの一覧を取得する。  前提条件:   - 認証: useスコープ
   * @summary 利用中アプリケーション一覧取得
   * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
   * @param {number} [offset] 返却するリストのオフセット, 既定値0
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApi
   */
  public apiOpenapiApplicationInstallationsGet(
    limit?: number,
    offset?: number,
    options?: AxiosRequestConfig,
  ) {
    return SafieViewerAPIApiFp(this.configuration)
      .apiOpenapiApplicationInstallationsGet(limit, offset, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したデバイスおよびアプリケーションの利用状況を取得します。  前提条件   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - 指定されたデバイスで、指定されたアプリケーションが利用されていること
   * @summary アプリケーション利用取得
   * @param {string} deviceId デバイスID
   * @param {number} applicationId アプリケーションID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApi
   */
  public apiOpenapiDevicesApplicationInstallationsApplicationIdGet(
    deviceId: string,
    applicationId: number,
    options?: AxiosRequestConfig,
  ) {
    return SafieViewerAPIApiFp(this.configuration)
      .apiOpenapiDevicesApplicationInstallationsApplicationIdGet(
        deviceId,
        applicationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定されたアプリケーション利用の設定を変更する。アプリケーション利用が一時停止に変更されると、 そのアプリケーションが持つAPIキーによるデバイス情報へのアクセスが権限エラーとなる。  前提条件:   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - アプリケーションIDで指定されるアプリケーションを利用中であるか利用申請中であること (審査状況は問わない)
   * @summary アプリケーション利用変更
   * @param {string} deviceId デバイスID
   * @param {number} applicationId アプリケーションID
   * @param {ApplicationInstallationPatchRequest} applicationInstallationPatchRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApi
   */
  public apiOpenapiDevicesApplicationInstallationsApplicationIdPatch(
    deviceId: string,
    applicationId: number,
    applicationInstallationPatchRequest: ApplicationInstallationPatchRequest,
    options?: AxiosRequestConfig,
  ) {
    return SafieViewerAPIApiFp(this.configuration)
      .apiOpenapiDevicesApplicationInstallationsApplicationIdPatch(
        deviceId,
        applicationId,
        applicationInstallationPatchRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したデバイスが利用中のAPIキー認証アプリケーションの一覧を取得します。  前提条件   - 認証: useスコープ
   * @summary アプリケーション利用一覧取得
   * @param {string} deviceId デバイスID
   * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
   * @param {number} [offset] 返却するリストのオフセット, 既定値0
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApi
   */
  public apiOpenapiDevicesApplicationInstallationsGet(
    deviceId: string,
    limit?: number,
    offset?: number,
    options?: AxiosRequestConfig,
  ) {
    return SafieViewerAPIApiFp(this.configuration)
      .apiOpenapiDevicesApplicationInstallationsGet(
        deviceId,
        limit,
        offset,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * アプリケーションコードで指定されたアプリケーションの利用を申請する。  前提条件:   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - アプリケーションコードで識別されるアプリケーションが存在し、以下のいずれかの状態であること     - アプリケーションが、ログインユーザーと関連付けられたディベロッパー（もしくは、ログインユーザが全体管理者である企業に関連付けられたディベロッパー）に属していること     - アプリケーションが公開中であり、アプリケーションが属するディベロッパーが審査済みであること   - アプリケーションのapplication_auth_typeがapikeyであること
   * @summary アプリケーション利用申請
   * @param {string} xXSRFToken
   * @param {string} deviceId デバイスID
   * @param {number} applicationId アプリケーションID
   * @param {ApplicationInstallationRequest} applicationInstallationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApi
   */
  public apiOpenapiDevicesApplicationInstallationsPost(
    xXSRFToken: string,
    deviceId: string,
    applicationId: number,
    applicationInstallationRequest: ApplicationInstallationRequest,
    options?: AxiosRequestConfig,
  ) {
    return SafieViewerAPIApiFp(this.configuration)
      .apiOpenapiDevicesApplicationInstallationsPost(
        xXSRFToken,
        deviceId,
        applicationId,
        applicationInstallationRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * アプリケーションコードで指定されたアプリケーションの情報を取得し、対象デバイスで利用可能かどうか判断する。利用可能でない場合は404のステータスで応答が得られる。  前提条件:   - 認証: useスコープ、指定したデバイスに対して app_integration 権限を持つこと   - アプリケーションコードで識別されるアプリケーションが存在し、以下のいずれかの状態であること     - アプリケーションが、ログインユーザーに関連付けられたディベロッパー（もしくは、ログインユーザが全体管理者である企業に関連付けられたディベロッパー）が作成したものであること     - アプリケーションが公開中であり、アプリケーションが属するディベロッパーが審査済みであること   - アプリケーションのapplication_auth_typeがapikeyであること
   * @summary アプリケーション利用申請プレビュー
   * @param {string} xXSRFToken
   * @param {string} deviceId デバイスID
   * @param {number} applicationId アプリケーションID
   * @param {ApplicationInstallationRequest} applicationInstallationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApi
   */
  public apiOpenapiDevicesApplicationInstallationsPreviewPost(
    xXSRFToken: string,
    deviceId: string,
    applicationId: number,
    applicationInstallationRequest: ApplicationInstallationRequest,
    options?: AxiosRequestConfig,
  ) {
    return SafieViewerAPIApiFp(this.configuration)
      .apiOpenapiDevicesApplicationInstallationsPreviewPost(
        xXSRFToken,
        deviceId,
        applicationId,
        applicationInstallationRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * ログインユーザーがOAuth2.0認証により払い出した有効なアクセストークンを持つアプリケーションの一覧を取得する。 この一覧にv1 APIで払い出したアクセストークンに関する情報は含まれない。  前提条件   - 認証: useスコープ
   * @summary OAuth2アプリケーション一覧取得
   * @param {number} [limit] 返却するリストの最大件数, 既定値20, 最大値100
   * @param {number} [offset] 返却するリストのオフセット, 既定値0
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApi
   */
  public apiOpenapiOauth2ApplicationsGet(
    limit?: number,
    offset?: number,
    options?: AxiosRequestConfig,
  ) {
    return SafieViewerAPIApiFp(this.configuration)
      .apiOpenapiOauth2ApplicationsGet(limit, offset, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * ログインユーザーが認可した特定のアプリケーションに関連するOAuth2アクセストークンを無効化する。 無効化されたアクセストークンはSafie API呼び出しに使用できなくなり、また関連付けられたリフレッシュトークンはリフレッシュに使用できなくなる。  前提条件   - 認証: useスコープ   - 指定したアプリケーションがログインユーザーが過去に認可したものであること
   * @summary OAuth2アプリケーションの無効化
   * @param {number} applicationId アプリケーションID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApi
   */
  public apiOpenapiOauth2ApplicationsTokensDelete(
    applicationId: number,
    options?: AxiosRequestConfig,
  ) {
    return SafieViewerAPIApiFp(this.configuration)
      .apiOpenapiOauth2ApplicationsTokensDelete(applicationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * メールアドレスの変更を要求します。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
   * @summary Safieユーザのメールアドレス変更
   * @param {string} xXSRFToken
   * @param {string} mailAddress 変更後のメールアドレス
   * @param {string} [provider] &#x60;safie&#x60; の文字列を固定で指定
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApi
   */
  public apiUserChangeMailAddressPost(
    xXSRFToken: string,
    mailAddress: string,
    provider?: string,
    options?: AxiosRequestConfig,
  ) {
    return SafieViewerAPIApiFp(this.configuration)
      .apiUserChangeMailAddressPost(xXSRFToken, mailAddress, provider, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * パスワードの変更を行います。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
   * @summary Safieユーザのパスワード変更
   * @param {string} xXSRFToken
   * @param {string} currentPassword 変更前のパスワード
   * @param {string} newPassword 新パスワード
   * @param {string} confirmPassword 確認用パスワード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApi
   */
  public apiUserChangePasswordPost(
    xXSRFToken: string,
    currentPassword: string,
    newPassword: string,
    confirmPassword: string,
    options?: AxiosRequestConfig,
  ) {
    return SafieViewerAPIApiFp(this.configuration)
      .apiUserChangePasswordPost(
        xXSRFToken,
        currentPassword,
        newPassword,
        confirmPassword,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Safieユーザの新規作成を要求する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。
   * @summary Safieユーザのサインアップ
   * @param {string} xXSRFToken
   * @param {string} mailAddress ユーザのメールアドレス
   * @param {string} password パスワード
   * @param {string} [provider] &#x60;safie&#x60; の文字列を固定で指定
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApi
   */
  public apiUserCreatePost(
    xXSRFToken: string,
    mailAddress: string,
    password: string,
    provider?: string,
    options?: AxiosRequestConfig,
  ) {
    return SafieViewerAPIApiFp(this.configuration)
      .apiUserCreatePost(xXSRFToken, mailAddress, password, provider, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 二段階認証設定の認証子登録を確認し、二段階認証を有効化する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
   * @summary Safieユーザの二段階認証設定 認証子登録確認
   * @param {string} xXSRFToken
   * @param {string} [authenticatorType] 認証子の種類（&#x60;otp&#x60;のみ設定可能）
   * @param {string} [otp] ワンタイムパスワード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApi
   */
  public apiUserMfaConfirmPost(
    xXSRFToken: string,
    authenticatorType?: string,
    otp?: string,
    options?: AxiosRequestConfig,
  ) {
    return SafieViewerAPIApiFp(this.configuration)
      .apiUserMfaConfirmPost(xXSRFToken, authenticatorType, otp, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * ユーザに設定されている二段階認証設定を解除する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
   * @summary Safieユーザの二段階認証設定解除
   * @param {string} xXSRFToken
   * @param {string} [password] パスワード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApi
   */
  public apiUserMfaDeletePost(
    xXSRFToken: string,
    password?: string,
    options?: AxiosRequestConfig,
  ) {
    return SafieViewerAPIApiFp(this.configuration)
      .apiUserMfaDeletePost(xXSRFToken, password, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 二段階認証設定の認証子登録を行い、otpauth URIを発行する。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。  前提条件   - 認証: `use`スコープ
   * @summary Safieユーザの二段階認証設定 認証子登録
   * @param {string} xXSRFToken
   * @param {string} [authenticatorType] 認証子の種類（&#x60;otp&#x60;のみ設定可能）
   * @param {string} [password] パスワード
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApi
   */
  public apiUserMfaPost(
    xXSRFToken: string,
    authenticatorType?: string,
    password?: string,
    options?: AxiosRequestConfig,
  ) {
    return SafieViewerAPIApiFp(this.configuration)
      .apiUserMfaPost(xXSRFToken, authenticatorType, password, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * パスワードのリセットを要求します。 Safie Viewerで利用されているAPIであり、Safie Developersでも同じAPIを利用している。
   * @summary Safieユーザのパスワードリセット
   * @param {string} xXSRFToken
   * @param {string} mailAddress パスワード変更を要求するSafieアカウントのメールアドレス
   * @param {string} [provider] &#x60;safie&#x60; の文字列を固定で指定
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SafieViewerAPIApi
   */
  public apiUserRequestResetPasswordPost(
    xXSRFToken: string,
    mailAddress: string,
    provider?: string,
    options?: AxiosRequestConfig,
  ) {
    return SafieViewerAPIApiFp(this.configuration)
      .apiUserRequestResetPasswordPost(
        xXSRFToken,
        mailAddress,
        provider,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
