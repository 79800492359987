const staticPage = {
  CASE_TITLE: "活用事例",
  FAQ_TITLE: "よくあるご質問（FAQ）",
  RELEASE_TITLE: "リリースノート",
  API_BEHAVIOR: "API動作",
  LIMIT_VALUE: "制限値",
  PRODUCTION_LIMIT: "プロダクトプランの各種制限値",
  TRIAL_LIMIT: "トライアルプランの各種制限値",
};

export default staticPage;
