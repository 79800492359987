import { createStore, Store, useStore as baseUseStore } from "vuex";
import {
  Application,
  DeveloperRequest,
  UserinfoResponse,
} from "../api/backend-api";
import createPersistedState from "vuex-persistedstate";
import services from "@/api/services";
import { showErrorCondition, showErrorMessage } from "@/utils";
import { TabNameEnum } from "@/views/manager/application/components";

export enum AccountType {
  user = "user",
  corporation = "corporation",
}

interface IErrorModal {
  visible: boolean;
  message?: string;
  callback?: () => void;
}

interface IdemoVideoData {
  [key: string]:
    | string
    | boolean
    | { name: string; deviceId: string }[]
    | undefined;
  tokenStatus: string;
  isShowContent: boolean;
  device: string;
  deviceOptions: IDeviceOptions[];
}

interface IDeviceOptions {
  name: string;
  deviceId: string;
}

interface ISetDemoVideoDataParams {
  key: string;
  value: string | boolean | { name: string; deviceId: string }[] | undefined;
}

export interface State {
  userinfo?: UserinfoResponse;
  scrollTopStatus: boolean;
  currentAccessApplication?: Application;
  developerId?: number;
  currentAccountType: AccountType;
  isDedicatedOptionEnabledDeveloper: boolean;
  userInfoForm?: DeveloperRequest & {
    username?: string;
    password?: string;
    isAgree?: boolean;
  };
  userSettingPath?: string;
  isLogin: boolean;
  bodyWidth: number;
  errorModal: IErrorModal;
  loading: boolean;
  loadingTimer?: number;
  // APIキー認証、OAuth2.0認証 from which page
  createFrom?: string;
  activeTab?: TabNameEnum;
  initPassword?: string;
  loginUsername?: string;
  demoVideoData: IdemoVideoData;
}

const setLoadingProperty = () => {
  document.documentElement.style.setProperty(
    "--loading-background",
    "rgba(0, 0, 0, 0.2)",
  );
  document.documentElement.style.setProperty(
    "--loading-spinner-display",
    "block",
  );
};

const removeLoadingProperty = () => {
  document.documentElement.style.removeProperty("--loading-background");
  document.documentElement.style.removeProperty("--loading-spinner-display");
};

const store = createStore<State>({
  state: {
    scrollTopStatus: false,
    currentAccountType: AccountType.user,
    isDedicatedOptionEnabledDeveloper: false,
    isLogin: false,
    bodyWidth: document.body.clientWidth,
    errorModal: {
      visible: false,
    },
    loading: false,
    demoVideoData: {
      tokenStatus: "",
      isShowContent: false,
      device: "",
      deviceOptions: [],
    },
  },
  plugins: [createPersistedState()],
  getters: {},
  mutations: {
    setUserinfo(state, payload) {
      state.userinfo = payload;
    },
    scrollTop(state) {
      state.scrollTopStatus = !state.scrollTopStatus;
    },
    setUserInfoForm(state, payload) {
      state.userInfoForm = payload;
    },
    clearUserInfoForm(state) {
      delete state.userInfoForm;
    },
    setDeveloperId(state, payload: number) {
      state.developerId = payload;
    },
    setCurrentAccountType(state, payload: AccountType) {
      state.currentAccountType = payload;
    },
    setIsDedicatedOptionEnabledDeveloper(state, payload: boolean) {
      state.isDedicatedOptionEnabledDeveloper = payload;
    },
    setAccessApplication(state, payload: Application) {
      state.currentAccessApplication = payload;
    },
    setUserSettingUnload(state, payload: string) {
      state.userSettingPath = payload;
    },
    setIsLogin(state, payload: boolean) {
      state.isLogin = payload;
    },
    setBodyWidth(state, payload: number) {
      state.bodyWidth = payload;
    },
    openErrorModal(
      state,
      payload: { message?: string; callback?: () => void },
    ) {
      state.errorModal.message = payload.message;
      state.errorModal.callback = payload.callback;
      state.errorModal.visible = true;
    },
    closeErrorModal(state) {
      delete state.errorModal.message;
      state.errorModal.visible = false;
      if (state.errorModal.callback) {
        state.errorModal.callback();
        delete state.errorModal.callback;
      }
    },
    openLoading(state, payload) {
      if (payload && payload === "dark") {
        setLoadingProperty();
      }
      if (state.loadingTimer) {
        clearTimeout(state.loadingTimer);
      }
      state.loading = true;
    },
    closeLoading(state) {
      if (state.loadingTimer) {
        clearTimeout(state.loadingTimer);
      }
      state.loadingTimer = window.setTimeout(() => {
        removeLoadingProperty();
        state.loading = false;
      }, 200);
    },
    setCreateFrom(state, payload) {
      state.createFrom = payload;
    },
    setActiveTab(state, payload) {
      state.activeTab = payload;
    },
    setInitPassword(state, payload) {
      state.initPassword = payload;
    },
    setLoginUsername(state, payload) {
      state.loginUsername = payload;
    },
    setDemoVideoData(state, params: ISetDemoVideoDataParams) {
      const { key, value } = params;
      state.demoVideoData[key] = value;
    },
  },
  actions: {
    afterCooperationChange(context) {
      return services.dev.devUserinfoGet().then(
        (res) => {
          const userinfo = res.data;
          context.commit("setUserinfo", res.data);
          if (
            store.state.currentAccountType === AccountType.user &&
            userinfo.user_developer
          ) {
            context.commit("setCurrentAccountType", AccountType.user);
            context.commit(
              "setDeveloperId",
              userinfo.user_developer.developer_id,
            );
            return true;
          } else if (
            store.state.currentAccountType === AccountType.corporation &&
            userinfo.corporation_developer
          ) {
            context.commit("setCurrentAccountType", AccountType.corporation);
            context.commit(
              "setDeveloperId",
              userinfo.corporation_developer.developer_id,
            );
            return true;
          } else {
            if (userinfo.user_developer) {
              context.commit("setCurrentAccountType", AccountType.user);
              context.commit(
                "setDeveloperId",
                userinfo.user_developer.developer_id,
              );
              return true;
            } else if (userinfo.corporation_developer) {
              context.commit("setCurrentAccountType", AccountType.corporation);
              context.commit(
                "setDeveloperId",
                userinfo.corporation_developer.developer_id,
              );
              return true;
            }
            return false;
          }
        },
        (reason) => {
          if (showErrorCondition(reason)) {
            showErrorMessage(reason.response.data.message);
          }
          return false;
        },
      );
    },
  },
  modules: {},
});

export function useStore(): Store<State> {
  return baseUseStore();
}

export default store;
