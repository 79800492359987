import { nextTick } from "vue";

/**
 * Select コンポーネントは、同じオプションを2回クリックすると、選択状態を解除します
 * @param isVisible 選択肢を広げるかどうか
 */
export const doubleSelectCancelSelection = (isVisible: boolean) => {
  if (!isVisible) {
    nextTick(() => {
      // 同じオプションを2回クリックすると、選択した状態を解除します
      window.getSelection()?.removeAllRanges();
    });
  }
};

/**
 * テキストのコピー
 * @param text コピーされたテキスト
 * @param callback コールバックメソッド
 */
export const copyToClipboard = (text?: string, callback?: () => void) => {
  if (text) {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text);
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.style.zIndex = "-10000";
      document.body.appendChild(textArea);
      textArea.readOnly = true;
      textArea.focus();
      textArea.select();
      new Promise((resolve, reject) => {
        document.execCommand("copy") ? resolve(true) : reject();
        textArea.remove();
      });
    }
    if (callback) {
      setTimeout(() => {
        callback();
      }, 500);
    }
  }
};

export interface IForm {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [prop: string]: any;
}

export const validLength = (
  form: IForm,
  prop: string,
  max: number,
  type?: string,
) => {
  if (type === "numberCode") {
    form[prop] = (form[prop] as string).replaceAll(/[^0-9]+/g, "");
  }
  if (form[prop] && form[prop].length > max)
    form[prop] = form[prop].slice(0, max);
};

// ¢ , £ , § , ¨ , « , ¬ , ¯ , ° , ± , ´ , µ , ¶ , · , ¸ , » , × , ÷
const specialChars = [
  162, 163, 167, 168, 171, 172, 175, 176, 177, 180, 181, 182, 183, 184, 187,
  215, 247,
];
export const getUnicodeLength = (str: string) => {
  let realLength = 0;
  const len = str.length;
  let charCode = -1;

  for (let i = 0; i < len; i++) {
    charCode = str.charCodeAt(i);

    if (charCode >= 0 && charCode <= 254) {
      if (specialChars.find((a) => a === charCode)) {
        realLength += 2;
      } else {
        realLength += 1;
      }
    } else if (charCode >= 65377 && charCode <= 65439) {
      if (charCode == 65381) {
        // '･'
        realLength += 2;
      } else {
        realLength += 1;
      }
    } else {
      realLength += 2;
    }
  }
  return realLength;
};

export const emptyValidate = (message: string) => {
  const validate = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    callback: (error?: string | Error | undefined) => void,
  ) => {
    if (!value || !value.trim()) {
      callback(new Error(message));
    } else {
      callback();
    }
  };
  return validate;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const showErrorCondition = (reason: any) => {
  if (reason.response.status === 401) {
    return false;
  }
  if (
    reason.response.status === 400 &&
    reason.response.data?.error === "invalid_token"
  ) {
    return false;
  }
  return true;
};
