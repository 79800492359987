const applicationList = {
  TITLE: "アプリケーション一覧",
  TIP_AUDIT_STATUS_LABEL: "アクセスできるデバイス",
  TIP_AUDIT_STATUS_VERIFIED: "すべてのデバイス",
  TIP_AUDIT_STATUS_NOT_VERIFIED: "自身がオーナーアカウントのデバイスのみ",
  TIP_TEXT1: "アプリケーションの認証方式の違い（APIキー/OAuth2.0）については、",
  TIP_LINK: "こちらのページ",
  TIP_TEXT2: "をご参照ください",
  CARD_API_KEY_TITLE: "APIキー認証アプリケーション一覧",
  CARD_BTN: "アプリケーション新規作成",
  API_TABLE_COLUMN1: "アプリケーション名",
  API_TABLE_COLUMN2: "アプリケーションコード",
  API_TABLE_COLUMN3: "プラン",
  TABLE_COLUMN_TITLE_STATUS: "公開状況",
  TABLE_COLUMN_TITLE_OPTION_TYPE: "オプション種別",
  TABLE_COLUMN_TITLE_OPERATION: "",
  API_TABLE_OPERATION_EDIT: "設定",
  API_TABLE_OPERATION_MANAGER: "連携デバイス管理",
  API_PLAN_LABEL: "プラン:",
  API_CODE_LABEL: "コード:",
  API_OPTION_TYPE_LABEL: "オプション種別:",
  CARD_OAUTH_TITLE: "OAuth2.0認証アプリケーション一覧",
  PLAN_TRIAL: "トライアル",
  PLAN_PRODUCTION: "プロダクト",
  SIZE_LIMIT: "アプリケーション作成数が上限に達しています。",
  JUMP_FIRST_PAGE_BUTTON_LABEL: "最初",
  JUMP_LAST_PAGE_BUTTON_LABEL: "最後",
};

export default applicationList;
