/* tslint:disable */
/* eslint-disable */
/**
 * Safie API specification for Safie Developers
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.14.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { Apis } from "./apis";

/**
 *
 * @export
 * @interface ApikeyApplicationForViewer
 */
export interface ApikeyApplicationForViewer {
  /**
   * アプリケーションを作成したディベロッパーのID
   * @type {number}
   * @memberof ApikeyApplicationForViewer
   */
  developer_id?: number;
  /**
   * アプリケーションを作成したディベロッパーの表示名。 ただし、アプリケーション対して個別に設定されたディベロッパー表示名が存在する場合には、その表示名が返る。
   * @type {string}
   * @memberof ApikeyApplicationForViewer
   */
  developer_description?: string;
  /**
   * アプリケーションID
   * @type {number}
   * @memberof ApikeyApplicationForViewer
   */
  application_id?: number;
  /**
   * アプリケーション名
   * @type {string}
   * @memberof ApikeyApplicationForViewer
   */
  application_name?: string;
  /**
   * アプリケーションに適用するプラン。`application_auth_type=oauth2`の場合は、`production`を指定しなければならない。 `trial`: 試験用アプリケーション、権限のある任意のデバイスに対して少量のAPIコールが可能。 `production`: 本番アプリケーション、権限のあるSafie APIオプションプランが付与されたデバイスに対してプランで定められた量のAPIコールが可能。
   * @type {string}
   * @memberof ApikeyApplicationForViewer
   */
  application_plan?: ApikeyApplicationForViewerApplicationPlanEnum;
  /**
   * アプリケーションが利用する認証種別 `apikey`: APIキーを利用するアプリケーション `oauth2`: OAuth2認可プロセスを通して取得したアクセストークンを利用するアプリケーション
   * @type {string}
   * @memberof ApikeyApplicationForViewer
   */
  application_auth_type?: ApikeyApplicationForViewerApplicationAuthTypeEnum;
  /**
   * アプリケーション公開状況。`application_auth_type=oauth2`の場合は、`true`を指定しなければならない。 `true`: 公開中 (自developerに属さないユーザーがアプリケーションコードを入力することでアプリケーション利用申請することができる。ただし、`application_auth_type=oauth2`のアプリケーションに対する利用申請はできない。) `false`: 非公開
   * @type {boolean}
   * @memberof ApikeyApplicationForViewer
   */
  published?: boolean;
  /**
   * このアプリケーションを利用するために許諾する必要のある権限のリスト。 `application_auth_type=apikey`の場合のみ指定可能。このアプリケーションで発行されたAPIキーでは、 ここで記述された権限の範囲内でのAPIアクセスのみが許可される。値は、`view`, `timeline`, `share`, `unshare`, `change_permission`, `talkback`, `view_clips`, `download`, `create_clips`, `delete_clips`, `device_setting`, `event_detection`, `delete_media`, `view_contract`, `location` の中から部分集合となるように指定する。
   * @type {Set<string>}
   * @memberof ApikeyApplicationForViewer
   */
  permissions?: Set<string> | null;
  /**
   *
   * @type {Apis}
   * @memberof ApikeyApplicationForViewer
   */
  apis?: Apis;
  /**
   * 承認状況   - `created`: 未承認   - `approved`: 承認済   - `denied`: 否認
   * @type {string}
   * @memberof ApikeyApplicationForViewer
   */
  status?: ApikeyApplicationForViewerStatusEnum;
  /**
   * 一時停止状況 - `true`: 利用中 - `false`: 一時停止中
   * @type {boolean}
   * @memberof ApikeyApplicationForViewer
   */
  enabled?: boolean;
}

export const ApikeyApplicationForViewerApplicationPlanEnum = {
  Trial: "trial",
  Production: "production",
} as const;

export type ApikeyApplicationForViewerApplicationPlanEnum =
  (typeof ApikeyApplicationForViewerApplicationPlanEnum)[keyof typeof ApikeyApplicationForViewerApplicationPlanEnum];
export const ApikeyApplicationForViewerApplicationAuthTypeEnum = {
  Apikey: "apikey",
  Oauth2: "oauth2",
} as const;

export type ApikeyApplicationForViewerApplicationAuthTypeEnum =
  (typeof ApikeyApplicationForViewerApplicationAuthTypeEnum)[keyof typeof ApikeyApplicationForViewerApplicationAuthTypeEnum];
export const ApikeyApplicationForViewerStatusEnum = {
  Created: "created",
  Approved: "approved",
  Denied: "denied",
} as const;

export type ApikeyApplicationForViewerStatusEnum =
  (typeof ApikeyApplicationForViewerStatusEnum)[keyof typeof ApikeyApplicationForViewerStatusEnum];
