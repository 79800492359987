const access = {
  TITLE: "APIキー認証アプリケーション",
  SUB_TITLE: "連携デバイス管理",
  APPLICATION_FORM_TITLE: "アプリケーション基本情報",
  APPLICATION_NAME_LABEL: "アプリケーション名称",
  APPLICATION_CODE_LABEL: "アプリケーションコード",
  DEVICES_FORM_TITLE: "連携デバイス一覧",
  DEVICES_NAME_LABEL: "デバイスシリアル",
  DEVICES_STATUS_LABEL: "承認状況",
  DEVICES_MEMO_LABEL: "メモ",
  DEVICE_TABLE_LABEL_TIME: "申請日時",
  DEVICE_TABLE_LABEL_MODEL: "デバイスモデル",
  DEVICE_TABLE_LABEL_SERIAL: "デバイスシリアル",
  DEVICE_TABLE_LABEL_STATUS: "承認状況",
  DEVICE_TABLE_LABEL_MEMO: "メモ",
  DEVICE_TABLE_LABEL_OPERATION: "",
  DEVICE_DELETE_DIALOG_TITLE: "連携デバイスを削除しますか?",
  DEVICE_DELETE_DIALOG_TIP:
    "このデバイスにアクセスできなくなります。<br/>削除すると設定を復元することはできません。",
  BATCH_EDIT_TABLE_TIP: "{n} 件の連携デバイスを選択中",
  BATCH_EDIT_FORM_TIP: "選択した連携デバイスを一括設定",
  BATCH_EDIT_FORM_RADIO_NO_CHANGE: "変更しない",
  BATCH_EDIT_FORM_RADIO_ALL_CHANGE: "以下の内容で一括変更する（最大128文字）",
  PC_SHOW_ALL: "全件表示",
  SP_ADD_SHOW: "もっと見る",
  ERROR_DEVICE: "デバイスシリアル：",
};

export default access;
