/* tslint:disable */
/* eslint-disable */
/**
 * Safie API specification for Safie Developers
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.14.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface Developer
 */
export interface Developer {
  /**
   * ディベロッパーID
   * @type {number}
   * @memberof Developer
   */
  developer_id?: number;
  /**
   * ディベロッパー表示名
   * @type {string}
   * @memberof Developer
   */
  description?: string;
  /**
   * 担当者メールアドレス
   * @type {string}
   * @memberof Developer
   */
  mail_address?: string;
  /**
   * 担当者名（姓）
   * @type {string}
   * @memberof Developer
   */
  user_name1?: string;
  /**
   * 担当者名（名）
   * @type {string}
   * @memberof Developer
   */
  user_name2?: string;
  /**
   * 担当者名（姓、かな）
   * @type {string}
   * @memberof Developer
   */
  user_kana1?: string;
  /**
   * 担当者名（名、かな）
   * @type {string}
   * @memberof Developer
   */
  user_kana2?: string;
  /**
   * ユーザ種別 `company`: 法人および個人事業主 `personal`: 個人
   * @type {string}
   * @memberof Developer
   */
  user_type?: DeveloperUserTypeEnum;
  /**
   * 企業名
   * @type {string}
   * @memberof Developer
   */
  company_name?: string;
  /**
   * 企業代表者名
   * @type {string}
   * @memberof Developer
   */
  representative_name?: string;
  /**
   * 企業部署名
   * @type {string}
   * @memberof Developer
   */
  department?: string;
  /**
   * 郵便番号
   * @type {string}
   * @memberof Developer
   */
  postal_code?: string;
  /**
   * 都道府県名
   * @type {string}
   * @memberof Developer
   */
  prefecture?: string;
  /**
   * 市区群
   * @type {string}
   * @memberof Developer
   */
  city?: string;
  /**
   * 町名
   * @type {string}
   * @memberof Developer
   */
  town?: string;
  /**
   * 番地
   * @type {string}
   * @memberof Developer
   */
  street?: string;
  /**
   * 建物名
   * @type {string}
   * @memberof Developer
   */
  building?: string | null;
  /**
   * 電話番号
   * @type {string}
   * @memberof Developer
   */
  phone_number?: string;
  /**
   * 審査状況   - `created`: 未申請   - `submitted`: 申請済み   - `accepted`: 通過   - `denied`: 不可
   * @type {string}
   * @memberof Developer
   */
  verify?: DeveloperVerifyEnum;
  /**
   * 専用オプションを利用できるディベロッパーであるか否か。 ディベロッパーに関連付けられた専用オプションが一つでも存在する場合はTrueとなる。
   * @type {boolean}
   * @memberof Developer
   */
  dedicated_option_enabled?: boolean;
}

export const DeveloperUserTypeEnum = {
  Company: "company",
  Personal: "personal",
} as const;

export type DeveloperUserTypeEnum =
  (typeof DeveloperUserTypeEnum)[keyof typeof DeveloperUserTypeEnum];
export const DeveloperVerifyEnum = {
  Created: "created",
  Submitted: "submitted",
  Accepted: "accepted",
  Denied: "denied",
} as const;

export type DeveloperVerifyEnum =
  (typeof DeveloperVerifyEnum)[keyof typeof DeveloperVerifyEnum];
