import Cookies from "js-cookie";

// get cookie
export function getCookie(name: string) {
  return Cookies.get(name);
}
// set cookie
export function setCookie(
  name: string,
  value: string,
  option?: Cookies.CookieAttributes,
) {
  Cookies.set(name, value, option);
}

// remove cookie
export function removeCookie(name: string) {
  Cookies.remove(name);
}
