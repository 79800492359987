const layout = {
  MENU_MANAGE_TITLE: "管理ツール",
  MENU_APPLICATIONS_TITLE: "アプリケーション一覧",
  MENU_API_TITLE: "APIキー認証",
  MENU_OAUTH_TITLE: "OAuth2.0認証",
  MENU_PROFILE_TITLE: "ディベロッパー情報",
  HEADER_TUTORIAL_DEVELOPERS: "Safie Developersの使い方",
  HEADER_TUTORIAL_OAUTH: "OAuth2.0認証アプリケーションの作成",
  HEADER_TUTORIAL_API_KEY: "APIキー認証アプリケーションの作成",
  HEADER_GUIDELINES: "設計ガイドライン",
  HEADER_API_GUIDELINES: "API設計ガイドライン",
  HEADER_SDK_GUIDELINES: "SDK設計ガイドライン",
  HEADER_REFERENCE: "リファレンス",
  HEADER_API_REFERENCE: "APIリファレンス",
  HEADER_API_RESTRICTION: "API機能制約",
  HEADER_SDK_REFERENCE: "SDKリファレンス",
  HEADER_SDK_RESTRICTION: "SDK機能制約",
  HEADER_CONSULT: "お問い合わせ",
  HEADER_FAQ: "よくあるご質問",
  HEADER_INFO: "ディベロッパー情報",
  HEADER_USER: "個人アカウントへ変更",
  HEADER_CORPORATION: "Managerアカウントへ切替",
  HEADER_USER_TAG: "個人",
  HEADER_CORPORATION_TAG: "Manager",
  HEADER_LOGOUT: "ログアウト",
  HEADER_GUIDE: "スタートガイド",
  HEADER_FAQ_MENU: "FAQ",
  HEADER_API_LINK: "APIリファレンス",
  HEADER_MANAGE: "管理ツール",
  HEADER_LOGIN: "ログイン",
  HEADER_INFO_TAG: "マイページ",
  HEADER_ACCOUNT_MANAGEMENT: "アカウント管理",
  FOOTER_HOME: "ホーム",
  FOOTER_CASE: "活用事例",
  FOOTER_TUTORIAL_DEVELOPERS: "Safie Developersの使い方",
  FOOTER_API_TITLE: "APIキー認証",
  FOOTER_OAUTH_TITLE: "OAuth2.0認証",
  FOOTER_FAQ: "よくあるご質問（FAQ）",
  FOOTER_API_DESIGN: "API設計ガイドライン",
  FOOTER_API_REFERENCE: "APIリファレンス",
  FOOTER_SDK_DESIGN: "SDK設計ガイドライン",
  FOOTER_SDK_REFERENCE: "SDKリファレンス",
  FOOTER_API_RESTRICTION: "API機能制約",
  FOOTER_SDK_RESTRICTION: "SDK機能制約",
  FOOTER_CONSULT: "お問い合わせ",
  FOOTER_RELEASE_NOTES: "リリースノート",
  FOOTER_COMPANY: "セーフィー株式会社",
  FOOTER_OPERATING_PROFILE: "運営会社概要",
  FOOTER_API_PROFILE: "Safie Developers利用規約",
  FOOTER_COOKIE_LINK: "クッキー（Cookie）ポリシー",
  FOOTER_GUIDE_LINK: "情報セキュリティ基本方針",
};
export default layout;
