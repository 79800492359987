const oAuth = {
  // add
  TITLE: "OAuth2.0認証アプリケーション",
  TIP: "アプリケーションを新たに作成する場合は、以下の各項目を入力して「この内容で新規作成」を押してください",
  CARD_TITLE: "OAuth2.0認証アプリケーション新規作成",
  APP_NAME_LABEL: "アプリケーション名称",
  APP_NAME_PLACEHOLDER: "入力してください",
  ALL_APIS_LABEL: "現在および今後追加されるすべてのAPI・SDKを利用する",
  SELECT_LIVE_V2_API: "Live v2 APIを利用。Player SDK（JavaScript）は利用しない",
  SELECT_PLAYER_SDK: "Live v2 APIは利用しない。Player SDK（JavaScript）を利用",
  PEOPLE_DETECTION_LABEL: "AI People Count API",
  API_KEY_DEVICES_LABEL: "Devices v2",
  PLAN_PLACEHOLDER: "選択してください",
  PLAN_LABEL: "プラン",
  PLAN_VALUE: "プロダクト",
  OPEN_STATUS_LABEL: "公開状況",
  OPEN_STATUS_VALUE: "公開中",
  OPTION_TYPE_LABEL: "オプション種別",
  CREATE_BTN_TEXT: "この内容で新規作成",
  FINISH_DIALOG_TITLE: "新規作成完了",
  FINISH_DIALOG_CONTENT1: "新規登録が完了しました。",
  FINISH_DIALOG_CONTENT2:
    "Scopeは以下の設定になります。API利用時に設定してください。",
  FINISH_DIALOG_CONTENT3:
    "アプリケーション設定の基本情報から確認することができます。",
  TO_LIST_BTN_TEXT: "アプリケーション一覧に戻る",
  TO_EDIT_BTN_TEXT: "Client管理に遷移する",
  // edit
  EDIT_CARD_TITLE: "OAuth2.0認証アプリケーション設定",
  TAB_LABEL_BASIC: "基本情報",
  TAB_LABEL_CLIENT: "Client管理",
  SCOPE_LABEL: "Scope",
  BASIC_INFO_DELETE_DIALOG_CONTENT:
    "アプリケーションを削除するとアプリケーションに紐づく以下の情報が削除されます。<br/>・Client ID、Client Secret、Redirect URI、メモ<br/>・イベント定義",
  DELETE_BTN_TEXT: "このアプリケーションを削除",
  BASIC_INFO_SAVE_DIALOG_CONTENT:
    "アプリケーションを利用しているユーザーに対しても変更後の名称が反映されます。ご確認の上、変更をおこなってください。",
  // Client管理
  CLIENT_ADD_BTN_TEXT: "Client新規発行",
  CLIENT_TABLE_COLUMN_ID: "Client ID",
  CLIENT_TABLE_COLUMN_SECRET: "Client Secret",
  CLIENT_TABLE_COLUMN_URI: "Redirect URI",
  CLIENT_TABLE_COLUMN_URI_QUANTITY: "Redirect URI数",
  CLIENT_TABLE_COLUMN_MEMO: "メモ",
  CLIENT_TABLE_COLUMN_OPERATION: "",
  CLIENT_EXPAND_COLUMN: "番号",
  DELETE_DIALOG_TITLE: "Clientを削除しますか?",
  CREATE_DIALOG_TITLE: "Client新規発行",
  DIALOG_MEMO_EDIT: "メモ（最大128文字）",
  EDIT_DIALOG_TITLE: "Client設定",
  URI_ERROR_TEXT: "不正なURLです",
  CLIENT_SIZE_LIMIT: "Redirect URI作成数が上限に達しています。",
  DUPLICATE_URI_ERROR: "Redirect URIが重複しています",
  CLIENT_ID_DELETE_DIALOG_CONTENT:
    "Clientを削除するとClient IDに紐づく以下の情報が削除されます。<br/>・Client ID、Client Secret、Redirect URI、メモ<br/>・このClient IDを利用して作成されたアクセストークンの失効",
  REDIRECT_URI_TIP: "Redirect URIは、https〜を推奨します",
};

export default oAuth;
