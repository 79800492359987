const signup = {
  TITLE: "新規ユーザー登録",
  PASSWORD_SURE_LABEL: "パスワード（確認）",
  IS_AGREE_LINK1: "利用規約",
  IS_AGREE_TEXT1: "と",
  IS_AGREE_LINK2: "プライバシーポリシー",
  IS_AGREE_TEXT2: "に同意する",
  SUBMIT_BTN: "新規ユーザー登録",
  TIP_TITLE: "送信完了",
  TIP_CONTENT:
    "登録確認Eメールを送信しました。受信したEメールを開き、アクティベーションを行ってください。",
  TIP_TIP: "アクティベーションされるまでログインはできません",
};
export default signup;
