/* tslint:disable */
/* eslint-disable */
/**
 * Safie API specification for Safie Developers
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.14.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
  /**
   * 認証方式 `password`: パスワード認証（デフォルト） `otp`: TOTP認証
   * @type {string}
   * @memberof LoginRequest
   */
  grant_type?: LoginRequestGrantTypeEnum;
  /**
   * メールアドレス
   * @type {string}
   * @memberof LoginRequest
   */
  username?: string;
  /**
   * パスワード
   * @type {string}
   * @memberof LoginRequest
   */
  password?: string;
  /**
   * `true`: ブラウザを閉じたあともセッションクッキーを保持する。 `false`: ブラウザを閉じたときセッションを無効化する（デフォルト）。
   * @type {boolean}
   * @memberof LoginRequest
   */
  remember_me?: boolean;
  /**
   * ワンタイムパスワード（`grant_type=otp`の場合は必須）
   * @type {string}
   * @memberof LoginRequest
   */
  otp?: string;
}

export const LoginRequestGrantTypeEnum = {
  Password: "password",
  Otp: "otp",
} as const;

export type LoginRequestGrantTypeEnum =
  (typeof LoginRequestGrantTypeEnum)[keyof typeof LoginRequestGrantTypeEnum];
