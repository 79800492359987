import { developerSite } from "./developerSite";
import { Configuration } from "../backend-api";
import axiosInstance from "../request";
import { safieViewer } from "./safieViewer";

const apiBasePath = process.env.VUE_APP_BASE_PATH;
const config = new Configuration();

const services = {
  dev: new developerSite(config, apiBasePath, axiosInstance),
  safie: new safieViewer(config, apiBasePath, axiosInstance),
};
export default services;
