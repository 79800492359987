import { RouteRecordRaw } from "vue-router";

const staticRoutes: Array<RouteRecordRaw> = [
  // API利用規約
  {
    path: "terms-of-service",
    name: "termsOfService",
    component: () => import("../views/static/TermsOfService.vue"),
    meta: {
      layoutType: "noAside",
      breadcrumb: [
        {
          name: "ホーム",
          to: "/",
        },
        {
          name: "Safie Developers利用規約",
        },
      ],
    },
  },
  // API 機能制約
  {
    path: "terms-of-api",
    name: "termsOfApi",
    component: () => import("../views/static/TermsOfApi.vue"),
    meta: {
      layoutType: "noAside",
      breadcrumb: [
        {
          name: "ホーム",
          to: "/",
        },
        {
          name: "API 機能制約",
        },
      ],
    },
  },
  // リリースノート
  {
    path: "releasenotes",
    name: "ReleaseNotes",
    component: () => import("../views/static/ReleaseNotes.vue"),
    meta: {
      layoutType: "noAside",
      breadcrumb: [
        {
          name: "ホーム",
          to: "/",
        },
        {
          name: "リリースノート",
        },
      ],
    },
  },
  // スタートガイド
  // Safie Developers の使い方
  {
    path: "tutorial",
    children: [
      {
        path: "developers",
        name: "tutorialDevelopers",
        component: () => import("../views/static/tutorial/Developers.vue"),
        meta: {
          layoutType: "noAside",
          breadcrumb: [
            {
              name: "ホーム",
              to: "/",
            },
            {
              name: "Safie Developersの使い方",
            },
          ],
        },
      },
      // APIキー認証
      {
        path: "api-key",
        name: "tutorialApiKey",
        component: () => import("../views/static/tutorial/ApiKey.vue"),
        meta: {
          layoutType: "noAside",
          breadcrumb: [
            {
              name: "ホーム",
              to: "/",
            },
            {
              name: "APIキー認証",
            },
          ],
        },
      },
      // OAuth2.0認証
      {
        path: "oauth",
        name: "tutorialOAuth",
        component: () => import("../views/static/tutorial/OAuth.vue"),
        meta: {
          layoutType: "noAside",
          breadcrumb: [
            {
              name: "ホーム",
              to: "/",
            },
            {
              name: "OAuth2.0認証",
            },
          ],
        },
      },
    ],
  },
  // API設計ガイドライン
  {
    path: "guidelines",
    name: "guidelines",
    component: () => import("../views/static/Guidelines.vue"),
    meta: {
      layoutType: "noAside",
      breadcrumb: [
        {
          name: "ホーム",
          to: "/",
        },
        {
          name: "設計ガイドライン",
        },
      ],
    },
  },
  {
    path: "casestudy",
    name: "caseStudy",
    component: () => import("../views/static/case/index.vue"),
    meta: {
      layoutType: "noAside",
      breadcrumb: [
        {
          name: "ホーム",
          to: "/",
        },
        {
          name: "活用事例",
        },
      ],
    },
  },
  {
    path: "/casestudy/:id",
    name: "case",
    component: () => import("../views/static/case/Case.vue"),
    meta: {
      layoutType: "noAside",
      breadcrumb: [
        {
          name: "ホーム",
          to: "/",
        },
        {
          name: "活用事例",
          to: "/casestudy",
        },
      ],
    },
  },
  // FAQ
  {
    path: "faq",
    name: "faq",
    component: () => import("../views/static/Faq.vue"),
    meta: {
      layoutType: "noAside",
      breadcrumb: [
        {
          name: "ホーム",
          to: "/",
        },
        {
          name: "よくあるご質問（FAQ）",
        },
      ],
    },
  },
  // API reference
  {
    path: "reference/api",
    name: "reference-api",
    component: () => import("../views/static/reference/ReferenceOfApi.vue"),
    meta: {
      layoutType: "noAside",
      breadcrumb: [
        {
          name: "ホーム",
          to: "/",
        },
        {
          name: "APIリファレンス",
        },
      ],
    },
  },
  // SDK reference
  {
    path: "reference/sdk",
    name: "reference-sdk",
    component: () => import("../views/static/reference/ReferenceOfSdk.vue"),
    meta: {
      layoutType: "noAside",
      breadcrumb: [
        {
          name: "ホーム",
          to: "/",
        },
        {
          name: "SDKリファレンス",
        },
      ],
    },
  },
  // SDK guidelines
  {
    path: "guidelines/sdk",
    name: "guidelines-sdk",
    component: () => import("../views/static/GuidelinesOfSdk.vue"),
    meta: {
      layoutType: "noAside",
      breadcrumb: [
        {
          name: "ホーム",
          to: "/",
        },
        {
          name: "SDK設計ガイドライン",
        },
      ],
    },
  },
  // SDK restriction
  {
    path: "terms-of-api/sdk",
    name: "terms-of-api-sdk",
    component: () => import("../views/static/TermsOfSdk.vue"),
    meta: {
      layoutType: "noAside",
      breadcrumb: [
        {
          name: "ホーム",
          to: "/",
        },
        {
          name: "SDK機能制約",
        },
      ],
    },
  },
];

export default staticRoutes;
