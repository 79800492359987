/* tslint:disable */
/* eslint-disable */
/**
 * Safie API specification for Safie Developers
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.14.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
// @ts-ignore
import { AccessControlSetting } from "../models";
// @ts-ignore
import { AccessControlsListResponse } from "../models";
// @ts-ignore
import { AccessControlsPatchRequest } from "../models";
// @ts-ignore
import { AccessControlsPostRequest } from "../models";
// @ts-ignore
import { ApikeyRequest } from "../models";
// @ts-ignore
import { ApikeyResponse } from "../models";
// @ts-ignore
import { ApplicationDetailResponse } from "../models";
// @ts-ignore
import { ApplicationInstallationDevicesPostRequest } from "../models";
// @ts-ignore
import { ApplicationInstallationList } from "../models";
// @ts-ignore
import { ApplicationList } from "../models";
// @ts-ignore
import { ApplicationPatchRequest } from "../models";
// @ts-ignore
import { ApplicationRequest } from "../models";
// @ts-ignore
import { ApplicationSuccess } from "../models";
// @ts-ignore
import { Developer } from "../models";
// @ts-ignore
import { DeveloperDedicatedOptionList } from "../models";
// @ts-ignore
import { DeveloperQuota } from "../models";
// @ts-ignore
import { DeveloperRequest } from "../models";
// @ts-ignore
import { DeveloperResponse } from "../models";
// @ts-ignore
import { DeveloperUpdateRequest } from "../models";
// @ts-ignore
import { EventDefinitionListResponse } from "../models";
// @ts-ignore
import { EventDefinitionPatchRequest } from "../models";
// @ts-ignore
import { EventDefinitionRequest } from "../models";
// @ts-ignore
import { EventDefinitionResponse } from "../models";
// @ts-ignore
import { LoginRequest } from "../models";
// @ts-ignore
import { Model400 } from "../models";
// @ts-ignore
import { Model401 } from "../models";
// @ts-ignore
import { Model403 } from "../models";
// @ts-ignore
import { Model404 } from "../models";
// @ts-ignore
import { Model409 } from "../models";
// @ts-ignore
import { Model428 } from "../models";
// @ts-ignore
import { Model500 } from "../models";
// @ts-ignore
import { Model503 } from "../models";
// @ts-ignore
import { Oauth2ClientList } from "../models";
// @ts-ignore
import { Oauth2ClientRequest } from "../models";
// @ts-ignore
import { Oauth2ClientResponse } from "../models";
// @ts-ignore
import { Success } from "../models";
// @ts-ignore
import { TransferRequest } from "../models";
// @ts-ignore
import { UserinfoResponse } from "../models";
/**
 * DeveloperSiteAPIApi - axios parameter creator
 * @export
 */
export const DeveloperSiteAPIApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * ディベロッパーサイトにログインする。 操作に成功するとセッションクッキーが発行され、 以降のAPI呼び出しでuse, enterprise (あれば) およびsafie-api-developerスコープでのCookie認証が行えるようになる。  otp認証が必要なユーザに対して、grant_type=passwordで認証を行った場合には、以下のような応答をステータスコード401で返すため、応答body内の`code`の値を参照して、値が`C-001-0003`であれば、otp認証が必要であると判別する。 ``` {   \"message\": \"2段階認証が必要です。\",   \"code\": \"C-001-0003\",   \"detail\": {     \"status\": \"MFA required.\"   },   \"error_description\": \"2段階認証が必要です。\" } ```
     * @summary ディベロッパーログイン
     * @param {string} xXSRFToken
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devAuthorizePost: async (
      xXSRFToken: string,
      loginRequest: LoginRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists("devAuthorizePost", "xXSRFToken", xXSRFToken);
      // verify required parameter 'loginRequest' is not null or undefined
      assertParamExists("devAuthorizePost", "loginRequest", loginRequest);
      const localVarPath = `/dev/authorize`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieXSRF required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * XSRF Cookieを取得する。リクエストが成功すると、_xsrfのCookieが設定される。
     * @summary XSRF Cookie 取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devCertificateGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/dev/certificate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したディベロッパーに属するアプリケーションを作成する。 `application_auth_type`が`oauth2`の場合は、`application_plan=production`、`published=true`でなければならない。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - アプリケーション作成後にアプリケーション数上限を超えないこと（上限はディベロッパー毎に設定されている値が参照される）
     * @summary アプリケーション作成
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {ApplicationRequest} applicationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDeveloperApplicationsPost: async (
      xXSRFToken: string,
      developerId: number,
      applicationRequest: ApplicationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists(
        "devDeveloperApplicationsPost",
        "xXSRFToken",
        xXSRFToken,
      );
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDeveloperApplicationsPost",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationRequest' is not null or undefined
      assertParamExists(
        "devDeveloperApplicationsPost",
        "applicationRequest",
        applicationRequest,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications`.replace(
          `{${"developer_id"}}`,
          encodeURIComponent(String(developerId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieXSRF required

      // authentication cookieAuth required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        applicationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したアクセス制御設定を削除する。  前提条件:   - 認証: safie-api-developerスコープ
     * @summary アクセス制御設定削除
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {number} settingId アクセス制御設定のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsAccessControlsDelete: async (
      developerId: number,
      applicationId: number,
      settingId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsAccessControlsDelete",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsAccessControlsDelete",
        "applicationId",
        applicationId,
      );
      // verify required parameter 'settingId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsAccessControlsDelete",
        "settingId",
        settingId,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}/access_controls/{setting_id}`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          )
          .replace(`{${"setting_id"}}`, encodeURIComponent(String(settingId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * アプリケーションに関連付けられたアクセス制御設定の一覧を取得する。一覧は設定の作成日時順で得られる。  前提条件:   - 認証: safie-api-developerスコープ
     * @summary アクセス制御設定一覧取得
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsAccessControlsGet: async (
      developerId: number,
      applicationId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsAccessControlsGet",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsAccessControlsGet",
        "applicationId",
        applicationId,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}/access_controls`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したアクセス制御設定を更新する。  前提条件:   - 認証: safie-api-developerスコープ
     * @summary アクセス制御設定更新
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {number} settingId アクセス制御設定のID
     * @param {AccessControlsPatchRequest} accessControlsPatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsAccessControlsPatch: async (
      developerId: number,
      applicationId: number,
      settingId: number,
      accessControlsPatchRequest: AccessControlsPatchRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsAccessControlsPatch",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsAccessControlsPatch",
        "applicationId",
        applicationId,
      );
      // verify required parameter 'settingId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsAccessControlsPatch",
        "settingId",
        settingId,
      );
      // verify required parameter 'accessControlsPatchRequest' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsAccessControlsPatch",
        "accessControlsPatchRequest",
        accessControlsPatchRequest,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}/access_controls/{setting_id}`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          )
          .replace(`{${"setting_id"}}`, encodeURIComponent(String(settingId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        accessControlsPatchRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したアプリケーションに属するアクセス制御設定を作成する。 アクセス制御設定が一つ以上存在するアプリケーションでは、アクセス制御設定のいずれかで許可されたソースIPアドレスからのアクセスのみがSafie APIで許可される。 アクセス制御設定が一つも存在しないアプリケーションでは、全てのソースIPアドレスからSafie APIアクセスが許可される（制限なし）。 各アプリケーション毎に作成可能なアクセス制御設定の上限は5である。  前提条件:   - 認証: safie-api-developerスコープ   - アクセス制御設定数が上限5を超えないこと
     * @summary アクセス制御設定作成
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {AccessControlsPostRequest} accessControlsPostRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsAccessControlsPost: async (
      xXSRFToken: string,
      developerId: number,
      applicationId: number,
      accessControlsPostRequest: AccessControlsPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsAccessControlsPost",
        "xXSRFToken",
        xXSRFToken,
      );
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsAccessControlsPost",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsAccessControlsPost",
        "applicationId",
        applicationId,
      );
      // verify required parameter 'accessControlsPostRequest' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsAccessControlsPost",
        "accessControlsPostRequest",
        accessControlsPostRequest,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}/access_controls`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieXSRF required

      // authentication cookieAuth required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        accessControlsPostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したAPIキーを削除する。指定したAPIキーによるアクセスは無効化される。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定したAPIキーが指定したアプリケーションに属すること
     * @summary アプリケーションAPIキー削除
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {number} keyId APIキーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsApikeysDelete: async (
      developerId: number,
      applicationId: number,
      keyId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsApikeysDelete",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsApikeysDelete",
        "applicationId",
        applicationId,
      );
      // verify required parameter 'keyId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsApikeysDelete",
        "keyId",
        keyId,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}/apikeys/{key_id}`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          )
          .replace(`{${"key_id"}}`, encodeURIComponent(String(keyId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したアプリケーションに属するAPIキーを作成する。 作成されたAPIキーのアクセス範囲は、アプリケーションの`application_plan`及び アプリケーションで定義されたAPI利用枠（`apis`）、アクセス権限（`permissions`）によって異なる。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたDeveloperに属すること   - 作成後にAPIキー数上限2を超えないこと   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること
     * @summary アプリケーションAPIキー作成
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {ApikeyRequest} apikeyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsApikeysPost: async (
      xXSRFToken: string,
      developerId: number,
      applicationId: number,
      apikeyRequest: ApikeyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsApikeysPost",
        "xXSRFToken",
        xXSRFToken,
      );
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsApikeysPost",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsApikeysPost",
        "applicationId",
        applicationId,
      );
      // verify required parameter 'apikeyRequest' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsApikeysPost",
        "apikeyRequest",
        apikeyRequest,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}/apikeys`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieXSRF required

      // authentication cookieAuth required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        apikeyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したアプリケーションの詳細情報を取得する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定したアプリケーションが指定したディベロッパーに属すること
     * @summary アプリケーション詳細
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsApplicationIdGet: async (
      developerId: number,
      applicationId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsApplicationIdGet",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsApplicationIdGet",
        "applicationId",
        applicationId,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したアプリケーションを削除する。  アプリケーションを削除したとき関連するOAuth2 ClientおよびAPI Keyは削除され、アプリケーションにより取得されたデバイスへの権限がすべて無効化されます。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること
     * @summary アプリケーション削除
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsDelete: async (
      developerId: number,
      applicationId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsDelete",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsDelete",
        "applicationId",
        applicationId,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * アプリケーションに関連付けられたイベント定義一覧を取得する。  前提条件:   - 認証: safie-api-developerスコープ
     * @summary イベント定義一覧取得
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsEventDefinitionsGet: async (
      developerId: number,
      applicationId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsEventDefinitionsGet",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsEventDefinitionsGet",
        "applicationId",
        applicationId,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}/event_definitions`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したイベント定義のイベント表示名、カラーコードおよび有効・無効状態を更新する。  前提条件:   - 認証: safie-api-developerスコープ
     * @summary イベント定義更新
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {string} definitionId イベント定義ID
     * @param {EventDefinitionPatchRequest} eventDefinitionPatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsEventDefinitionsPatch: async (
      developerId: number,
      applicationId: number,
      definitionId: string,
      eventDefinitionPatchRequest: EventDefinitionPatchRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsEventDefinitionsPatch",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsEventDefinitionsPatch",
        "applicationId",
        applicationId,
      );
      // verify required parameter 'definitionId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsEventDefinitionsPatch",
        "definitionId",
        definitionId,
      );
      // verify required parameter 'eventDefinitionPatchRequest' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsEventDefinitionsPatch",
        "eventDefinitionPatchRequest",
        eventDefinitionPatchRequest,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}/event_definitions/{definition_id}`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          )
          .replace(
            `{${"definition_id"}}`,
            encodeURIComponent(String(definitionId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        eventDefinitionPatchRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したアプリケーションに属するイベント定義を作成する。イベント定義は作成時は無効状態となる。 各アプリケーション毎に作成可能なイベント定義の上限は5である。  前提条件:   - 認証: safie-api-developerスコープ   - イベント定義作成数の上限5を超えないこと
     * @summary イベント定義作成
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {EventDefinitionRequest} eventDefinitionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsEventDefinitionsPost: async (
      xXSRFToken: string,
      developerId: number,
      applicationId: number,
      eventDefinitionRequest: EventDefinitionRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsEventDefinitionsPost",
        "xXSRFToken",
        xXSRFToken,
      );
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsEventDefinitionsPost",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsEventDefinitionsPost",
        "applicationId",
        applicationId,
      );
      // verify required parameter 'eventDefinitionRequest' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsEventDefinitionsPost",
        "eventDefinitionRequest",
        eventDefinitionRequest,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}/event_definitions`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieXSRF required

      // authentication cookieAuth required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        eventDefinitionRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したディベロッパーに属するアプリケーションを列挙する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること
     * @summary アプリケーション一覧
     * @param {number} developerId ディベロッパーID
     * @param {'apikey' | 'oauth2'} [applicationAuthType] &#x60;apikey&#x60;: APIキーを利用するアプリケーションを取得 &#x60;oauth2&#x60;: OAuth2認可プロセスを通して取得したアクセストークンを利用するアプリケーションを取得
     * @param {number} [offset] 返却するリストのオフセット
     * @param {number} [limit] 返却するリストの最大件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsGet: async (
      developerId: number,
      applicationAuthType?: "apikey" | "oauth2",
      offset?: number,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsGet",
        "developerId",
        developerId,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications`.replace(
          `{${"developer_id"}}`,
          encodeURIComponent(String(developerId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (applicationAuthType !== undefined) {
        localVarQueryParameter["application_auth_type"] = applicationAuthType;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したアプリケーションへの利用申請を削除し、申請前の状態にします。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること   - 指定されたデバイスが指定されたアプリケーションに対して利用申請されていること
     * @summary アプリケーション利用申請削除
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {number} deviceId デバイスID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsInstallationsDevicesDelete: async (
      developerId: number,
      applicationId: number,
      deviceId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsInstallationsDevicesDelete",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsInstallationsDevicesDelete",
        "applicationId",
        applicationId,
      );
      // verify required parameter 'deviceId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsInstallationsDevicesDelete",
        "deviceId",
        deviceId,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}/installations/devices/{device_id}`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          )
          .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したアプリケーションに対する複数の利用申請を一括で変更する。 更新前と更新後の値が同一の場合、値を変更することなくAPIは終了する。 指定されたデバイスの内、一つでも不正なものが存在した場合、指定した全てデバイスの更新が行われない。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること   - 指定されたデバイスが指定されたアプリケーションに対して利用申請されていること
     * @summary アプリケーション利用申請一括更新
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {ApplicationInstallationDevicesPostRequest} applicationInstallationDevicesPostRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsInstallationsDevicesPost: async (
      xXSRFToken: string,
      developerId: number,
      applicationId: number,
      applicationInstallationDevicesPostRequest: ApplicationInstallationDevicesPostRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsInstallationsDevicesPost",
        "xXSRFToken",
        xXSRFToken,
      );
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsInstallationsDevicesPost",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsInstallationsDevicesPost",
        "applicationId",
        applicationId,
      );
      // verify required parameter 'applicationInstallationDevicesPostRequest' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsInstallationsDevicesPost",
        "applicationInstallationDevicesPostRequest",
        applicationInstallationDevicesPostRequest,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}/installations/devices`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieXSRF required

      // authentication cookieAuth required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        applicationInstallationDevicesPostRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * アプリケーションを指定して利用申請を行っているデバイスの一覧を取得する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること
     * @summary アプリケーション利用一覧
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {string} [deviceModel] デバイスモデルによる絞り込み検索 (部分一致)
     * @param {string} [deviceSerial] デバイスシリアルによる絞り込み検索 (部分一致)
     * @param {Array<'created' | 'approved' | 'denied'>} [status] 承認状況による絞り込み検索。複数指定可。 &#x60;created&#x60;: 申請中、 &#x60;approved&#x60;: 承認済み、 &#x60;denied&#x60;: 拒否済み
     * @param {string} [memo] メモ欄による絞り込み検索 (部分一致)
     * @param {number} [offset] 返却するリストのオフセット
     * @param {number} [limit] 返却するリストの最大件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsInstallationsGet: async (
      developerId: number,
      applicationId: number,
      deviceModel?: string,
      deviceSerial?: string,
      status?: Array<"created" | "approved" | "denied">,
      memo?: string,
      offset?: number,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsInstallationsGet",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsInstallationsGet",
        "applicationId",
        applicationId,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}/installations`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (deviceModel !== undefined) {
        localVarQueryParameter["device_model"] = deviceModel;
      }

      if (deviceSerial !== undefined) {
        localVarQueryParameter["device_serial"] = deviceSerial;
      }

      if (status) {
        localVarQueryParameter["status"] = status;
      }

      if (memo !== undefined) {
        localVarQueryParameter["memo"] = memo;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したOAuth2クライアントを削除する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること   - 指定されたOAuth2クライアントが指定されたアプリケーションに属すること
     * @summary アプリケーションOAuth2クライアント削除
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {string} clientId OAuth2クライアントID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsOauth2ClientsDelete: async (
      developerId: number,
      applicationId: number,
      clientId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsOauth2ClientsDelete",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsOauth2ClientsDelete",
        "applicationId",
        applicationId,
      );
      // verify required parameter 'clientId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsOauth2ClientsDelete",
        "clientId",
        clientId,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}/oauth2_clients/{client_id}`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          )
          .replace(`{${"client_id"}}`, encodeURIComponent(String(clientId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したアプリケーションに属するOAuth2クライアントの一覧を取得する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること
     * @summary アプリケーションOAuth2クライアント一覧
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {number} [offset] 返却するリストのオフセット
     * @param {number} [limit] 返却するリストの最大件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsOauth2ClientsGet: async (
      developerId: number,
      applicationId: number,
      offset?: number,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsOauth2ClientsGet",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsOauth2ClientsGet",
        "applicationId",
        applicationId,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}/oauth2_clients`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したアプリケーションに属するOAuth2クライアントを作成する。 クライアントIDおよびクライアントシークレットは乱数により生成される。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して権限を持つこと   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 作成後にクライアントの数の上限2を超えないこと   - リダイレクトURL数が上限30を超えないこと   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること
     * @summary アプリケーションOAuth2クライアント作成
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {Oauth2ClientRequest} oauth2ClientRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsOauth2ClientsPost: async (
      xXSRFToken: string,
      developerId: number,
      applicationId: number,
      oauth2ClientRequest: Oauth2ClientRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsOauth2ClientsPost",
        "xXSRFToken",
        xXSRFToken,
      );
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsOauth2ClientsPost",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsOauth2ClientsPost",
        "applicationId",
        applicationId,
      );
      // verify required parameter 'oauth2ClientRequest' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsOauth2ClientsPost",
        "oauth2ClientRequest",
        oauth2ClientRequest,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}/oauth2_clients`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieXSRF required

      // authentication cookieAuth required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        oauth2ClientRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したOAuth2クライアントの属性を更新する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること   - 指定されたOAuth2クライアントが指定されたアプリケーションに属すること   - 更新後にリダイレクトURL数が上限30を超えないこと
     * @summary アプリケーションOAuth2クライアント更新
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {string} clientId OAuth2クライアントID
     * @param {Oauth2ClientRequest} oauth2ClientRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsOauth2ClientsPut: async (
      developerId: number,
      applicationId: number,
      clientId: string,
      oauth2ClientRequest: Oauth2ClientRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsOauth2ClientsPut",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsOauth2ClientsPut",
        "applicationId",
        applicationId,
      );
      // verify required parameter 'clientId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsOauth2ClientsPut",
        "clientId",
        clientId,
      );
      // verify required parameter 'oauth2ClientRequest' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsOauth2ClientsPut",
        "oauth2ClientRequest",
        oauth2ClientRequest,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}/oauth2_clients/{client_id}`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          )
          .replace(`{${"client_id"}}`, encodeURIComponent(String(clientId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        oauth2ClientRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * アプリケーションを更新します。アプリケーション名称とアプリケーション公開状況のみが変更可能です。 公開状況を非公開に変更した場合、公開済みのアプリケーションのユーザ利用申請状況はそのままに非公開となる。 (追加での利用申請ができなくなるが、すでに申請されているものには影響しない。)  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること
     * @summary アプリケーション更新
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {ApplicationPatchRequest} applicationPatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsPatch: async (
      developerId: number,
      applicationId: number,
      applicationPatchRequest: ApplicationPatchRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsPatch",
        "developerId",
        developerId,
      );
      // verify required parameter 'applicationId' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsPatch",
        "applicationId",
        applicationId,
      );
      // verify required parameter 'applicationPatchRequest' is not null or undefined
      assertParamExists(
        "devDevelopersApplicationsPatch",
        "applicationPatchRequest",
        applicationPatchRequest,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/applications/{application_id}`
          .replace(
            `{${"developer_id"}}`,
            encodeURIComponent(String(developerId)),
          )
          .replace(
            `{${"application_id"}}`,
            encodeURIComponent(String(applicationId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        applicationPatchRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * ディベロッパーが利用可能な専用オプションの一覧を取得する。 Management Toolよりそのディベロッパーに関連付けられた専用オプションが利用可能。  前提条件:   - 認証: `safie-api-developer`スコープ
     * @summary ディベロッパー専用オプション一覧
     * @param {number} developerId ディベロッパーID
     * @param {number} [offset] 返却するリストのオフセット
     * @param {number} [limit] 返却するリストの最大件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersDedicatedOptionsGet: async (
      developerId: number,
      offset?: number,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersDedicatedOptionsGet",
        "developerId",
        developerId,
      );
      const localVarPath =
        `/dev/developers/{developer_id}/dedicated_options`.replace(
          `{${"developer_id"}}`,
          encodeURIComponent(String(developerId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 指定したディベロッパーの情報を更新する。ディベロッパー表示名と、審査状況の変更のみが可能。 審査状況は`submitted`のみ指定可能。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること
     * @summary ディベロッパー更新
     * @param {number} developerId ディベロッパーID
     * @param {DeveloperUpdateRequest} developerUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersPut: async (
      developerId: number,
      developerUpdateRequest: DeveloperUpdateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists("devDevelopersPut", "developerId", developerId);
      // verify required parameter 'developerUpdateRequest' is not null or undefined
      assertParamExists(
        "devDevelopersPut",
        "developerUpdateRequest",
        developerUpdateRequest,
      );
      const localVarPath = `/dev/developers/{developer_id}`.replace(
        `{${"developer_id"}}`,
        encodeURIComponent(String(developerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "PATCH",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        developerUpdateRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * ディベロッパーに設定されているクオータ情報を取得する  前提条件:   - 認証: `safie-api-developer`スコープ
     * @summary ディベロッパークオータ情報
     * @param {number} developerId ディベロッパーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersQuotasGet: async (
      developerId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists("devDevelopersQuotasGet", "developerId", developerId);
      const localVarPath = `/dev/developers/{developer_id}/quotas`.replace(
        `{${"developer_id"}}`,
        encodeURIComponent(String(developerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * ディベロッパーに関連付けられたユーザ（もしくは企業）を別のユーザまたは企業に変更します。  発行済の秘密情報 (OAuth2 client secretおよびAPI Key) は変更されません。 移行前のユーザーがアクセス権を持たないことを保証するには、既存のOAuth2 client secretおよびAPI Keyを無効化してください。  オーナー委譲はSafieユーザーとその所属する企業の間のみで行うことができる。  前提条件:   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定されたSafieユーザまたは企業にディベロッパーが関連付けられていないこと   - `mail_address` が指定される場合、ディベロッパーに関連付けられた企業に指定されたユーザーが全体管理者として所属していること   - `corporation` が指定される場合、ディベロッパーに関連付けられたユーザーが指定された企業に全体管理者として所属していること
     * @summary ディベロッパーオーナー権限委譲
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {TransferRequest} transferRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersTransferPost: async (
      xXSRFToken: string,
      developerId: number,
      transferRequest: TransferRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists("devDevelopersTransferPost", "xXSRFToken", xXSRFToken);
      // verify required parameter 'developerId' is not null or undefined
      assertParamExists(
        "devDevelopersTransferPost",
        "developerId",
        developerId,
      );
      // verify required parameter 'transferRequest' is not null or undefined
      assertParamExists(
        "devDevelopersTransferPost",
        "transferRequest",
        transferRequest,
      );
      const localVarPath = `/dev/developers/{developer_id}/transfer`.replace(
        `{${"developer_id"}}`,
        encodeURIComponent(String(developerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieXSRF required

      // authentication cookieAuth required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transferRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * ディベロッパーサイトからログアウトします。 操作に成功するとセッションクッキーが無効化されまたUAから削除されます。  前提条件   - Safieユーザとしてログイン済みであること (いずれのスコープでも)
     * @summary ディベロッパーログアウト
     * @param {string} xXSRFToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devLogoutPost: async (
      xXSRFToken: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists("devLogoutPost", "xXSRFToken", xXSRFToken);
      const localVarPath = `/dev/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieXSRF required

      // authentication cookieAuth required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * ディベロッパーを作成し、現在のログインユーザーを作成されたディベロッパーと関連付ける。 ログイン中のユーザが企業に所属する場合には、個人のSafieユーザアカウントに関連付けるか、所属する企業に関連付けるかを選択できる。  前提条件:   - 認証: `use`スコープ   - `signup_account_type=general`を指定した場合、現在のログインユーザーに対応するディベロッパーが存在しないこと   - `signup_account_type=enterprise`を指定した場合、1) 現在のログインユーザが企業に所属していること、2) ログインユーザが所属企業の全体管理者であること、3) またその所属する企業に対応するディベロッパーが存在しないこと。
     * @summary ディベロッパー登録
     * @param {string} xXSRFToken
     * @param {DeveloperRequest} developerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devSignupPost: async (
      xXSRFToken: string,
      developerRequest: DeveloperRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'xXSRFToken' is not null or undefined
      assertParamExists("devSignupPost", "xXSRFToken", xXSRFToken);
      // verify required parameter 'developerRequest' is not null or undefined
      assertParamExists("devSignupPost", "developerRequest", developerRequest);
      const localVarPath = `/dev/signup`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieXSRF required

      // authentication cookieAuth required

      if (xXSRFToken != null) {
        localVarHeaderParameter["X-XSRFToken"] = String(xXSRFToken);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        developerRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 現在ログイン中のディベロッパーサイトにおけるユーザー情報を取得する。  前提条件:   - 認証: `safie-api-developer`スコープ
     * @summary ディベロッパーユーザ情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devUserinfoGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/dev/userinfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DeveloperSiteAPIApi - functional programming interface
 * @export
 */
export const DeveloperSiteAPIApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DeveloperSiteAPIApiAxiosParamCreator(configuration);
  return {
    /**
     * ディベロッパーサイトにログインする。 操作に成功するとセッションクッキーが発行され、 以降のAPI呼び出しでuse, enterprise (あれば) およびsafie-api-developerスコープでのCookie認証が行えるようになる。  otp認証が必要なユーザに対して、grant_type=passwordで認証を行った場合には、以下のような応答をステータスコード401で返すため、応答body内の`code`の値を参照して、値が`C-001-0003`であれば、otp認証が必要であると判別する。 ``` {   \"message\": \"2段階認証が必要です。\",   \"code\": \"C-001-0003\",   \"detail\": {     \"status\": \"MFA required.\"   },   \"error_description\": \"2段階認証が必要です。\" } ```
     * @summary ディベロッパーログイン
     * @param {string} xXSRFToken
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devAuthorizePost(
      xXSRFToken: string,
      loginRequest: LoginRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devAuthorizePost(
          xXSRFToken,
          loginRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * XSRF Cookieを取得する。リクエストが成功すると、_xsrfのCookieが設定される。
     * @summary XSRF Cookie 取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devCertificateGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devCertificateGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したディベロッパーに属するアプリケーションを作成する。 `application_auth_type`が`oauth2`の場合は、`application_plan=production`、`published=true`でなければならない。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - アプリケーション作成後にアプリケーション数上限を超えないこと（上限はディベロッパー毎に設定されている値が参照される）
     * @summary アプリケーション作成
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {ApplicationRequest} applicationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDeveloperApplicationsPost(
      xXSRFToken: string,
      developerId: number,
      applicationRequest: ApplicationRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationSuccess>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDeveloperApplicationsPost(
          xXSRFToken,
          developerId,
          applicationRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したアクセス制御設定を削除する。  前提条件:   - 認証: safie-api-developerスコープ
     * @summary アクセス制御設定削除
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {number} settingId アクセス制御設定のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsAccessControlsDelete(
      developerId: number,
      applicationId: number,
      settingId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsAccessControlsDelete(
          developerId,
          applicationId,
          settingId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * アプリケーションに関連付けられたアクセス制御設定の一覧を取得する。一覧は設定の作成日時順で得られる。  前提条件:   - 認証: safie-api-developerスコープ
     * @summary アクセス制御設定一覧取得
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsAccessControlsGet(
      developerId: number,
      applicationId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AccessControlsListResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsAccessControlsGet(
          developerId,
          applicationId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したアクセス制御設定を更新する。  前提条件:   - 認証: safie-api-developerスコープ
     * @summary アクセス制御設定更新
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {number} settingId アクセス制御設定のID
     * @param {AccessControlsPatchRequest} accessControlsPatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsAccessControlsPatch(
      developerId: number,
      applicationId: number,
      settingId: number,
      accessControlsPatchRequest: AccessControlsPatchRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AccessControlSetting>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsAccessControlsPatch(
          developerId,
          applicationId,
          settingId,
          accessControlsPatchRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したアプリケーションに属するアクセス制御設定を作成する。 アクセス制御設定が一つ以上存在するアプリケーションでは、アクセス制御設定のいずれかで許可されたソースIPアドレスからのアクセスのみがSafie APIで許可される。 アクセス制御設定が一つも存在しないアプリケーションでは、全てのソースIPアドレスからSafie APIアクセスが許可される（制限なし）。 各アプリケーション毎に作成可能なアクセス制御設定の上限は5である。  前提条件:   - 認証: safie-api-developerスコープ   - アクセス制御設定数が上限5を超えないこと
     * @summary アクセス制御設定作成
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {AccessControlsPostRequest} accessControlsPostRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsAccessControlsPost(
      xXSRFToken: string,
      developerId: number,
      applicationId: number,
      accessControlsPostRequest: AccessControlsPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AccessControlSetting>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsAccessControlsPost(
          xXSRFToken,
          developerId,
          applicationId,
          accessControlsPostRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したAPIキーを削除する。指定したAPIキーによるアクセスは無効化される。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定したAPIキーが指定したアプリケーションに属すること
     * @summary アプリケーションAPIキー削除
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {number} keyId APIキーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsApikeysDelete(
      developerId: number,
      applicationId: number,
      keyId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsApikeysDelete(
          developerId,
          applicationId,
          keyId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したアプリケーションに属するAPIキーを作成する。 作成されたAPIキーのアクセス範囲は、アプリケーションの`application_plan`及び アプリケーションで定義されたAPI利用枠（`apis`）、アクセス権限（`permissions`）によって異なる。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたDeveloperに属すること   - 作成後にAPIキー数上限2を超えないこと   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること
     * @summary アプリケーションAPIキー作成
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {ApikeyRequest} apikeyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsApikeysPost(
      xXSRFToken: string,
      developerId: number,
      applicationId: number,
      apikeyRequest: ApikeyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApikeyResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsApikeysPost(
          xXSRFToken,
          developerId,
          applicationId,
          apikeyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したアプリケーションの詳細情報を取得する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定したアプリケーションが指定したディベロッパーに属すること
     * @summary アプリケーション詳細
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsApplicationIdGet(
      developerId: number,
      applicationId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsApplicationIdGet(
          developerId,
          applicationId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したアプリケーションを削除する。  アプリケーションを削除したとき関連するOAuth2 ClientおよびAPI Keyは削除され、アプリケーションにより取得されたデバイスへの権限がすべて無効化されます。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること
     * @summary アプリケーション削除
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsDelete(
      developerId: number,
      applicationId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsDelete(
          developerId,
          applicationId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * アプリケーションに関連付けられたイベント定義一覧を取得する。  前提条件:   - 認証: safie-api-developerスコープ
     * @summary イベント定義一覧取得
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsEventDefinitionsGet(
      developerId: number,
      applicationId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EventDefinitionListResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsEventDefinitionsGet(
          developerId,
          applicationId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したイベント定義のイベント表示名、カラーコードおよび有効・無効状態を更新する。  前提条件:   - 認証: safie-api-developerスコープ
     * @summary イベント定義更新
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {string} definitionId イベント定義ID
     * @param {EventDefinitionPatchRequest} eventDefinitionPatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsEventDefinitionsPatch(
      developerId: number,
      applicationId: number,
      definitionId: string,
      eventDefinitionPatchRequest: EventDefinitionPatchRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EventDefinitionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsEventDefinitionsPatch(
          developerId,
          applicationId,
          definitionId,
          eventDefinitionPatchRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したアプリケーションに属するイベント定義を作成する。イベント定義は作成時は無効状態となる。 各アプリケーション毎に作成可能なイベント定義の上限は5である。  前提条件:   - 認証: safie-api-developerスコープ   - イベント定義作成数の上限5を超えないこと
     * @summary イベント定義作成
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {EventDefinitionRequest} eventDefinitionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsEventDefinitionsPost(
      xXSRFToken: string,
      developerId: number,
      applicationId: number,
      eventDefinitionRequest: EventDefinitionRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EventDefinitionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsEventDefinitionsPost(
          xXSRFToken,
          developerId,
          applicationId,
          eventDefinitionRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したディベロッパーに属するアプリケーションを列挙する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること
     * @summary アプリケーション一覧
     * @param {number} developerId ディベロッパーID
     * @param {'apikey' | 'oauth2'} [applicationAuthType] &#x60;apikey&#x60;: APIキーを利用するアプリケーションを取得 &#x60;oauth2&#x60;: OAuth2認可プロセスを通して取得したアクセストークンを利用するアプリケーションを取得
     * @param {number} [offset] 返却するリストのオフセット
     * @param {number} [limit] 返却するリストの最大件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsGet(
      developerId: number,
      applicationAuthType?: "apikey" | "oauth2",
      offset?: number,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsGet(
          developerId,
          applicationAuthType,
          offset,
          limit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したアプリケーションへの利用申請を削除し、申請前の状態にします。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること   - 指定されたデバイスが指定されたアプリケーションに対して利用申請されていること
     * @summary アプリケーション利用申請削除
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {number} deviceId デバイスID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsInstallationsDevicesDelete(
      developerId: number,
      applicationId: number,
      deviceId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsInstallationsDevicesDelete(
          developerId,
          applicationId,
          deviceId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したアプリケーションに対する複数の利用申請を一括で変更する。 更新前と更新後の値が同一の場合、値を変更することなくAPIは終了する。 指定されたデバイスの内、一つでも不正なものが存在した場合、指定した全てデバイスの更新が行われない。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること   - 指定されたデバイスが指定されたアプリケーションに対して利用申請されていること
     * @summary アプリケーション利用申請一括更新
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {ApplicationInstallationDevicesPostRequest} applicationInstallationDevicesPostRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsInstallationsDevicesPost(
      xXSRFToken: string,
      developerId: number,
      applicationId: number,
      applicationInstallationDevicesPostRequest: ApplicationInstallationDevicesPostRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsInstallationsDevicesPost(
          xXSRFToken,
          developerId,
          applicationId,
          applicationInstallationDevicesPostRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * アプリケーションを指定して利用申請を行っているデバイスの一覧を取得する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること
     * @summary アプリケーション利用一覧
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {string} [deviceModel] デバイスモデルによる絞り込み検索 (部分一致)
     * @param {string} [deviceSerial] デバイスシリアルによる絞り込み検索 (部分一致)
     * @param {Array<'created' | 'approved' | 'denied'>} [status] 承認状況による絞り込み検索。複数指定可。 &#x60;created&#x60;: 申請中、 &#x60;approved&#x60;: 承認済み、 &#x60;denied&#x60;: 拒否済み
     * @param {string} [memo] メモ欄による絞り込み検索 (部分一致)
     * @param {number} [offset] 返却するリストのオフセット
     * @param {number} [limit] 返却するリストの最大件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsInstallationsGet(
      developerId: number,
      applicationId: number,
      deviceModel?: string,
      deviceSerial?: string,
      status?: Array<"created" | "approved" | "denied">,
      memo?: string,
      offset?: number,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationInstallationList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsInstallationsGet(
          developerId,
          applicationId,
          deviceModel,
          deviceSerial,
          status,
          memo,
          offset,
          limit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したOAuth2クライアントを削除する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること   - 指定されたOAuth2クライアントが指定されたアプリケーションに属すること
     * @summary アプリケーションOAuth2クライアント削除
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {string} clientId OAuth2クライアントID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsOauth2ClientsDelete(
      developerId: number,
      applicationId: number,
      clientId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsOauth2ClientsDelete(
          developerId,
          applicationId,
          clientId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したアプリケーションに属するOAuth2クライアントの一覧を取得する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること
     * @summary アプリケーションOAuth2クライアント一覧
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {number} [offset] 返却するリストのオフセット
     * @param {number} [limit] 返却するリストの最大件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsOauth2ClientsGet(
      developerId: number,
      applicationId: number,
      offset?: number,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Oauth2ClientList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsOauth2ClientsGet(
          developerId,
          applicationId,
          offset,
          limit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したアプリケーションに属するOAuth2クライアントを作成する。 クライアントIDおよびクライアントシークレットは乱数により生成される。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して権限を持つこと   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 作成後にクライアントの数の上限2を超えないこと   - リダイレクトURL数が上限30を超えないこと   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること
     * @summary アプリケーションOAuth2クライアント作成
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {Oauth2ClientRequest} oauth2ClientRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsOauth2ClientsPost(
      xXSRFToken: string,
      developerId: number,
      applicationId: number,
      oauth2ClientRequest: Oauth2ClientRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Oauth2ClientResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsOauth2ClientsPost(
          xXSRFToken,
          developerId,
          applicationId,
          oauth2ClientRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したOAuth2クライアントの属性を更新する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること   - 指定されたOAuth2クライアントが指定されたアプリケーションに属すること   - 更新後にリダイレクトURL数が上限30を超えないこと
     * @summary アプリケーションOAuth2クライアント更新
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {string} clientId OAuth2クライアントID
     * @param {Oauth2ClientRequest} oauth2ClientRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsOauth2ClientsPut(
      developerId: number,
      applicationId: number,
      clientId: string,
      oauth2ClientRequest: Oauth2ClientRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Oauth2ClientResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsOauth2ClientsPut(
          developerId,
          applicationId,
          clientId,
          oauth2ClientRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * アプリケーションを更新します。アプリケーション名称とアプリケーション公開状況のみが変更可能です。 公開状況を非公開に変更した場合、公開済みのアプリケーションのユーザ利用申請状況はそのままに非公開となる。 (追加での利用申請ができなくなるが、すでに申請されているものには影響しない。)  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること
     * @summary アプリケーション更新
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {ApplicationPatchRequest} applicationPatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersApplicationsPatch(
      developerId: number,
      applicationId: number,
      applicationPatchRequest: ApplicationPatchRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ApplicationDetailResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersApplicationsPatch(
          developerId,
          applicationId,
          applicationPatchRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * ディベロッパーが利用可能な専用オプションの一覧を取得する。 Management Toolよりそのディベロッパーに関連付けられた専用オプションが利用可能。  前提条件:   - 認証: `safie-api-developer`スコープ
     * @summary ディベロッパー専用オプション一覧
     * @param {number} developerId ディベロッパーID
     * @param {number} [offset] 返却するリストのオフセット
     * @param {number} [limit] 返却するリストの最大件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersDedicatedOptionsGet(
      developerId: number,
      offset?: number,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeveloperDedicatedOptionList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersDedicatedOptionsGet(
          developerId,
          offset,
          limit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 指定したディベロッパーの情報を更新する。ディベロッパー表示名と、審査状況の変更のみが可能。 審査状況は`submitted`のみ指定可能。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること
     * @summary ディベロッパー更新
     * @param {number} developerId ディベロッパーID
     * @param {DeveloperUpdateRequest} developerUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersPut(
      developerId: number,
      developerUpdateRequest: DeveloperUpdateRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Developer>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersPut(
          developerId,
          developerUpdateRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * ディベロッパーに設定されているクオータ情報を取得する  前提条件:   - 認証: `safie-api-developer`スコープ
     * @summary ディベロッパークオータ情報
     * @param {number} developerId ディベロッパーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersQuotasGet(
      developerId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeveloperQuota>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersQuotasGet(
          developerId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * ディベロッパーに関連付けられたユーザ（もしくは企業）を別のユーザまたは企業に変更します。  発行済の秘密情報 (OAuth2 client secretおよびAPI Key) は変更されません。 移行前のユーザーがアクセス権を持たないことを保証するには、既存のOAuth2 client secretおよびAPI Keyを無効化してください。  オーナー委譲はSafieユーザーとその所属する企業の間のみで行うことができる。  前提条件:   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定されたSafieユーザまたは企業にディベロッパーが関連付けられていないこと   - `mail_address` が指定される場合、ディベロッパーに関連付けられた企業に指定されたユーザーが全体管理者として所属していること   - `corporation` が指定される場合、ディベロッパーに関連付けられたユーザーが指定された企業に全体管理者として所属していること
     * @summary ディベロッパーオーナー権限委譲
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {TransferRequest} transferRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devDevelopersTransferPost(
      xXSRFToken: string,
      developerId: number,
      transferRequest: TransferRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devDevelopersTransferPost(
          xXSRFToken,
          developerId,
          transferRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * ディベロッパーサイトからログアウトします。 操作に成功するとセッションクッキーが無効化されまたUAから削除されます。  前提条件   - Safieユーザとしてログイン済みであること (いずれのスコープでも)
     * @summary ディベロッパーログアウト
     * @param {string} xXSRFToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devLogoutPost(
      xXSRFToken: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Success>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.devLogoutPost(
        xXSRFToken,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * ディベロッパーを作成し、現在のログインユーザーを作成されたディベロッパーと関連付ける。 ログイン中のユーザが企業に所属する場合には、個人のSafieユーザアカウントに関連付けるか、所属する企業に関連付けるかを選択できる。  前提条件:   - 認証: `use`スコープ   - `signup_account_type=general`を指定した場合、現在のログインユーザーに対応するディベロッパーが存在しないこと   - `signup_account_type=enterprise`を指定した場合、1) 現在のログインユーザが企業に所属していること、2) ログインユーザが所属企業の全体管理者であること、3) またその所属する企業に対応するディベロッパーが存在しないこと。
     * @summary ディベロッパー登録
     * @param {string} xXSRFToken
     * @param {DeveloperRequest} developerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devSignupPost(
      xXSRFToken: string,
      developerRequest: DeveloperRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DeveloperResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.devSignupPost(
        xXSRFToken,
        developerRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * 現在ログイン中のディベロッパーサイトにおけるユーザー情報を取得する。  前提条件:   - 認証: `safie-api-developer`スコープ
     * @summary ディベロッパーユーザ情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async devUserinfoGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserinfoResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.devUserinfoGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DeveloperSiteAPIApi - factory interface
 * @export
 */
export const DeveloperSiteAPIApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DeveloperSiteAPIApiFp(configuration);
  return {
    /**
     * ディベロッパーサイトにログインする。 操作に成功するとセッションクッキーが発行され、 以降のAPI呼び出しでuse, enterprise (あれば) およびsafie-api-developerスコープでのCookie認証が行えるようになる。  otp認証が必要なユーザに対して、grant_type=passwordで認証を行った場合には、以下のような応答をステータスコード401で返すため、応答body内の`code`の値を参照して、値が`C-001-0003`であれば、otp認証が必要であると判別する。 ``` {   \"message\": \"2段階認証が必要です。\",   \"code\": \"C-001-0003\",   \"detail\": {     \"status\": \"MFA required.\"   },   \"error_description\": \"2段階認証が必要です。\" } ```
     * @summary ディベロッパーログイン
     * @param {string} xXSRFToken
     * @param {LoginRequest} loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devAuthorizePost(
      xXSRFToken: string,
      loginRequest: LoginRequest,
      options?: any,
    ): AxiosPromise<Success> {
      return localVarFp
        .devAuthorizePost(xXSRFToken, loginRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * XSRF Cookieを取得する。リクエストが成功すると、_xsrfのCookieが設定される。
     * @summary XSRF Cookie 取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devCertificateGet(options?: any): AxiosPromise<Success> {
      return localVarFp
        .devCertificateGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したディベロッパーに属するアプリケーションを作成する。 `application_auth_type`が`oauth2`の場合は、`application_plan=production`、`published=true`でなければならない。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - アプリケーション作成後にアプリケーション数上限を超えないこと（上限はディベロッパー毎に設定されている値が参照される）
     * @summary アプリケーション作成
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {ApplicationRequest} applicationRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDeveloperApplicationsPost(
      xXSRFToken: string,
      developerId: number,
      applicationRequest: ApplicationRequest,
      options?: any,
    ): AxiosPromise<ApplicationSuccess> {
      return localVarFp
        .devDeveloperApplicationsPost(
          xXSRFToken,
          developerId,
          applicationRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したアクセス制御設定を削除する。  前提条件:   - 認証: safie-api-developerスコープ
     * @summary アクセス制御設定削除
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {number} settingId アクセス制御設定のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsAccessControlsDelete(
      developerId: number,
      applicationId: number,
      settingId: number,
      options?: any,
    ): AxiosPromise<Success> {
      return localVarFp
        .devDevelopersApplicationsAccessControlsDelete(
          developerId,
          applicationId,
          settingId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * アプリケーションに関連付けられたアクセス制御設定の一覧を取得する。一覧は設定の作成日時順で得られる。  前提条件:   - 認証: safie-api-developerスコープ
     * @summary アクセス制御設定一覧取得
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsAccessControlsGet(
      developerId: number,
      applicationId: number,
      options?: any,
    ): AxiosPromise<AccessControlsListResponse> {
      return localVarFp
        .devDevelopersApplicationsAccessControlsGet(
          developerId,
          applicationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したアクセス制御設定を更新する。  前提条件:   - 認証: safie-api-developerスコープ
     * @summary アクセス制御設定更新
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {number} settingId アクセス制御設定のID
     * @param {AccessControlsPatchRequest} accessControlsPatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsAccessControlsPatch(
      developerId: number,
      applicationId: number,
      settingId: number,
      accessControlsPatchRequest: AccessControlsPatchRequest,
      options?: any,
    ): AxiosPromise<AccessControlSetting> {
      return localVarFp
        .devDevelopersApplicationsAccessControlsPatch(
          developerId,
          applicationId,
          settingId,
          accessControlsPatchRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したアプリケーションに属するアクセス制御設定を作成する。 アクセス制御設定が一つ以上存在するアプリケーションでは、アクセス制御設定のいずれかで許可されたソースIPアドレスからのアクセスのみがSafie APIで許可される。 アクセス制御設定が一つも存在しないアプリケーションでは、全てのソースIPアドレスからSafie APIアクセスが許可される（制限なし）。 各アプリケーション毎に作成可能なアクセス制御設定の上限は5である。  前提条件:   - 認証: safie-api-developerスコープ   - アクセス制御設定数が上限5を超えないこと
     * @summary アクセス制御設定作成
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {AccessControlsPostRequest} accessControlsPostRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsAccessControlsPost(
      xXSRFToken: string,
      developerId: number,
      applicationId: number,
      accessControlsPostRequest: AccessControlsPostRequest,
      options?: any,
    ): AxiosPromise<AccessControlSetting> {
      return localVarFp
        .devDevelopersApplicationsAccessControlsPost(
          xXSRFToken,
          developerId,
          applicationId,
          accessControlsPostRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したAPIキーを削除する。指定したAPIキーによるアクセスは無効化される。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定したAPIキーが指定したアプリケーションに属すること
     * @summary アプリケーションAPIキー削除
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {number} keyId APIキーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsApikeysDelete(
      developerId: number,
      applicationId: number,
      keyId: number,
      options?: any,
    ): AxiosPromise<Success> {
      return localVarFp
        .devDevelopersApplicationsApikeysDelete(
          developerId,
          applicationId,
          keyId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したアプリケーションに属するAPIキーを作成する。 作成されたAPIキーのアクセス範囲は、アプリケーションの`application_plan`及び アプリケーションで定義されたAPI利用枠（`apis`）、アクセス権限（`permissions`）によって異なる。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたDeveloperに属すること   - 作成後にAPIキー数上限2を超えないこと   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること
     * @summary アプリケーションAPIキー作成
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {ApikeyRequest} apikeyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsApikeysPost(
      xXSRFToken: string,
      developerId: number,
      applicationId: number,
      apikeyRequest: ApikeyRequest,
      options?: any,
    ): AxiosPromise<ApikeyResponse> {
      return localVarFp
        .devDevelopersApplicationsApikeysPost(
          xXSRFToken,
          developerId,
          applicationId,
          apikeyRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したアプリケーションの詳細情報を取得する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定したアプリケーションが指定したディベロッパーに属すること
     * @summary アプリケーション詳細
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsApplicationIdGet(
      developerId: number,
      applicationId: number,
      options?: any,
    ): AxiosPromise<ApplicationDetailResponse> {
      return localVarFp
        .devDevelopersApplicationsApplicationIdGet(
          developerId,
          applicationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したアプリケーションを削除する。  アプリケーションを削除したとき関連するOAuth2 ClientおよびAPI Keyは削除され、アプリケーションにより取得されたデバイスへの権限がすべて無効化されます。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること
     * @summary アプリケーション削除
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsDelete(
      developerId: number,
      applicationId: number,
      options?: any,
    ): AxiosPromise<Success> {
      return localVarFp
        .devDevelopersApplicationsDelete(developerId, applicationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * アプリケーションに関連付けられたイベント定義一覧を取得する。  前提条件:   - 認証: safie-api-developerスコープ
     * @summary イベント定義一覧取得
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsEventDefinitionsGet(
      developerId: number,
      applicationId: number,
      options?: any,
    ): AxiosPromise<EventDefinitionListResponse> {
      return localVarFp
        .devDevelopersApplicationsEventDefinitionsGet(
          developerId,
          applicationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したイベント定義のイベント表示名、カラーコードおよび有効・無効状態を更新する。  前提条件:   - 認証: safie-api-developerスコープ
     * @summary イベント定義更新
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {string} definitionId イベント定義ID
     * @param {EventDefinitionPatchRequest} eventDefinitionPatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsEventDefinitionsPatch(
      developerId: number,
      applicationId: number,
      definitionId: string,
      eventDefinitionPatchRequest: EventDefinitionPatchRequest,
      options?: any,
    ): AxiosPromise<EventDefinitionResponse> {
      return localVarFp
        .devDevelopersApplicationsEventDefinitionsPatch(
          developerId,
          applicationId,
          definitionId,
          eventDefinitionPatchRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したアプリケーションに属するイベント定義を作成する。イベント定義は作成時は無効状態となる。 各アプリケーション毎に作成可能なイベント定義の上限は5である。  前提条件:   - 認証: safie-api-developerスコープ   - イベント定義作成数の上限5を超えないこと
     * @summary イベント定義作成
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {EventDefinitionRequest} eventDefinitionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsEventDefinitionsPost(
      xXSRFToken: string,
      developerId: number,
      applicationId: number,
      eventDefinitionRequest: EventDefinitionRequest,
      options?: any,
    ): AxiosPromise<EventDefinitionResponse> {
      return localVarFp
        .devDevelopersApplicationsEventDefinitionsPost(
          xXSRFToken,
          developerId,
          applicationId,
          eventDefinitionRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したディベロッパーに属するアプリケーションを列挙する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること
     * @summary アプリケーション一覧
     * @param {number} developerId ディベロッパーID
     * @param {'apikey' | 'oauth2'} [applicationAuthType] &#x60;apikey&#x60;: APIキーを利用するアプリケーションを取得 &#x60;oauth2&#x60;: OAuth2認可プロセスを通して取得したアクセストークンを利用するアプリケーションを取得
     * @param {number} [offset] 返却するリストのオフセット
     * @param {number} [limit] 返却するリストの最大件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsGet(
      developerId: number,
      applicationAuthType?: "apikey" | "oauth2",
      offset?: number,
      limit?: number,
      options?: any,
    ): AxiosPromise<ApplicationList> {
      return localVarFp
        .devDevelopersApplicationsGet(
          developerId,
          applicationAuthType,
          offset,
          limit,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したアプリケーションへの利用申請を削除し、申請前の状態にします。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること   - 指定されたデバイスが指定されたアプリケーションに対して利用申請されていること
     * @summary アプリケーション利用申請削除
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {number} deviceId デバイスID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsInstallationsDevicesDelete(
      developerId: number,
      applicationId: number,
      deviceId: number,
      options?: any,
    ): AxiosPromise<Success> {
      return localVarFp
        .devDevelopersApplicationsInstallationsDevicesDelete(
          developerId,
          applicationId,
          deviceId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したアプリケーションに対する複数の利用申請を一括で変更する。 更新前と更新後の値が同一の場合、値を変更することなくAPIは終了する。 指定されたデバイスの内、一つでも不正なものが存在した場合、指定した全てデバイスの更新が行われない。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること   - 指定されたデバイスが指定されたアプリケーションに対して利用申請されていること
     * @summary アプリケーション利用申請一括更新
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {ApplicationInstallationDevicesPostRequest} applicationInstallationDevicesPostRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsInstallationsDevicesPost(
      xXSRFToken: string,
      developerId: number,
      applicationId: number,
      applicationInstallationDevicesPostRequest: ApplicationInstallationDevicesPostRequest,
      options?: any,
    ): AxiosPromise<Success> {
      return localVarFp
        .devDevelopersApplicationsInstallationsDevicesPost(
          xXSRFToken,
          developerId,
          applicationId,
          applicationInstallationDevicesPostRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * アプリケーションを指定して利用申請を行っているデバイスの一覧を取得する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること
     * @summary アプリケーション利用一覧
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {string} [deviceModel] デバイスモデルによる絞り込み検索 (部分一致)
     * @param {string} [deviceSerial] デバイスシリアルによる絞り込み検索 (部分一致)
     * @param {Array<'created' | 'approved' | 'denied'>} [status] 承認状況による絞り込み検索。複数指定可。 &#x60;created&#x60;: 申請中、 &#x60;approved&#x60;: 承認済み、 &#x60;denied&#x60;: 拒否済み
     * @param {string} [memo] メモ欄による絞り込み検索 (部分一致)
     * @param {number} [offset] 返却するリストのオフセット
     * @param {number} [limit] 返却するリストの最大件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsInstallationsGet(
      developerId: number,
      applicationId: number,
      deviceModel?: string,
      deviceSerial?: string,
      status?: Array<"created" | "approved" | "denied">,
      memo?: string,
      offset?: number,
      limit?: number,
      options?: any,
    ): AxiosPromise<ApplicationInstallationList> {
      return localVarFp
        .devDevelopersApplicationsInstallationsGet(
          developerId,
          applicationId,
          deviceModel,
          deviceSerial,
          status,
          memo,
          offset,
          limit,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したOAuth2クライアントを削除する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること   - 指定されたOAuth2クライアントが指定されたアプリケーションに属すること
     * @summary アプリケーションOAuth2クライアント削除
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {string} clientId OAuth2クライアントID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsOauth2ClientsDelete(
      developerId: number,
      applicationId: number,
      clientId: string,
      options?: any,
    ): AxiosPromise<Success> {
      return localVarFp
        .devDevelopersApplicationsOauth2ClientsDelete(
          developerId,
          applicationId,
          clientId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したアプリケーションに属するOAuth2クライアントの一覧を取得する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること
     * @summary アプリケーションOAuth2クライアント一覧
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {number} [offset] 返却するリストのオフセット
     * @param {number} [limit] 返却するリストの最大件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsOauth2ClientsGet(
      developerId: number,
      applicationId: number,
      offset?: number,
      limit?: number,
      options?: any,
    ): AxiosPromise<Oauth2ClientList> {
      return localVarFp
        .devDevelopersApplicationsOauth2ClientsGet(
          developerId,
          applicationId,
          offset,
          limit,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したアプリケーションに属するOAuth2クライアントを作成する。 クライアントIDおよびクライアントシークレットは乱数により生成される。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して権限を持つこと   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 作成後にクライアントの数の上限2を超えないこと   - リダイレクトURL数が上限30を超えないこと   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること
     * @summary アプリケーションOAuth2クライアント作成
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {Oauth2ClientRequest} oauth2ClientRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsOauth2ClientsPost(
      xXSRFToken: string,
      developerId: number,
      applicationId: number,
      oauth2ClientRequest: Oauth2ClientRequest,
      options?: any,
    ): AxiosPromise<Oauth2ClientResponse> {
      return localVarFp
        .devDevelopersApplicationsOauth2ClientsPost(
          xXSRFToken,
          developerId,
          applicationId,
          oauth2ClientRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したOAuth2クライアントの属性を更新する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること   - 指定されたOAuth2クライアントが指定されたアプリケーションに属すること   - 更新後にリダイレクトURL数が上限30を超えないこと
     * @summary アプリケーションOAuth2クライアント更新
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {string} clientId OAuth2クライアントID
     * @param {Oauth2ClientRequest} oauth2ClientRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsOauth2ClientsPut(
      developerId: number,
      applicationId: number,
      clientId: string,
      oauth2ClientRequest: Oauth2ClientRequest,
      options?: any,
    ): AxiosPromise<Oauth2ClientResponse> {
      return localVarFp
        .devDevelopersApplicationsOauth2ClientsPut(
          developerId,
          applicationId,
          clientId,
          oauth2ClientRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * アプリケーションを更新します。アプリケーション名称とアプリケーション公開状況のみが変更可能です。 公開状況を非公開に変更した場合、公開済みのアプリケーションのユーザ利用申請状況はそのままに非公開となる。 (追加での利用申請ができなくなるが、すでに申請されているものには影響しない。)  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること
     * @summary アプリケーション更新
     * @param {number} developerId ディベロッパーID
     * @param {number} applicationId アプリケーションID
     * @param {ApplicationPatchRequest} applicationPatchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersApplicationsPatch(
      developerId: number,
      applicationId: number,
      applicationPatchRequest: ApplicationPatchRequest,
      options?: any,
    ): AxiosPromise<ApplicationDetailResponse> {
      return localVarFp
        .devDevelopersApplicationsPatch(
          developerId,
          applicationId,
          applicationPatchRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * ディベロッパーが利用可能な専用オプションの一覧を取得する。 Management Toolよりそのディベロッパーに関連付けられた専用オプションが利用可能。  前提条件:   - 認証: `safie-api-developer`スコープ
     * @summary ディベロッパー専用オプション一覧
     * @param {number} developerId ディベロッパーID
     * @param {number} [offset] 返却するリストのオフセット
     * @param {number} [limit] 返却するリストの最大件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersDedicatedOptionsGet(
      developerId: number,
      offset?: number,
      limit?: number,
      options?: any,
    ): AxiosPromise<DeveloperDedicatedOptionList> {
      return localVarFp
        .devDevelopersDedicatedOptionsGet(developerId, offset, limit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 指定したディベロッパーの情報を更新する。ディベロッパー表示名と、審査状況の変更のみが可能。 審査状況は`submitted`のみ指定可能。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること
     * @summary ディベロッパー更新
     * @param {number} developerId ディベロッパーID
     * @param {DeveloperUpdateRequest} developerUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersPut(
      developerId: number,
      developerUpdateRequest: DeveloperUpdateRequest,
      options?: any,
    ): AxiosPromise<Developer> {
      return localVarFp
        .devDevelopersPut(developerId, developerUpdateRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ディベロッパーに設定されているクオータ情報を取得する  前提条件:   - 認証: `safie-api-developer`スコープ
     * @summary ディベロッパークオータ情報
     * @param {number} developerId ディベロッパーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersQuotasGet(
      developerId: number,
      options?: any,
    ): AxiosPromise<DeveloperQuota> {
      return localVarFp
        .devDevelopersQuotasGet(developerId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ディベロッパーに関連付けられたユーザ（もしくは企業）を別のユーザまたは企業に変更します。  発行済の秘密情報 (OAuth2 client secretおよびAPI Key) は変更されません。 移行前のユーザーがアクセス権を持たないことを保証するには、既存のOAuth2 client secretおよびAPI Keyを無効化してください。  オーナー委譲はSafieユーザーとその所属する企業の間のみで行うことができる。  前提条件:   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定されたSafieユーザまたは企業にディベロッパーが関連付けられていないこと   - `mail_address` が指定される場合、ディベロッパーに関連付けられた企業に指定されたユーザーが全体管理者として所属していること   - `corporation` が指定される場合、ディベロッパーに関連付けられたユーザーが指定された企業に全体管理者として所属していること
     * @summary ディベロッパーオーナー権限委譲
     * @param {string} xXSRFToken
     * @param {number} developerId ディベロッパーID
     * @param {TransferRequest} transferRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devDevelopersTransferPost(
      xXSRFToken: string,
      developerId: number,
      transferRequest: TransferRequest,
      options?: any,
    ): AxiosPromise<Success> {
      return localVarFp
        .devDevelopersTransferPost(
          xXSRFToken,
          developerId,
          transferRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * ディベロッパーサイトからログアウトします。 操作に成功するとセッションクッキーが無効化されまたUAから削除されます。  前提条件   - Safieユーザとしてログイン済みであること (いずれのスコープでも)
     * @summary ディベロッパーログアウト
     * @param {string} xXSRFToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devLogoutPost(xXSRFToken: string, options?: any): AxiosPromise<Success> {
      return localVarFp
        .devLogoutPost(xXSRFToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * ディベロッパーを作成し、現在のログインユーザーを作成されたディベロッパーと関連付ける。 ログイン中のユーザが企業に所属する場合には、個人のSafieユーザアカウントに関連付けるか、所属する企業に関連付けるかを選択できる。  前提条件:   - 認証: `use`スコープ   - `signup_account_type=general`を指定した場合、現在のログインユーザーに対応するディベロッパーが存在しないこと   - `signup_account_type=enterprise`を指定した場合、1) 現在のログインユーザが企業に所属していること、2) ログインユーザが所属企業の全体管理者であること、3) またその所属する企業に対応するディベロッパーが存在しないこと。
     * @summary ディベロッパー登録
     * @param {string} xXSRFToken
     * @param {DeveloperRequest} developerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devSignupPost(
      xXSRFToken: string,
      developerRequest: DeveloperRequest,
      options?: any,
    ): AxiosPromise<DeveloperResponse> {
      return localVarFp
        .devSignupPost(xXSRFToken, developerRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 現在ログイン中のディベロッパーサイトにおけるユーザー情報を取得する。  前提条件:   - 認証: `safie-api-developer`スコープ
     * @summary ディベロッパーユーザ情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    devUserinfoGet(options?: any): AxiosPromise<UserinfoResponse> {
      return localVarFp
        .devUserinfoGet(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DeveloperSiteAPIApi - interface
 * @export
 * @interface DeveloperSiteAPIApi
 */
export interface DeveloperSiteAPIApiInterface {
  /**
   * ディベロッパーサイトにログインする。 操作に成功するとセッションクッキーが発行され、 以降のAPI呼び出しでuse, enterprise (あれば) およびsafie-api-developerスコープでのCookie認証が行えるようになる。  otp認証が必要なユーザに対して、grant_type=passwordで認証を行った場合には、以下のような応答をステータスコード401で返すため、応答body内の`code`の値を参照して、値が`C-001-0003`であれば、otp認証が必要であると判別する。 ``` {   \"message\": \"2段階認証が必要です。\",   \"code\": \"C-001-0003\",   \"detail\": {     \"status\": \"MFA required.\"   },   \"error_description\": \"2段階認証が必要です。\" } ```
   * @summary ディベロッパーログイン
   * @param {string} xXSRFToken
   * @param {LoginRequest} loginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devAuthorizePost(
    xXSRFToken: string,
    loginRequest: LoginRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Success>;

  /**
   * XSRF Cookieを取得する。リクエストが成功すると、_xsrfのCookieが設定される。
   * @summary XSRF Cookie 取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devCertificateGet(options?: AxiosRequestConfig): AxiosPromise<Success>;

  /**
   * 指定したディベロッパーに属するアプリケーションを作成する。 `application_auth_type`が`oauth2`の場合は、`application_plan=production`、`published=true`でなければならない。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - アプリケーション作成後にアプリケーション数上限を超えないこと（上限はディベロッパー毎に設定されている値が参照される）
   * @summary アプリケーション作成
   * @param {string} xXSRFToken
   * @param {number} developerId ディベロッパーID
   * @param {ApplicationRequest} applicationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDeveloperApplicationsPost(
    xXSRFToken: string,
    developerId: number,
    applicationRequest: ApplicationRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<ApplicationSuccess>;

  /**
   * 指定したアクセス制御設定を削除する。  前提条件:   - 認証: safie-api-developerスコープ
   * @summary アクセス制御設定削除
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {number} settingId アクセス制御設定のID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsAccessControlsDelete(
    developerId: number,
    applicationId: number,
    settingId: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Success>;

  /**
   * アプリケーションに関連付けられたアクセス制御設定の一覧を取得する。一覧は設定の作成日時順で得られる。  前提条件:   - 認証: safie-api-developerスコープ
   * @summary アクセス制御設定一覧取得
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsAccessControlsGet(
    developerId: number,
    applicationId: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<AccessControlsListResponse>;

  /**
   * 指定したアクセス制御設定を更新する。  前提条件:   - 認証: safie-api-developerスコープ
   * @summary アクセス制御設定更新
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {number} settingId アクセス制御設定のID
   * @param {AccessControlsPatchRequest} accessControlsPatchRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsAccessControlsPatch(
    developerId: number,
    applicationId: number,
    settingId: number,
    accessControlsPatchRequest: AccessControlsPatchRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<AccessControlSetting>;

  /**
   * 指定したアプリケーションに属するアクセス制御設定を作成する。 アクセス制御設定が一つ以上存在するアプリケーションでは、アクセス制御設定のいずれかで許可されたソースIPアドレスからのアクセスのみがSafie APIで許可される。 アクセス制御設定が一つも存在しないアプリケーションでは、全てのソースIPアドレスからSafie APIアクセスが許可される（制限なし）。 各アプリケーション毎に作成可能なアクセス制御設定の上限は5である。  前提条件:   - 認証: safie-api-developerスコープ   - アクセス制御設定数が上限5を超えないこと
   * @summary アクセス制御設定作成
   * @param {string} xXSRFToken
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {AccessControlsPostRequest} accessControlsPostRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsAccessControlsPost(
    xXSRFToken: string,
    developerId: number,
    applicationId: number,
    accessControlsPostRequest: AccessControlsPostRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<AccessControlSetting>;

  /**
   * 指定したAPIキーを削除する。指定したAPIキーによるアクセスは無効化される。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定したAPIキーが指定したアプリケーションに属すること
   * @summary アプリケーションAPIキー削除
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {number} keyId APIキーID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsApikeysDelete(
    developerId: number,
    applicationId: number,
    keyId: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Success>;

  /**
   * 指定したアプリケーションに属するAPIキーを作成する。 作成されたAPIキーのアクセス範囲は、アプリケーションの`application_plan`及び アプリケーションで定義されたAPI利用枠（`apis`）、アクセス権限（`permissions`）によって異なる。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたDeveloperに属すること   - 作成後にAPIキー数上限2を超えないこと   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること
   * @summary アプリケーションAPIキー作成
   * @param {string} xXSRFToken
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {ApikeyRequest} apikeyRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsApikeysPost(
    xXSRFToken: string,
    developerId: number,
    applicationId: number,
    apikeyRequest: ApikeyRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<ApikeyResponse>;

  /**
   * 指定したアプリケーションの詳細情報を取得する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定したアプリケーションが指定したディベロッパーに属すること
   * @summary アプリケーション詳細
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsApplicationIdGet(
    developerId: number,
    applicationId: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<ApplicationDetailResponse>;

  /**
   * 指定したアプリケーションを削除する。  アプリケーションを削除したとき関連するOAuth2 ClientおよびAPI Keyは削除され、アプリケーションにより取得されたデバイスへの権限がすべて無効化されます。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること
   * @summary アプリケーション削除
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsDelete(
    developerId: number,
    applicationId: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Success>;

  /**
   * アプリケーションに関連付けられたイベント定義一覧を取得する。  前提条件:   - 認証: safie-api-developerスコープ
   * @summary イベント定義一覧取得
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsEventDefinitionsGet(
    developerId: number,
    applicationId: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<EventDefinitionListResponse>;

  /**
   * 指定したイベント定義のイベント表示名、カラーコードおよび有効・無効状態を更新する。  前提条件:   - 認証: safie-api-developerスコープ
   * @summary イベント定義更新
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {string} definitionId イベント定義ID
   * @param {EventDefinitionPatchRequest} eventDefinitionPatchRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsEventDefinitionsPatch(
    developerId: number,
    applicationId: number,
    definitionId: string,
    eventDefinitionPatchRequest: EventDefinitionPatchRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<EventDefinitionResponse>;

  /**
   * 指定したアプリケーションに属するイベント定義を作成する。イベント定義は作成時は無効状態となる。 各アプリケーション毎に作成可能なイベント定義の上限は5である。  前提条件:   - 認証: safie-api-developerスコープ   - イベント定義作成数の上限5を超えないこと
   * @summary イベント定義作成
   * @param {string} xXSRFToken
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {EventDefinitionRequest} eventDefinitionRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsEventDefinitionsPost(
    xXSRFToken: string,
    developerId: number,
    applicationId: number,
    eventDefinitionRequest: EventDefinitionRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<EventDefinitionResponse>;

  /**
   * 指定したディベロッパーに属するアプリケーションを列挙する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること
   * @summary アプリケーション一覧
   * @param {number} developerId ディベロッパーID
   * @param {'apikey' | 'oauth2'} [applicationAuthType] &#x60;apikey&#x60;: APIキーを利用するアプリケーションを取得 &#x60;oauth2&#x60;: OAuth2認可プロセスを通して取得したアクセストークンを利用するアプリケーションを取得
   * @param {number} [offset] 返却するリストのオフセット
   * @param {number} [limit] 返却するリストの最大件数
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsGet(
    developerId: number,
    applicationAuthType?: "apikey" | "oauth2",
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<ApplicationList>;

  /**
   * 指定したアプリケーションへの利用申請を削除し、申請前の状態にします。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること   - 指定されたデバイスが指定されたアプリケーションに対して利用申請されていること
   * @summary アプリケーション利用申請削除
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {number} deviceId デバイスID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsInstallationsDevicesDelete(
    developerId: number,
    applicationId: number,
    deviceId: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Success>;

  /**
   * 指定したアプリケーションに対する複数の利用申請を一括で変更する。 更新前と更新後の値が同一の場合、値を変更することなくAPIは終了する。 指定されたデバイスの内、一つでも不正なものが存在した場合、指定した全てデバイスの更新が行われない。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること   - 指定されたデバイスが指定されたアプリケーションに対して利用申請されていること
   * @summary アプリケーション利用申請一括更新
   * @param {string} xXSRFToken
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {ApplicationInstallationDevicesPostRequest} applicationInstallationDevicesPostRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsInstallationsDevicesPost(
    xXSRFToken: string,
    developerId: number,
    applicationId: number,
    applicationInstallationDevicesPostRequest: ApplicationInstallationDevicesPostRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Success>;

  /**
   * アプリケーションを指定して利用申請を行っているデバイスの一覧を取得する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること
   * @summary アプリケーション利用一覧
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {string} [deviceModel] デバイスモデルによる絞り込み検索 (部分一致)
   * @param {string} [deviceSerial] デバイスシリアルによる絞り込み検索 (部分一致)
   * @param {Array<'created' | 'approved' | 'denied'>} [status] 承認状況による絞り込み検索。複数指定可。 &#x60;created&#x60;: 申請中、 &#x60;approved&#x60;: 承認済み、 &#x60;denied&#x60;: 拒否済み
   * @param {string} [memo] メモ欄による絞り込み検索 (部分一致)
   * @param {number} [offset] 返却するリストのオフセット
   * @param {number} [limit] 返却するリストの最大件数
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsInstallationsGet(
    developerId: number,
    applicationId: number,
    deviceModel?: string,
    deviceSerial?: string,
    status?: Array<"created" | "approved" | "denied">,
    memo?: string,
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<ApplicationInstallationList>;

  /**
   * 指定したOAuth2クライアントを削除する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること   - 指定されたOAuth2クライアントが指定されたアプリケーションに属すること
   * @summary アプリケーションOAuth2クライアント削除
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {string} clientId OAuth2クライアントID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsOauth2ClientsDelete(
    developerId: number,
    applicationId: number,
    clientId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Success>;

  /**
   * 指定したアプリケーションに属するOAuth2クライアントの一覧を取得する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること
   * @summary アプリケーションOAuth2クライアント一覧
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {number} [offset] 返却するリストのオフセット
   * @param {number} [limit] 返却するリストの最大件数
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsOauth2ClientsGet(
    developerId: number,
    applicationId: number,
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Oauth2ClientList>;

  /**
   * 指定したアプリケーションに属するOAuth2クライアントを作成する。 クライアントIDおよびクライアントシークレットは乱数により生成される。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して権限を持つこと   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 作成後にクライアントの数の上限2を超えないこと   - リダイレクトURL数が上限30を超えないこと   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること
   * @summary アプリケーションOAuth2クライアント作成
   * @param {string} xXSRFToken
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {Oauth2ClientRequest} oauth2ClientRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsOauth2ClientsPost(
    xXSRFToken: string,
    developerId: number,
    applicationId: number,
    oauth2ClientRequest: Oauth2ClientRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Oauth2ClientResponse>;

  /**
   * 指定したOAuth2クライアントの属性を更新する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること   - 指定されたOAuth2クライアントが指定されたアプリケーションに属すること   - 更新後にリダイレクトURL数が上限30を超えないこと
   * @summary アプリケーションOAuth2クライアント更新
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {string} clientId OAuth2クライアントID
   * @param {Oauth2ClientRequest} oauth2ClientRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsOauth2ClientsPut(
    developerId: number,
    applicationId: number,
    clientId: string,
    oauth2ClientRequest: Oauth2ClientRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Oauth2ClientResponse>;

  /**
   * アプリケーションを更新します。アプリケーション名称とアプリケーション公開状況のみが変更可能です。 公開状況を非公開に変更した場合、公開済みのアプリケーションのユーザ利用申請状況はそのままに非公開となる。 (追加での利用申請ができなくなるが、すでに申請されているものには影響しない。)  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること
   * @summary アプリケーション更新
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {ApplicationPatchRequest} applicationPatchRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersApplicationsPatch(
    developerId: number,
    applicationId: number,
    applicationPatchRequest: ApplicationPatchRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<ApplicationDetailResponse>;

  /**
   * ディベロッパーが利用可能な専用オプションの一覧を取得する。 Management Toolよりそのディベロッパーに関連付けられた専用オプションが利用可能。  前提条件:   - 認証: `safie-api-developer`スコープ
   * @summary ディベロッパー専用オプション一覧
   * @param {number} developerId ディベロッパーID
   * @param {number} [offset] 返却するリストのオフセット
   * @param {number} [limit] 返却するリストの最大件数
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersDedicatedOptionsGet(
    developerId: number,
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<DeveloperDedicatedOptionList>;

  /**
   * 指定したディベロッパーの情報を更新する。ディベロッパー表示名と、審査状況の変更のみが可能。 審査状況は`submitted`のみ指定可能。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること
   * @summary ディベロッパー更新
   * @param {number} developerId ディベロッパーID
   * @param {DeveloperUpdateRequest} developerUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersPut(
    developerId: number,
    developerUpdateRequest: DeveloperUpdateRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Developer>;

  /**
   * ディベロッパーに設定されているクオータ情報を取得する  前提条件:   - 認証: `safie-api-developer`スコープ
   * @summary ディベロッパークオータ情報
   * @param {number} developerId ディベロッパーID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersQuotasGet(
    developerId: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<DeveloperQuota>;

  /**
   * ディベロッパーに関連付けられたユーザ（もしくは企業）を別のユーザまたは企業に変更します。  発行済の秘密情報 (OAuth2 client secretおよびAPI Key) は変更されません。 移行前のユーザーがアクセス権を持たないことを保証するには、既存のOAuth2 client secretおよびAPI Keyを無効化してください。  オーナー委譲はSafieユーザーとその所属する企業の間のみで行うことができる。  前提条件:   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定されたSafieユーザまたは企業にディベロッパーが関連付けられていないこと   - `mail_address` が指定される場合、ディベロッパーに関連付けられた企業に指定されたユーザーが全体管理者として所属していること   - `corporation` が指定される場合、ディベロッパーに関連付けられたユーザーが指定された企業に全体管理者として所属していること
   * @summary ディベロッパーオーナー権限委譲
   * @param {string} xXSRFToken
   * @param {number} developerId ディベロッパーID
   * @param {TransferRequest} transferRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devDevelopersTransferPost(
    xXSRFToken: string,
    developerId: number,
    transferRequest: TransferRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Success>;

  /**
   * ディベロッパーサイトからログアウトします。 操作に成功するとセッションクッキーが無効化されまたUAから削除されます。  前提条件   - Safieユーザとしてログイン済みであること (いずれのスコープでも)
   * @summary ディベロッパーログアウト
   * @param {string} xXSRFToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devLogoutPost(
    xXSRFToken: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Success>;

  /**
   * ディベロッパーを作成し、現在のログインユーザーを作成されたディベロッパーと関連付ける。 ログイン中のユーザが企業に所属する場合には、個人のSafieユーザアカウントに関連付けるか、所属する企業に関連付けるかを選択できる。  前提条件:   - 認証: `use`スコープ   - `signup_account_type=general`を指定した場合、現在のログインユーザーに対応するディベロッパーが存在しないこと   - `signup_account_type=enterprise`を指定した場合、1) 現在のログインユーザが企業に所属していること、2) ログインユーザが所属企業の全体管理者であること、3) またその所属する企業に対応するディベロッパーが存在しないこと。
   * @summary ディベロッパー登録
   * @param {string} xXSRFToken
   * @param {DeveloperRequest} developerRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devSignupPost(
    xXSRFToken: string,
    developerRequest: DeveloperRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<DeveloperResponse>;

  /**
   * 現在ログイン中のディベロッパーサイトにおけるユーザー情報を取得する。  前提条件:   - 認証: `safie-api-developer`スコープ
   * @summary ディベロッパーユーザ情報
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApiInterface
   */
  devUserinfoGet(options?: AxiosRequestConfig): AxiosPromise<UserinfoResponse>;
}

/**
 * DeveloperSiteAPIApi - object-oriented interface
 * @export
 * @class DeveloperSiteAPIApi
 * @extends {BaseAPI}
 */
export class DeveloperSiteAPIApi
  extends BaseAPI
  implements DeveloperSiteAPIApiInterface
{
  /**
   * ディベロッパーサイトにログインする。 操作に成功するとセッションクッキーが発行され、 以降のAPI呼び出しでuse, enterprise (あれば) およびsafie-api-developerスコープでのCookie認証が行えるようになる。  otp認証が必要なユーザに対して、grant_type=passwordで認証を行った場合には、以下のような応答をステータスコード401で返すため、応答body内の`code`の値を参照して、値が`C-001-0003`であれば、otp認証が必要であると判別する。 ``` {   \"message\": \"2段階認証が必要です。\",   \"code\": \"C-001-0003\",   \"detail\": {     \"status\": \"MFA required.\"   },   \"error_description\": \"2段階認証が必要です。\" } ```
   * @summary ディベロッパーログイン
   * @param {string} xXSRFToken
   * @param {LoginRequest} loginRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devAuthorizePost(
    xXSRFToken: string,
    loginRequest: LoginRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devAuthorizePost(xXSRFToken, loginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * XSRF Cookieを取得する。リクエストが成功すると、_xsrfのCookieが設定される。
   * @summary XSRF Cookie 取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devCertificateGet(options?: AxiosRequestConfig) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devCertificateGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したディベロッパーに属するアプリケーションを作成する。 `application_auth_type`が`oauth2`の場合は、`application_plan=production`、`published=true`でなければならない。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - アプリケーション作成後にアプリケーション数上限を超えないこと（上限はディベロッパー毎に設定されている値が参照される）
   * @summary アプリケーション作成
   * @param {string} xXSRFToken
   * @param {number} developerId ディベロッパーID
   * @param {ApplicationRequest} applicationRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDeveloperApplicationsPost(
    xXSRFToken: string,
    developerId: number,
    applicationRequest: ApplicationRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDeveloperApplicationsPost(
        xXSRFToken,
        developerId,
        applicationRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したアクセス制御設定を削除する。  前提条件:   - 認証: safie-api-developerスコープ
   * @summary アクセス制御設定削除
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {number} settingId アクセス制御設定のID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsAccessControlsDelete(
    developerId: number,
    applicationId: number,
    settingId: number,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsAccessControlsDelete(
        developerId,
        applicationId,
        settingId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * アプリケーションに関連付けられたアクセス制御設定の一覧を取得する。一覧は設定の作成日時順で得られる。  前提条件:   - 認証: safie-api-developerスコープ
   * @summary アクセス制御設定一覧取得
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsAccessControlsGet(
    developerId: number,
    applicationId: number,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsAccessControlsGet(
        developerId,
        applicationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したアクセス制御設定を更新する。  前提条件:   - 認証: safie-api-developerスコープ
   * @summary アクセス制御設定更新
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {number} settingId アクセス制御設定のID
   * @param {AccessControlsPatchRequest} accessControlsPatchRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsAccessControlsPatch(
    developerId: number,
    applicationId: number,
    settingId: number,
    accessControlsPatchRequest: AccessControlsPatchRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsAccessControlsPatch(
        developerId,
        applicationId,
        settingId,
        accessControlsPatchRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したアプリケーションに属するアクセス制御設定を作成する。 アクセス制御設定が一つ以上存在するアプリケーションでは、アクセス制御設定のいずれかで許可されたソースIPアドレスからのアクセスのみがSafie APIで許可される。 アクセス制御設定が一つも存在しないアプリケーションでは、全てのソースIPアドレスからSafie APIアクセスが許可される（制限なし）。 各アプリケーション毎に作成可能なアクセス制御設定の上限は5である。  前提条件:   - 認証: safie-api-developerスコープ   - アクセス制御設定数が上限5を超えないこと
   * @summary アクセス制御設定作成
   * @param {string} xXSRFToken
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {AccessControlsPostRequest} accessControlsPostRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsAccessControlsPost(
    xXSRFToken: string,
    developerId: number,
    applicationId: number,
    accessControlsPostRequest: AccessControlsPostRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsAccessControlsPost(
        xXSRFToken,
        developerId,
        applicationId,
        accessControlsPostRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したAPIキーを削除する。指定したAPIキーによるアクセスは無効化される。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定したAPIキーが指定したアプリケーションに属すること
   * @summary アプリケーションAPIキー削除
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {number} keyId APIキーID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsApikeysDelete(
    developerId: number,
    applicationId: number,
    keyId: number,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsApikeysDelete(
        developerId,
        applicationId,
        keyId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したアプリケーションに属するAPIキーを作成する。 作成されたAPIキーのアクセス範囲は、アプリケーションの`application_plan`及び アプリケーションで定義されたAPI利用枠（`apis`）、アクセス権限（`permissions`）によって異なる。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたDeveloperに属すること   - 作成後にAPIキー数上限2を超えないこと   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること
   * @summary アプリケーションAPIキー作成
   * @param {string} xXSRFToken
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {ApikeyRequest} apikeyRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsApikeysPost(
    xXSRFToken: string,
    developerId: number,
    applicationId: number,
    apikeyRequest: ApikeyRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsApikeysPost(
        xXSRFToken,
        developerId,
        applicationId,
        apikeyRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したアプリケーションの詳細情報を取得する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定したアプリケーションが指定したディベロッパーに属すること
   * @summary アプリケーション詳細
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsApplicationIdGet(
    developerId: number,
    applicationId: number,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsApplicationIdGet(
        developerId,
        applicationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したアプリケーションを削除する。  アプリケーションを削除したとき関連するOAuth2 ClientおよびAPI Keyは削除され、アプリケーションにより取得されたデバイスへの権限がすべて無効化されます。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること
   * @summary アプリケーション削除
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsDelete(
    developerId: number,
    applicationId: number,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsDelete(developerId, applicationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * アプリケーションに関連付けられたイベント定義一覧を取得する。  前提条件:   - 認証: safie-api-developerスコープ
   * @summary イベント定義一覧取得
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsEventDefinitionsGet(
    developerId: number,
    applicationId: number,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsEventDefinitionsGet(
        developerId,
        applicationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したイベント定義のイベント表示名、カラーコードおよび有効・無効状態を更新する。  前提条件:   - 認証: safie-api-developerスコープ
   * @summary イベント定義更新
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {string} definitionId イベント定義ID
   * @param {EventDefinitionPatchRequest} eventDefinitionPatchRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsEventDefinitionsPatch(
    developerId: number,
    applicationId: number,
    definitionId: string,
    eventDefinitionPatchRequest: EventDefinitionPatchRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsEventDefinitionsPatch(
        developerId,
        applicationId,
        definitionId,
        eventDefinitionPatchRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したアプリケーションに属するイベント定義を作成する。イベント定義は作成時は無効状態となる。 各アプリケーション毎に作成可能なイベント定義の上限は5である。  前提条件:   - 認証: safie-api-developerスコープ   - イベント定義作成数の上限5を超えないこと
   * @summary イベント定義作成
   * @param {string} xXSRFToken
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {EventDefinitionRequest} eventDefinitionRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsEventDefinitionsPost(
    xXSRFToken: string,
    developerId: number,
    applicationId: number,
    eventDefinitionRequest: EventDefinitionRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsEventDefinitionsPost(
        xXSRFToken,
        developerId,
        applicationId,
        eventDefinitionRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したディベロッパーに属するアプリケーションを列挙する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること
   * @summary アプリケーション一覧
   * @param {number} developerId ディベロッパーID
   * @param {'apikey' | 'oauth2'} [applicationAuthType] &#x60;apikey&#x60;: APIキーを利用するアプリケーションを取得 &#x60;oauth2&#x60;: OAuth2認可プロセスを通して取得したアクセストークンを利用するアプリケーションを取得
   * @param {number} [offset] 返却するリストのオフセット
   * @param {number} [limit] 返却するリストの最大件数
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsGet(
    developerId: number,
    applicationAuthType?: "apikey" | "oauth2",
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsGet(
        developerId,
        applicationAuthType,
        offset,
        limit,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したアプリケーションへの利用申請を削除し、申請前の状態にします。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること   - 指定されたデバイスが指定されたアプリケーションに対して利用申請されていること
   * @summary アプリケーション利用申請削除
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {number} deviceId デバイスID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsInstallationsDevicesDelete(
    developerId: number,
    applicationId: number,
    deviceId: number,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsInstallationsDevicesDelete(
        developerId,
        applicationId,
        deviceId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したアプリケーションに対する複数の利用申請を一括で変更する。 更新前と更新後の値が同一の場合、値を変更することなくAPIは終了する。 指定されたデバイスの内、一つでも不正なものが存在した場合、指定した全てデバイスの更新が行われない。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること   - 指定されたデバイスが指定されたアプリケーションに対して利用申請されていること
   * @summary アプリケーション利用申請一括更新
   * @param {string} xXSRFToken
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {ApplicationInstallationDevicesPostRequest} applicationInstallationDevicesPostRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsInstallationsDevicesPost(
    xXSRFToken: string,
    developerId: number,
    applicationId: number,
    applicationInstallationDevicesPostRequest: ApplicationInstallationDevicesPostRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsInstallationsDevicesPost(
        xXSRFToken,
        developerId,
        applicationId,
        applicationInstallationDevicesPostRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * アプリケーションを指定して利用申請を行っているデバイスの一覧を取得する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`apikey`であること
   * @summary アプリケーション利用一覧
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {string} [deviceModel] デバイスモデルによる絞り込み検索 (部分一致)
   * @param {string} [deviceSerial] デバイスシリアルによる絞り込み検索 (部分一致)
   * @param {Array<'created' | 'approved' | 'denied'>} [status] 承認状況による絞り込み検索。複数指定可。 &#x60;created&#x60;: 申請中、 &#x60;approved&#x60;: 承認済み、 &#x60;denied&#x60;: 拒否済み
   * @param {string} [memo] メモ欄による絞り込み検索 (部分一致)
   * @param {number} [offset] 返却するリストのオフセット
   * @param {number} [limit] 返却するリストの最大件数
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsInstallationsGet(
    developerId: number,
    applicationId: number,
    deviceModel?: string,
    deviceSerial?: string,
    status?: Array<"created" | "approved" | "denied">,
    memo?: string,
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsInstallationsGet(
        developerId,
        applicationId,
        deviceModel,
        deviceSerial,
        status,
        memo,
        offset,
        limit,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したOAuth2クライアントを削除する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること   - 指定されたOAuth2クライアントが指定されたアプリケーションに属すること
   * @summary アプリケーションOAuth2クライアント削除
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {string} clientId OAuth2クライアントID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsOauth2ClientsDelete(
    developerId: number,
    applicationId: number,
    clientId: string,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsOauth2ClientsDelete(
        developerId,
        applicationId,
        clientId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したアプリケーションに属するOAuth2クライアントの一覧を取得する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること
   * @summary アプリケーションOAuth2クライアント一覧
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {number} [offset] 返却するリストのオフセット
   * @param {number} [limit] 返却するリストの最大件数
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsOauth2ClientsGet(
    developerId: number,
    applicationId: number,
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsOauth2ClientsGet(
        developerId,
        applicationId,
        offset,
        limit,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したアプリケーションに属するOAuth2クライアントを作成する。 クライアントIDおよびクライアントシークレットは乱数により生成される。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して権限を持つこと   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 作成後にクライアントの数の上限2を超えないこと   - リダイレクトURL数が上限30を超えないこと   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること
   * @summary アプリケーションOAuth2クライアント作成
   * @param {string} xXSRFToken
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {Oauth2ClientRequest} oauth2ClientRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsOauth2ClientsPost(
    xXSRFToken: string,
    developerId: number,
    applicationId: number,
    oauth2ClientRequest: Oauth2ClientRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsOauth2ClientsPost(
        xXSRFToken,
        developerId,
        applicationId,
        oauth2ClientRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したOAuth2クライアントの属性を更新する。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること   - 指定されたアプリケーションの`application_auth_type`が`oauth2`であること   - 指定されたOAuth2クライアントが指定されたアプリケーションに属すること   - 更新後にリダイレクトURL数が上限30を超えないこと
   * @summary アプリケーションOAuth2クライアント更新
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {string} clientId OAuth2クライアントID
   * @param {Oauth2ClientRequest} oauth2ClientRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsOauth2ClientsPut(
    developerId: number,
    applicationId: number,
    clientId: string,
    oauth2ClientRequest: Oauth2ClientRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsOauth2ClientsPut(
        developerId,
        applicationId,
        clientId,
        oauth2ClientRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * アプリケーションを更新します。アプリケーション名称とアプリケーション公開状況のみが変更可能です。 公開状況を非公開に変更した場合、公開済みのアプリケーションのユーザ利用申請状況はそのままに非公開となる。 (追加での利用申請ができなくなるが、すでに申請されているものには影響しない。)  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定されたアプリケーションが指定されたディベロッパーに属すること
   * @summary アプリケーション更新
   * @param {number} developerId ディベロッパーID
   * @param {number} applicationId アプリケーションID
   * @param {ApplicationPatchRequest} applicationPatchRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersApplicationsPatch(
    developerId: number,
    applicationId: number,
    applicationPatchRequest: ApplicationPatchRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersApplicationsPatch(
        developerId,
        applicationId,
        applicationPatchRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * ディベロッパーが利用可能な専用オプションの一覧を取得する。 Management Toolよりそのディベロッパーに関連付けられた専用オプションが利用可能。  前提条件:   - 認証: `safie-api-developer`スコープ
   * @summary ディベロッパー専用オプション一覧
   * @param {number} developerId ディベロッパーID
   * @param {number} [offset] 返却するリストのオフセット
   * @param {number} [limit] 返却するリストの最大件数
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersDedicatedOptionsGet(
    developerId: number,
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersDedicatedOptionsGet(developerId, offset, limit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 指定したディベロッパーの情報を更新する。ディベロッパー表示名と、審査状況の変更のみが可能。 審査状況は`submitted`のみ指定可能。  前提条件   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに関連付けられていること
   * @summary ディベロッパー更新
   * @param {number} developerId ディベロッパーID
   * @param {DeveloperUpdateRequest} developerUpdateRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersPut(
    developerId: number,
    developerUpdateRequest: DeveloperUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersPut(developerId, developerUpdateRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * ディベロッパーに設定されているクオータ情報を取得する  前提条件:   - 認証: `safie-api-developer`スコープ
   * @summary ディベロッパークオータ情報
   * @param {number} developerId ディベロッパーID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersQuotasGet(
    developerId: number,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersQuotasGet(developerId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * ディベロッパーに関連付けられたユーザ（もしくは企業）を別のユーザまたは企業に変更します。  発行済の秘密情報 (OAuth2 client secretおよびAPI Key) は変更されません。 移行前のユーザーがアクセス権を持たないことを保証するには、既存のOAuth2 client secretおよびAPI Keyを無効化してください。  オーナー委譲はSafieユーザーとその所属する企業の間のみで行うことができる。  前提条件:   - 認証: `safie-api-developer`スコープ   - ログインユーザーが対象ディベロッパーに対して関連付けられていること   - 指定されたSafieユーザまたは企業にディベロッパーが関連付けられていないこと   - `mail_address` が指定される場合、ディベロッパーに関連付けられた企業に指定されたユーザーが全体管理者として所属していること   - `corporation` が指定される場合、ディベロッパーに関連付けられたユーザーが指定された企業に全体管理者として所属していること
   * @summary ディベロッパーオーナー権限委譲
   * @param {string} xXSRFToken
   * @param {number} developerId ディベロッパーID
   * @param {TransferRequest} transferRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devDevelopersTransferPost(
    xXSRFToken: string,
    developerId: number,
    transferRequest: TransferRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devDevelopersTransferPost(
        xXSRFToken,
        developerId,
        transferRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * ディベロッパーサイトからログアウトします。 操作に成功するとセッションクッキーが無効化されまたUAから削除されます。  前提条件   - Safieユーザとしてログイン済みであること (いずれのスコープでも)
   * @summary ディベロッパーログアウト
   * @param {string} xXSRFToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devLogoutPost(xXSRFToken: string, options?: AxiosRequestConfig) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devLogoutPost(xXSRFToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * ディベロッパーを作成し、現在のログインユーザーを作成されたディベロッパーと関連付ける。 ログイン中のユーザが企業に所属する場合には、個人のSafieユーザアカウントに関連付けるか、所属する企業に関連付けるかを選択できる。  前提条件:   - 認証: `use`スコープ   - `signup_account_type=general`を指定した場合、現在のログインユーザーに対応するディベロッパーが存在しないこと   - `signup_account_type=enterprise`を指定した場合、1) 現在のログインユーザが企業に所属していること、2) ログインユーザが所属企業の全体管理者であること、3) またその所属する企業に対応するディベロッパーが存在しないこと。
   * @summary ディベロッパー登録
   * @param {string} xXSRFToken
   * @param {DeveloperRequest} developerRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devSignupPost(
    xXSRFToken: string,
    developerRequest: DeveloperRequest,
    options?: AxiosRequestConfig,
  ) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devSignupPost(xXSRFToken, developerRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 現在ログイン中のディベロッパーサイトにおけるユーザー情報を取得する。  前提条件:   - 認証: `safie-api-developer`スコープ
   * @summary ディベロッパーユーザ情報
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperSiteAPIApi
   */
  public devUserinfoGet(options?: AxiosRequestConfig) {
    return DeveloperSiteAPIApiFp(this.configuration)
      .devUserinfoGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
