import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "./styles/index.scss";
import "./assets/iconfont/iconfont.css";
import "./assets/fontello/safie-icon-codes.css";

import { validLength } from "./utils";

const app = createApp(App);
app.config.globalProperties.$validLength = validLength;

app.use(i18n).use(store).use(router).use(ElementPlus).mount("#app");
