function timestampToStr(timestamp: number | string, format: string): string {
  const date = new Date(timestamp);
  const getTwo = (i: number) => {
    return i < 10 ? "0" + i : "" + i;
  };
  return format.replace(/yyyy|yy|MM|dd|HH|mm|ss/g, (i: string) => {
    switch (i) {
      case "yyyy":
        return getTwo(date.getFullYear());
      case "yy":
        return date.getFullYear().toString().substring(2);
      case "MM":
        return getTwo(date.getMonth() + 1);
      case "dd":
        return getTwo(date.getDate());
      case "HH":
        return getTwo(date.getHours());
      case "mm":
        return getTwo(date.getMinutes());
      case "ss":
        return getTwo(date.getSeconds());
      default:
        return "";
    }
  });
}

export function formatDate(time: string | number, format = "yyyy-MM-dd") {
  return timestampToStr(time, format);
}

export function formatTime(time: string | number, format = "yyyy-MM-dd HH:mm") {
  return timestampToStr(time, format);
}
