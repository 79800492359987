import store from "@/store";
import { getCookie } from "@/utils";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  DeveloperSiteAPIApi as Api,
  Configuration,
  LoginRequest,
  Success,
  DeveloperRequest,
  DeveloperResponse,
  UserinfoResponse,
  ApplicationApplicationAuthTypeEnum,
  ApplicationList,
  ApplicationRequest,
  ApplicationSuccess,
  ApplicationDetailResponse,
  ApplicationPatchRequest,
  ApikeyRequest,
  ApikeyResponse,
  Developer,
  DeveloperUpdateRequest,
  TransferRequest,
  ApplicationInstallationList,
  ApplicationInstallationDevicesPostRequest,
  Oauth2ClientList,
  Oauth2ClientRequest,
  Oauth2ClientResponse,
  EventDefinitionPatchRequest,
  EventDefinitionRequest,
  EventDefinitionListResponse,
  EventDefinitionResponse,
  AccessControlSetting,
  AccessControlsListResponse,
  AccessControlsPostRequest,
  AccessControlsPatchRequest,
  DeveloperQuota,
  DedicatedOptionList,
} from "../backend-api";

class developerSite {
  private api: Api;

  constructor(
    config?: Configuration,
    basePath?: string,
    instance?: AxiosInstance,
  ) {
    this.api = new Api(config, basePath, instance);
  }
  // ディベロッパーログイン
  async devAuthorizePost(
    loginRequest: LoginRequest,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Success>> {
    await this.devCertificateGet();
    const xXSRFToken = getCookie("_xsrf");
    return this.api.devAuthorizePost(xXSRFToken ?? "", loginRequest, options);
  }
  // ディベロッパー登録
  async devSignupPost(
    developerRequest: DeveloperRequest,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<DeveloperResponse>> {
    store.commit("openLoading", "dark");
    await this.devCertificateGet();
    const xXSRFToken = getCookie("_xsrf");
    return this.api.devSignupPost(xXSRFToken ?? "", developerRequest, options);
  }
  // ディベロッパーログアウト
  async devLogoutPost(
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Success>> {
    await this.devCertificateGet();
    const xXSRFToken = getCookie("_xsrf");
    return this.api.devLogoutPost(xXSRFToken ?? "", options).then((res) => {
      store.commit("setUserinfo", undefined);
      store.commit("setDeveloperId", undefined);
      store.commit("setLoginUsername", undefined);
      store.commit("setInitPassword", undefined);
      store.commit("setIsLogin", false);
      return res;
    });
  }
  // ディベロッパーユーザ情報
  devUserinfoGet(
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<UserinfoResponse>> {
    return this.api.devUserinfoGet(options);
  }
  // ディベロッパークオータ情報
  devQuotasGet(
    developerId: number,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<DeveloperQuota>> {
    return this.api.devDevelopersQuotasGet(developerId, options);
  }
  // 指定したディベロッパーに属するアプリケーションを列挙する。
  devApplicationsGet(
    developerId: number,
    applicationAuthType: ApplicationApplicationAuthTypeEnum,
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<ApplicationList>> {
    return this.api.devDevelopersApplicationsGet(
      developerId,
      applicationAuthType,
      offset,
      limit,
      options,
    );
  }
  // アプリケーション作成
  async devDeveloperApplicationsPost(
    developerId: number,
    applicationRequest: ApplicationRequest,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<ApplicationSuccess>> {
    await this.devCertificateGet();
    const xXSRFToken = getCookie("_xsrf");
    return this.api.devDeveloperApplicationsPost(
      xXSRFToken ?? "",
      developerId,
      applicationRequest,
      options,
    );
  }
  // アプリケーション詳細
  devApplicationGet(
    developerId: number,
    applicationId: number,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<ApplicationDetailResponse>> {
    return this.api.devDevelopersApplicationsApplicationIdGet(
      developerId,
      applicationId,
      options,
    );
  }
  // アプリケーション更新
  devApplicationPatch(
    developerId: number,
    applicationId: number,
    applicationPatchRequest: ApplicationPatchRequest,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<ApplicationDetailResponse>> {
    return this.api.devDevelopersApplicationsPatch(
      developerId,
      applicationId,
      applicationPatchRequest,
      options,
    );
  }
  // アプリケーション削除
  devApplicationDelete(
    developerId: number,
    applicationId: number,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Success>> {
    return this.api.devDevelopersApplicationsDelete(
      developerId,
      applicationId,
      options,
    );
  }
  // アプリケーションAPIキー削除
  devApiKeyDelete(
    developerId: number,
    applicationId: number,
    keyId: number,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Success>> {
    return this.api.devDevelopersApplicationsApikeysDelete(
      developerId,
      applicationId,
      keyId,
      options,
    );
  }
  // アプリケーションAPIキー作成
  async devApiKeyPost(
    developerId: number,
    applicationId: number,
    apikeyRequest: ApikeyRequest,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<ApikeyResponse>> {
    await this.devCertificateGet();
    const xXSRFToken = getCookie("_xsrf");
    return this.api.devDevelopersApplicationsApikeysPost(
      xXSRFToken ?? "",
      developerId,
      applicationId,
      apikeyRequest,
      options,
    );
  }
  // ディベロッパー更新
  devDevelopersPut(
    developerId: number,
    developerUpdateRequest: DeveloperUpdateRequest,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Developer>> {
    store.commit("openLoading", "dark");
    return this.api.devDevelopersPut(
      developerId,
      developerUpdateRequest,
      options,
    );
  }
  // ディベロッパーオーナー権限委譲
  async devDevelopersTransferPost(
    developerId: number,
    transferRequest: TransferRequest,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Success>> {
    await this.devCertificateGet();
    const xXSRFToken = getCookie("_xsrf");
    return this.api.devDevelopersTransferPost(
      xXSRFToken ?? "",
      developerId,
      transferRequest,
      options,
    );
  }
  // アプリケーション利用一覧
  devDevelopersApplicationsInstallationsGet(
    developerId: number,
    applicationId: number,
    deviceModel?: string,
    deviceSerial?: string,
    status?: Array<"created" | "approved" | "denied">,
    memo?: string,
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<ApplicationInstallationList>> {
    return this.api.devDevelopersApplicationsInstallationsGet(
      developerId,
      applicationId,
      deviceModel,
      deviceSerial,
      status,
      memo,
      offset,
      limit,
      options,
    );
  }
  // アプリケーションOAuth2クライアント一覧
  devDevelopersApplicationsOauth2ClientsGet(
    developerId: number,
    applicationId: number,
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Oauth2ClientList>> {
    return this.api.devDevelopersApplicationsOauth2ClientsGet(
      developerId,
      applicationId,
      offset,
      limit,
      options,
    );
  }
  // アプリケーション利用申請一括更新
  async devDevelopersApplicationsInstallationsDevicesPost(
    developerId: number,
    applicationId: number,
    applicationInstallationDevicesPostRequest: ApplicationInstallationDevicesPostRequest,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Success>> {
    await this.devCertificateGet();
    const xXSRFToken = getCookie("_xsrf");
    return this.api.devDevelopersApplicationsInstallationsDevicesPost(
      xXSRFToken ?? "",
      developerId,
      applicationId,
      applicationInstallationDevicesPostRequest,
      options,
    );
  }
  // アプリケーション利用申請削除
  devDevelopersApplicationsInstallationsDelete(
    developerId: number,
    applicationId: number,
    deviceId: number,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Success>> {
    return this.api.devDevelopersApplicationsInstallationsDevicesDelete(
      developerId,
      applicationId,
      deviceId,
      options,
    );
  }
  // アプリケーションOAuth2クライアント削除
  devDevelopersApplicationsOauth2ClientsDelete(
    developerId: number,
    applicationId: number,
    clientId: string,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Success>> {
    return this.api.devDevelopersApplicationsOauth2ClientsDelete(
      developerId,
      applicationId,
      clientId,
      options,
    );
  }
  // アプリケーションOAuth2クライアント更新
  devDevelopersApplicationsOauth2ClientsPut(
    developerId: number,
    applicationId: number,
    clientId: string,
    oauth2ClientRequest: Oauth2ClientRequest,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Oauth2ClientResponse>> {
    return this.api.devDevelopersApplicationsOauth2ClientsPut(
      developerId,
      applicationId,
      clientId,
      oauth2ClientRequest,
      options,
    );
  }
  // アプリケーションOAuth2クライアント作成
  async devDevelopersApplicationsOauth2ClientsPost(
    developerId: number,
    applicationId: number,
    oauth2ClientRequest: Oauth2ClientRequest,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Oauth2ClientResponse>> {
    await this.devCertificateGet();
    const xXSRFToken = getCookie("_xsrf");
    return this.api.devDevelopersApplicationsOauth2ClientsPost(
      xXSRFToken ?? "",
      developerId,
      applicationId,
      oauth2ClientRequest,
      options,
    );
  }
  // XSRF Cookie 取得
  devCertificateGet(): Promise<AxiosResponse<Success>> {
    return this.api.devCertificateGet();
  }
  // イベント定義一覧取得
  devDevelopersApplicationsEventDefinitionsGet(
    developerId: number,
    applicationId: number,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<EventDefinitionListResponse>> {
    return this.api.devDevelopersApplicationsEventDefinitionsGet(
      developerId,
      applicationId,
      options,
    );
  }
  // イベント定義更新
  devDevelopersApplicationsEventDefinitionsPatch(
    developerId: number,
    applicationId: number,
    definitionId: string,
    eventDefinitionPatchRequest: EventDefinitionPatchRequest,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<EventDefinitionResponse>> {
    return this.api.devDevelopersApplicationsEventDefinitionsPatch(
      developerId,
      applicationId,
      definitionId,
      eventDefinitionPatchRequest,
      options,
    );
  }
  // イベント定義作成
  async devDevelopersApplicationsEventDefinitionsPost(
    developerId: number,
    applicationId: number,
    eventDefinitionRequest: EventDefinitionRequest,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<EventDefinitionResponse>> {
    await this.devCertificateGet();
    const xXSRFToken = getCookie("_xsrf");
    return this.api.devDevelopersApplicationsEventDefinitionsPost(
      xXSRFToken ?? "",
      developerId,
      applicationId,
      eventDefinitionRequest,
      options,
    );
  }
  // アクセス制御設定一覧取得
  devDevelopersApplicationsAccessControlsGet(
    developerId: number,
    applicationId: number,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<AccessControlsListResponse>> {
    return this.api.devDevelopersApplicationsAccessControlsGet(
      developerId,
      applicationId,
      options,
    );
  }
  // アクセス制御設定作成
  async devDevelopersApplicationsAccessControlsPost(
    developerId: number,
    applicationId: number,
    accessControlsPostRequest: AccessControlsPostRequest,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<AccessControlSetting>> {
    await this.devCertificateGet();
    const xXSRFToken = getCookie("_xsrf");
    return this.api.devDevelopersApplicationsAccessControlsPost(
      xXSRFToken ?? "",
      developerId,
      applicationId,
      accessControlsPostRequest,
      options,
    );
  }
  // アクセス制御設定更新
  devDevelopersApplicationsAccessControlsPatch(
    developerId: number,
    applicationId: number,
    settingId: number,
    accessControlsPatchRequest: AccessControlsPatchRequest,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<AccessControlSetting>> {
    return this.api.devDevelopersApplicationsAccessControlsPatch(
      developerId,
      applicationId,
      settingId,
      accessControlsPatchRequest,
      options,
    );
  }
  // アクセス制御設定削除
  devDevelopersApplicationsAccessControlsDelete(
    developerId: number,
    applicationId: number,
    settingId: number,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<Success>> {
    return this.api.devDevelopersApplicationsAccessControlsDelete(
      developerId,
      applicationId,
      settingId,
      options,
    );
  }
  // 専用オプションを取得
  devDevelopersDedicatedOptionsGet(
    developerId: number,
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<DedicatedOptionList>> {
    return this.api.devDevelopersDedicatedOptionsGet(
      developerId,
      offset,
      limit,
      options,
    );
  }
}

export { developerSite };
