/* tslint:disable */
/* eslint-disable */
/**
 * Safie API specification for Safie Developers
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.14.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface DeveloperRequest
 */
export interface DeveloperRequest {
  /**
   * 登録するディベロッパーに関連付けるアカウントの種別。 `general`: Safieユーザアカウント（個人）に関連付ける `enterprise`: Safie Managerアカウント（企業）に関連付ける
   * @type {string}
   * @memberof DeveloperRequest
   */
  signup_account_type?: DeveloperRequestSignupAccountTypeEnum;
  /**
   * ディベロッパー表示名
   * @type {string}
   * @memberof DeveloperRequest
   */
  description?: string;
  /**
   * 担当者メールアドレス
   * @type {string}
   * @memberof DeveloperRequest
   */
  mail_address?: string;
  /**
   * 担当者名（姓）
   * @type {string}
   * @memberof DeveloperRequest
   */
  user_name1?: string;
  /**
   * 担当者名（名）
   * @type {string}
   * @memberof DeveloperRequest
   */
  user_name2?: string;
  /**
   * 担当者名（姓、かな）
   * @type {string}
   * @memberof DeveloperRequest
   */
  user_kana1?: string;
  /**
   * 担当者名（名、かな）
   * @type {string}
   * @memberof DeveloperRequest
   */
  user_kana2?: string;
  /**
   * ユーザ種別 `company`: 法人および個人事業主 `personal`: 個人
   * @type {string}
   * @memberof DeveloperRequest
   */
  user_type?: DeveloperRequestUserTypeEnum;
  /**
   * 企業名
   * @type {string}
   * @memberof DeveloperRequest
   */
  company_name?: string;
  /**
   * 企業代表者名
   * @type {string}
   * @memberof DeveloperRequest
   */
  representative_name?: string;
  /**
   * 企業部署名
   * @type {string}
   * @memberof DeveloperRequest
   */
  department?: string;
  /**
   * 郵便番号
   * @type {string}
   * @memberof DeveloperRequest
   */
  postal_code?: string;
  /**
   * 都道府県名
   * @type {string}
   * @memberof DeveloperRequest
   */
  prefecture?: string;
  /**
   * 市区群
   * @type {string}
   * @memberof DeveloperRequest
   */
  city?: string;
  /**
   * 町名
   * @type {string}
   * @memberof DeveloperRequest
   */
  town?: string;
  /**
   * 番地
   * @type {string}
   * @memberof DeveloperRequest
   */
  street?: string;
  /**
   * 建物名
   * @type {string}
   * @memberof DeveloperRequest
   */
  building?: string | null;
  /**
   * 電話番号
   * @type {string}
   * @memberof DeveloperRequest
   */
  phone_number?: string;
}

export const DeveloperRequestSignupAccountTypeEnum = {
  General: "general",
  Enterprise: "enterprise",
} as const;

export type DeveloperRequestSignupAccountTypeEnum =
  (typeof DeveloperRequestSignupAccountTypeEnum)[keyof typeof DeveloperRequestSignupAccountTypeEnum];
export const DeveloperRequestUserTypeEnum = {
  Company: "company",
  Personal: "personal",
} as const;

export type DeveloperRequestUserTypeEnum =
  (typeof DeveloperRequestUserTypeEnum)[keyof typeof DeveloperRequestUserTypeEnum];
