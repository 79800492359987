import { createI18n } from "vue-i18n";
import ja from "./ja";
const messages = {
  ja,
};

const i18n = createI18n({
  legacy: false,
  locale: "ja",
  messages,
});

export default i18n;
