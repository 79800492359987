const elementPlus = {
  TABLE_EMPTY_TEXT: "データなし",
  PAGINATION_GOTO_TEXT: "ジャンプ",
  PAGINATION_PAGE_SIZE_TEXT: "件/ページ",
  PAGINATION_PAGE_CLASSIFIER_TEXT: "ページ",
  COLOR_PICKER_CONFIRM: "確認",
  COLOR_PICKER_CLEAR: "リセット",
  PAGINATION_TOTAL_PRE_TEXT: "全 ",
  PAGINATION_TOTAL_SUF_TEXT: " 件",
};

export default elementPlus;
