import { ElMessage } from "element-plus";
import { Component } from "vue";
import ErrorMessageIcon from "@/common/components/ErrorMessageIcon.vue";
const showErrorMessage = (message: string, offset = 104) => {
  if (message) {
    ElMessage.error({
      message,
      offset,
      customClass: "safie-error-message",
      icon: ErrorMessageIcon,
    });
  }
};

const showSuccessMessage = (
  message: string,
  offset = 104,
  icon?: string | Component,
) => {
  if (message) {
    ElMessage.success({
      message,
      offset,
      customClass: "safie-success-message",
      icon,
    });
  }
};
export { showErrorMessage, showSuccessMessage };
