/* tslint:disable */
/* eslint-disable */
/**
 * Safie API specification for Safie Developers
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.14.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { Apis } from "./apis";

/**
 *
 * @export
 * @interface ApplicationRequest
 */
export interface ApplicationRequest {
  /**
   * アプリケーション名
   * @type {string}
   * @memberof ApplicationRequest
   */
  application_name: string;
  /**
   * アプリケーション毎に設定可能なディベロッパー表示名。 `device_option_type=dedicated`以外の場合には指定できない。
   * @type {string}
   * @memberof ApplicationRequest
   */
  developer_description?: string | null;
  /**
   * アプリケーションに適用するプラン。`application_auth_type=oauth2`の場合は、`production`を指定しなければならない。 `trial`: 試験用アプリケーション、権限のある任意のデバイスに対して少量のAPIコールが可能。 `production`: 本番アプリケーション、権限のあるSafie APIオプションプランが付与されたデバイスに対してプランで定められた量のAPIコールが可能。
   * @type {string}
   * @memberof ApplicationRequest
   */
  application_plan: ApplicationRequestApplicationPlanEnum;
  /**
   * アプリケーションが利用する認証種別 `apikey`: APIキーを利用するアプリケーション `oauth2`: OAuth2認可プロセスを通して取得したアクセストークンを利用するアプリケーション
   * @type {string}
   * @memberof ApplicationRequest
   */
  application_auth_type: ApplicationRequestApplicationAuthTypeEnum;
  /**
   * アプリケーション公開状況。`application_auth_type=oauth2`の場合は、`true`を指定しなければならない。 `true`: 公開中 (自developerに属さないユーザーがアプリケーションコードを入力することでアプリケーション利用申請することができる。ただし、`application_auth_type=oauth2`のアプリケーションに対する利用申請はできない。) `false`: 非公開（デフォルト）
   * @type {boolean}
   * @memberof ApplicationRequest
   */
  published?: boolean;
  /**
   * このアプリケーションを利用するために許諾する必要のある権限のリスト。 `application_auth_type=apikey`の場合のみ指定可能。このアプリケーションで発行されたAPIキーでは、 ここで記述された権限の範囲内でのAPIアクセスのみが許可される。値は、`view`, `timeline`, `share`, `unshare`, `change_permission`, `talkback`, `view_clips`, `download`, `create_clips`, `delete_clips`, `device_setting`, `event_detection`, `delete_media`, `view_contract`, `location` の中から部分集合となるように指定する。
   * @type {Set<string>}
   * @memberof ApplicationRequest
   */
  permissions?: Set<string> | null;
  /**
   *
   * @type {Apis}
   * @memberof ApplicationRequest
   */
  apis?: Apis;
  /**
   * デベロッパーがアプリケーション作成時に選択した利用APIのリスト。この情報は、アプリケーション詳細画面での表示に利用されるのみであり、アクセス制御には影響を与えない。 値は、`device_image`, `event_register`, `location`, `still_capture`, `device_list`, `media_list`, `thumbnail`, `standard_event_list`, `shares`, `live`, `media_files`, `movie_clips`, `events`, `quotas`, `snapshots`, `ai_people_count`, `aiapp_people_count`, `entrance2`, `application_links`, `users`,`device_others`(deprecated) の中から部分集合となるように指定する。
   * @type {Set<string>}
   * @memberof ApplicationRequest
   */
  selected_apis?: Set<string> | null;
  /**
   * デベロッパーがアプリケーション作成時に選択したOAuthのscope、`application_auth_type=oauth2`の場合のみ指定可能。 このアプリケーションで発行されたクライアントでは、ここで記述されたscopeの範囲のAPIアクセスのみが許可される。 全ての範囲のアクセスを許可する場合は `safie-api` を指定する。 特定の範囲を許可する場合は、`api:device:live:view`, `api:device:timeline:view`, `api:device:share:create`, `api:device:share:delete`, `api:device:permission:update`, `api:device:talkback:all`, `api:device:setting:all`, `api:device:contract:view`, `api:device:location:all`, `api:device:media:view`, `api:device:media:create`, `api:device:media:delete`, `api:device:media:download`, `api:device:event:create`, `api:entrance:all` の中から部分集合となるように指定する。
   * @type {Set<string>}
   * @memberof ApplicationRequest
   */
  oauth2_scope?: Set<string> | null;
  /**
   * このアプリケーションでAPIアクセスする際に利用されるデバイスオプションの種別 `dedicated`（専用オプション）、`general`（汎用オプション）の二種類の値のみ。省略された場合は`general`（汎用オプション）となる。
   * @type {string}
   * @memberof ApplicationRequest
   */
  device_option_type?: ApplicationRequestDeviceOptionTypeEnum;
  /**
   * アプリケーションで利用する専用オプションのitem_id(payment_itemテーブルのitem_id)のリスト。 `device_option_type=dedicated`以外の場合には指定できない。 アプリケーションで利用することのできる専用オプションの最大数は10。
   * @type {Set<number>}
   * @memberof ApplicationRequest
   */
  dedicated_option_item_ids?: Set<number> | null;
  /**
   * デベロッパーがアプリケーション作成時に選択した利用SDKのリスト。 値は `javascript` の中から部分集合となるように指定する。
   * @type {Set<string>}
   * @memberof ApplicationRequest
   */
  sdks?: Set<string> | null;
  /**
   * デベロッパーがアプリケーション作成時に選択したSDKで利用するAPIのリスト。 値は `streaming`, `device_image`, `device_list`, `media_list`, `location`, `thumbnail`, `standard_event_list`, `still_capture`, `users`, `devices`(deprecated)の中から部分集合となるように指定する。
   * @type {Set<string>}
   * @memberof ApplicationRequest
   */
  selected_sdk_apis?: Set<string> | null;
}

export const ApplicationRequestApplicationPlanEnum = {
  Trial: "trial",
  Production: "production",
} as const;

export type ApplicationRequestApplicationPlanEnum =
  (typeof ApplicationRequestApplicationPlanEnum)[keyof typeof ApplicationRequestApplicationPlanEnum];
export const ApplicationRequestApplicationAuthTypeEnum = {
  Apikey: "apikey",
  Oauth2: "oauth2",
} as const;

export type ApplicationRequestApplicationAuthTypeEnum =
  (typeof ApplicationRequestApplicationAuthTypeEnum)[keyof typeof ApplicationRequestApplicationAuthTypeEnum];
export const ApplicationRequestDeviceOptionTypeEnum = {
  Dedicated: "dedicated",
  General: "general",
} as const;

export type ApplicationRequestDeviceOptionTypeEnum =
  (typeof ApplicationRequestDeviceOptionTypeEnum)[keyof typeof ApplicationRequestDeviceOptionTypeEnum];
